import React from 'react';
import styles from './BookingCard.module.css';
import { formatTime } from '../../Utils/utils';

const BookingCard = ({ worx, space, from_time, to_time, cost }) => {
    return (
        <div className={styles.bookingCard}>
            <div className={styles.bookingCardDetails}>
                <div className={styles.bookingCardImage}>
                    <img src={worx.WorxPhotos.length > 0 ? worx.WorxPhotos[0].photo_url : 'https://via.placeholder.com/150'} alt={worx.name} />
                </div>
                <div className={styles.bookingCardInfo}>
                    <div>
                        <div className={styles.bookingCardHotel}>{worx.name}</div>
                        <div className={styles.bookingCardAddress}>{space.address}</div>
                    </div>
                    <div className={styles.bookingCardTimeCost}>
                        <span className={styles.spans}>{formatTime(from_time)} - {formatTime(to_time)}</span>
                        <span className={styles.spans}>{cost} €</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BookingCard;
