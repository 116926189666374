import React, { useState, useEffect } from 'react';
import './HoverEffectButton.css';

const HoverEffectButton = ({ text, onClick }) => {
  const [hovered, setHovered] = useState(false);
  const [displayText, setDisplayText] = useState(text);

  useEffect(() => {
    let interval;
    let timeout;
    if (hovered) {
      interval = setInterval(() => {
        setDisplayText((prevText) =>
          prevText
            .split('')
            .map((char) => (Math.random() > 0.5 ? getRandomChar() : char))
            .join('')
        );
      }, 50);

      timeout = setTimeout(() => {
        setHovered(false);
        revertText();
      }, 1000);
    } else {
      setDisplayText(text);
    }

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [hovered, text]);

  const getRandomChar = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    return chars.charAt(Math.floor(Math.random() * chars.length));
  };

  const revertText = () => {
    let index = 0;
    const revertInterval = setInterval(() => {
      setDisplayText((prevText) =>
        prevText
          .split('')
          .map((char, i) => (i <= index ? text.charAt(i) : char))
          .join('')
      );
      index += 1;
      if (index >= text.length) {
        clearInterval(revertInterval);
      }
    }, 50);
  };

  return (
    <button
      className="hoverEffectButton"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={onClick}
    >
      {displayText}
    </button>
  );
};

export default HoverEffectButton;
