import React, { useState, useContext, useEffect } from 'react'
//import '../App.css';
import SidebarNav from '../Sidebar/Sidebar';
import Header from '../Header/Header';
import { UserContext } from '../../Context/User/UserContext';
import useHttp from "../../Hooks/useHttp";
import * as utils from '../../Utils/utils';
import GeneralGrid from '../../Layouts/Grid/GeneralGrid';
import Table from 'react-bootstrap/Table';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function Bookings() {

    const [bookings, setBookings] = useState([])


    const user = useContext(UserContext);


    const { isLoadingSpot, errorSpot, setError: setErrorSpot, sendRequest: findBookings } = useHttp();


    const getBookings = (response) => {
        setBookings(response.body)
        console.log("---------------", bookings)
    };

    useEffect(() => {
        console.log(user.userId)
        const userId = utils.getFromSessionStorage('user-id')
        console.log(userId)
        if (userId) {
            user.saveUserId(userId)
            console.log(user.userId)
            
        }
        findBookings(
            {
                url: `${process.env.REACT_APP_BACKEND_URL}/api/v1/booking/list?SpaceId=2`,
                method: "GET",
                auth: false,
            },
            getBookings
        );
    }, []);



    return (
        <div>
            <Header />
            <SidebarNav />
            <main style={{ marginLeft: '55px', height: '15%', padding: '5%' }}>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Username</th>
                        </tr>
                    </thead>
                    <tbody>
                        {() => {console.log("---------------", bookings)}}
                        {bookings.map((booking) => (
                            <tr>
                                <td>{booking.from}</td>
                                <td>{booking.to}</td>
                                <td>{booking.Worx.name}</td>
                                <td>{booking.User.firstName} {booking.User.lastName}</td>
                                <td><Link>See details</Link></td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </main>

        </div>
    )
}