import { ApiService } from "./apiService";
import { SpaceDetail } from "../Models/spaceDetails";
import { WorxDetail } from "../Models/worxDetails";
import { SERVER_URL } from "../Utils/constants";

export class AmenitieAPIService {
  private apiService: ApiService;

  constructor() {
    this.apiService = new ApiService(SERVER_URL);
  }

  public async setChild(parentId: number, childId: string) {
    const relationship = {
      parentId,
      childId,
    };
    return await this.apiService.post(`space/set/child`, relationship);
  }

  public async getAllAmenities(): Promise<any> {
    return await this.apiService.get(`amenitie/list`);
  }

  public async createAmenitieWorx(body: any): Promise<any> {
    return this.apiService.create(`amenitie/create-worx-amenitie`, body);
  }

  public async deleteAmenitieWorx(id: number): Promise<any> {
    return this.apiService.delete(`amenitie/delete-worx-amenitie/${id}`);
  }
}
