import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
import { useNavigate } from "react-router-dom";


interface MapProps {
    centerLatitude: number;
    centerLongitude: number;
    height: string;
    spaces: [];
    search: any;
    canBeClicked?: boolean;
    showingSpaces: boolean;
}

const WorxinnMapSpaces: React.FC<MapProps> = ({ centerLatitude, centerLongitude, height, spaces, search, canBeClicked = true, showingSpaces = false }) => {
    const [selectedSpace, setSelectedSpace] = useState<Space | null>(null);
    const navigate = useNavigate();

    const handleBookNowClick = (spaceId: number) => {
        navigate(`/space/detail?spaceId=${spaceId}`, { state: { search } });
    };

    const containerStyle = {
        width: "100%",
        height: height,
    };

    const center = {
        lat: centerLatitude,
        lng: centerLongitude,
    };

    const renderMarkers = (map: any, maps: any) => {
        spaces.forEach(space => {
        
            let markerPosition = {
                lat: 0.0,
                lng: 0.0,
            }
            if (showingSpaces) {
                markerPosition = {
                    lat: space.lat,
                    lng: space.lng,
                }
            } else {
                markerPosition = {
                    lat: space.Space.lat,
                    lng: space.Space.lng,
                }
            }
            console.log(markerPosition)
            const marker = new maps.Marker({
                position: markerPosition,
                map,
                title: space.name,
            });

            if (canBeClicked) {
                marker.addListener("click", () => {
                    setSelectedSpace(space);
                });
            }
        });
    };

    return (
        <div>
            <div
                style={containerStyle}
                className="rounded-xl overflow-hidden"
            >
                <GoogleMapReact
                    yesIWantToUseGoogleMapApiInternals={true}
                    bootstrapURLKeys={undefined}
                    defaultCenter={center}
                    defaultZoom={15}
                    onGoogleApiLoaded={({ map, maps }) => {
                        console.log("API loaded, rendering markers.");
                        renderMarkers(map, maps)
                    }}
                    options={{
                        fullscreenControl: false, // This disables the fullscreen button
                    }}
                ></GoogleMapReact>
            </div>
            {selectedSpace && (
                <div className="p-2 mt-3 border rounded" onClick={() => handleBookNowClick(selectedSpace.id)}>
                    <h3 className="text-xl font-bold mb-2">{selectedSpace.name}</h3>
                    <img src={selectedSpace.WorxPhotos.length > 0 ? selectedSpace.WorxPhotos[0].photo_url : 'https://via.placeholder.com/400'} alt="Space" className="w-full h-auto object-cover rounded" />
                    <div className="flex justify-between items-center mt-2">
                        <span className="text-lg font-semibold">{`Price: €${selectedSpace.price}/hr`}</span>
                        <span className="text-lg font-semibold">{`Capacity: ${selectedSpace.capacity}`}</span>
                    </div>
                    <button className="mt-2 bg-orange-500 text-white px-4 py-2 rounded-md self-start md:self-auto" onClick={() => handleBookNowClick(selectedSpace.id)}>Book Now</button>
                </div>
            )}
        </div>
    );
};

export default WorxinnMapSpaces;