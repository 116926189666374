import { useEffect, useState } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import WorxAPIService from '../../Api/worxAPIService'; // Ensure you have this service
import WorxinnMap from '../../Components/map'; // Adjust import path as necessary
import UserHeader from '../components/menu'; // Adjust import path as necessary
import { useRecoilState } from "recoil";
import { userState } from '../../State/userAtom';
import { SERVER_URL } from '../../Utils/constants';

function Completion(props) {
    const [worxDetails, setWorxDetails] = useState(null);
    const [spaceDetails, setSpaceDetails] = useState(null);
    const [messageBody, setMessageBody] = useState('');
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const worxId = urlParams.get('worxId');
    const date = urlParams.get('date');
    const price = urlParams.get('price');
    const hours = urlParams.get('hours');
    const userEmail = urlParams.get('userEmail');
    const worxAPIService = new WorxAPIService();
    const { stripePromise } = props;
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [user] = useRecoilState(userState);
    const [errormessage, setErrorMessage] = useState('')

    useEffect(() => {
        const fetchWorxDetails = async () => {
            if (!worxId) return;
            try {
                const response = await worxAPIService.getWorx(parseInt(worxId));
                setWorxDetails(response.body);
                setSpaceDetails(response.body.Space);
                setIsLoading(false);

            } catch (error) {
                console.error('Error fetching worx details:', error);
                setIsLoading(false);
            }
        };

        fetchWorxDetails();

    }, [worxId]);

    useEffect(() => {
        if (!stripePromise) return;

        stripePromise.then(async (stripe) => {
            const url = new URL(window.location);
            const clientSecret = url.searchParams.get('payment_intent_client_secret');
            const { error, paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);

            setMessageBody(error ? `> ${error.message}` : (
                <>&gt; Payment {paymentIntent.status}</>
            ));
            setErrorMessage(error)
        });
    }, [stripePromise]);

    if (isLoading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-orange-500"></div>
            </div>
        );
    }

    return (
        <div className="flex flex-col h-screen">
            <div className="flex-grow overflow-auto">
                <div className="container mx-auto p-0">
                    <div className="bg-white p-6">
                        <h2 className="text-2xl font-bold text-center mb-6">Booking Confirmation</h2>
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                            <div>
                                <div className="p-4 mb-2 border-t">
                                    <h3 className="text-xl font-semibold mb-2">Space Details</h3>
                                    <div className="mb-4">
                                        <span className="font-semibold">Space:</span> {worxDetails?.name}
                                    </div>
                                    <div className="mb-4">
                                        <span className="font-semibold">Date:</span> {date}
                                    </div>
                                    <h3 className="text-xl font-semibold mb-2">Price Details</h3>
                                    <div className="mb-4">
                                        {hours && (
                                            <div>
                                                <p>Total Price: €{(parseFloat(price)).toFixed(2)}</p>
                                                <p>Hours: {hours}</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="p-4 border-t">
                                    <h3 className="text-xl font-semibold mb-4">Location</h3>
                                    <div className="mb-4">
                                        <p className="text-lg">{spaceDetails?.address}, {spaceDetails?.eircode}</p>
                                    </div>
                                    <div className="map-container h-64 w-full">
                                        <WorxinnMap latitude={spaceDetails?.lat} longitude={spaceDetails?.lng} height="100%" marker={true} />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="p-4 text-center border-t ">
                                    <h3 className="text-xl font-semibold mb-4">Payment Details</h3>
                                    <div className="text-lg mb-4">{messageBody}</div>
                                    <div className="flex justify-center space-x-4">
                                        <button
                                            onClick={() => navigate('/')}
                                            className="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded mb-4"
                                        >
                                            Home
                                        </button>
                                        {user && Object.keys(user).length > 0 && (
                                            <button
                                                onClick={() => navigate('/my-bookings')}
                                                className="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded mb-4"
                                            >
                                                My Bookings
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Completion;
