import { ApiService } from "../Api/apiService";
import { SERVER_URL } from "../Utils/constants";

export class EmailAPIService {
    private apiService: ApiService;

    constructor() {
        this.apiService = new ApiService(SERVER_URL);
    }

    public async sendUserBookingConfirmationEmail(body: any): Promise<any> {
        return this.apiService.create(`email/user-booking-confirmation-email`, body);
    }

    public async sendHotelBookingConfirmationEmail(body: any): Promise<any> {
        return this.apiService.create(`email/hotel-booking-confirmation-email`, body);
    }
}