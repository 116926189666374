import React from 'react';
import PdfViewer from './components/pdfViewe';


function StorageLocker() {

    const pdf = 'pdf/Storage_Drawer.pdf';

    console.log(pdf)
    return (
        <div >
            <PdfViewer pdf={pdf} />
        </div>
    );
}

export default StorageLocker;