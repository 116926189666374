
export async function bodyRequest(url, dataToSend, verb) {
    try {
        const result = await fetch(url, {
            method: verb, // *GET, POST, PUT, DELETE, etc
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
            // body data type must match`Content-Type` header
        });
        const data = await result.json();
        const status = result.status;
        const respose = {
            data: data,
            status: status
        }
        if (result.status !== 200) {
            return respose;
        }
        return respose;
    } catch (error) {
        console.error(error.message);
        return false;
    }
}

export function saveSessionStorage(key, data) {
    try {
        sessionStorage.setItem(key, data)
    } catch (error) {
        console.log(error)
    }
}

export function getFromSessionStorage(key) {
    return sessionStorage.getItem(key);
}

export const formatTime = (timeString) => {
    const options = { hour: '2-digit', minute: '2-digit' };
    return new Date(`1970-01-01T${timeString}Z`).toLocaleTimeString([], options);
};

export const groupBookingsByDate = (bookings) => {
    return bookings.reduce((acc, booking) => {
      const date = booking.bookingDate;
      if (!acc[date]) {
        acc[date] = { bookings: [], totalAmount: 0 };
      }
      acc[date].bookings.push(booking);
      acc[date].totalAmount += parseFloat(booking.totalAmount);
      return acc;
    }, {});
  };