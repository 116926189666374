import { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import SidebarNav from '../Sidebar/Sidebar';
import Header from '../Header/Header';
import useHttp from "../../Hooks/useHttp";
import { useLocation } from 'react-router-dom';

export default function EditSpecialDeals(props) {
    const location = useLocation();

    const specialdeal = location.state.data;


    console.log(specialdeal)

    const [name, setName] = useState(specialdeal.name);
    const [description, setDescription] = useState(specialdeal.description);
    const [price, setPrice] = useState(specialdeal.price);
    const [photo, setPhoto] = useState(specialdeal.photo);

    const { isLoadingEdit, errorEdit, setError: setErrorEdit, sendRequest: editWorx } = useHttp();



    // handle form submit
    const handleSubmit = (event) => {
        event.preventDefault();
        // do something with the form data
        const data = {
            name: name,
            description: description,
            price: price,
        };
        console.log("-----Edit worx handle submit----")
        editWorx({
            url: `${process.env.REACT_APP_BACKEND_URL}/api/v1/specialdeal/update/${specialdeal.id}`,
            method: "PUT",
            auth: false,
            body: data
        },
            createSpecialDealResp)
    };

    function handlePhotoChange(event) {
        setPhoto(event.target.files[0]);
    }

    const createSpecialDealResp = (response) => {
        if (response.status == 200) {
            console.log("------------------------")

        } else {
            console.log("Something went bad")
        }
        console.log(response)
    }

    return (
        <>
            <Header />
            <SidebarNav />
            <main style={{ marginLeft: '55px', height: '15%', padding: '5%' }}>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="Enter name" value={name} onChange={(e) => setName(e.target.value)} />
                    </Form.Group>
                    <Form.Group controlId="description">
                        <Form.Label>Description</Form.Label>
                        <Form.Control as="textarea" placeholder="Enter description" value={description} onChange={(e) => setDescription(e.target.value)} />
                    </Form.Group>
                    <Form.Group controlId="price">
                        <Form.Label>Price</Form.Label>
                        <Form.Control type="number" step="0.01" placeholder="Enter price" value={price} onChange={(e) => setPrice(parseFloat(e.target.value))} />
                    </Form.Group>
                    <Form.Group controlId="photo">
                        <Form.Label>Photo</Form.Label>
                        <Form.Control type="file" onChange={handlePhotoChange} />
                    </Form.Group>
                    <Button variant="primary" type="submit">Submit</Button>
                </Form>
            </main>
        </>
    );
}

