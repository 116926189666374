import axios from 'axios';

const getLatLngFromEircode = async (eircode : string) => {
  const apiKey = "AIzaSyCNilZfl5ySD5GmeE2PZn9eZNg5BsaRqkg";
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${eircode}&key=${apiKey}`;

  try {
    const response = await axios.get(url);
    const { lat, lng } = response.data.results[0].geometry.location;
    return { lat, lng };
  } catch (error) {
    console.error('Error fetching geocode', error);
    return null;
  }
};

export default getLatLngFromEircode;
