import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi'; // Assuming you're using react-icons for icons

const BackArrowHeader: React.FC = () => {
    const navigate = useNavigate();

    const goBack = () => {
       navigate(-1);
    };

    return (
        <div className="header-with-back-arrow w-full h-full">
            <button onClick={goBack} className="back-arrow-button">
                <FiArrowLeft size={24} /> {/* Adjust size as needed */}
            </button>
            {/* Additional header content here */}
        </div>
    );
};

export default BackArrowHeader;