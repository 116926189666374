import { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import SidebarNav from '../Sidebar/Sidebar';
import Header from '../Header/Header';
import useHttp from "../../Hooks/useHttp";
import { useLocation } from 'react-router-dom';

export default function CreateWorx(props) {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const worxType = queryParams.get('worxtype');

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState(0);
    const [isVisible, setIsVisible] = useState(false)

    const { isLoadingEdit, errorEdit, setError: setErrorEdit, sendRequest: createSpecialDeal } = useHttp();

    const [showA, setShowA] = useState(false);


    // handle form submit
    const handleSubmit = (event) => {
        event.preventDefault();
        // do something with the form data
        const data = {
            name: name,
            description: description,
            price: price,
            SpaceId: 2,
            WorxTypeId: worxType,
            createdById: 1
        };
        console.log("-----Special Deal handle submit----")
        createSpecialDeal({
            url: `${process.env.REACT_APP_BACKEND_URL}/api/v1/worx/create`,
            method: "POST",
            auth: false,
            body: data
        },
            createSpecialDealResp)
    };

    const createSpecialDealResp = (response) => {
        if (response.status == 200) {
            console.log("------------------------")

        } else {
            console.log("Something went bad")
        }
        console.log(response)
    }

    return (
        <>
            <Header />
            <SidebarNav />
            <main style={{ marginLeft: '55px', height: '15%', padding: '5%' }}>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formBasicName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Enter name"
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicDescription">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            type="text"
                            name="description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder="Enter description"
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicPrice">
                        <Form.Label>Price</Form.Label>
                        <Form.Control
                            type="number"
                            name="price"
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                            placeholder="Enter price"
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicIsVisible">
                        <Form.Check
                            type="switch"
                            name="isVisible"
                            label="Is Visible"
                            checked={isVisible}
                            onChange={(e) => setIsVisible(e.target.value)}
                        />
                    </Form.Group>

                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                </Form>
            </main>
        </>
    );
}

