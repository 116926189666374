import React, { useState, useContext, useEffect } from 'react'
//import '../App.css';
import SidebarNav from '../Sidebar/Sidebar';
import Header from '../Header/Header';
import { UserContext } from '../../Context/User/UserContext';
import useHttp from "../../Hooks/useHttp";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import CardGrid from '../../Layouts/Grid/CardGrid';
import Col from 'react-bootstrap/Col';
import DashboardCard from '../../Layouts/Cards/DashboardCard';
import Button from 'react-bootstrap/Button';
import * as utils from '../../Utils/utils';
import GeneralGrid from '../../Layouts/Grid/GeneralGrid';

export default function Worx() {

    const [worxSpots, setWorxsSpotsList] = useState([])
    const [worxMeetings, setWorxsMeetingList] = useState([])
    const [worxOffices, setWorxsOfficesList] = useState([])
    const [worxZones, setWorxsZonesList] = useState([])
    const [worxSuites, setWorxsSuitesList] = useState([])

    const user = useContext(UserContext);


    const { isLoadingSpot, errorSpot, setError: setErrorSpot, sendRequest: findWorxSpots } = useHttp();
    const { isLoadingMeeting, errorMeeting, setError: setErrorMeeting, sendRequest: findWorxMeeting } = useHttp();
    const { isLoadingOffice, errorOffice, setError: setErrorOffice, sendRequest: findWorxOffice } = useHttp();
    const { isLoadingZone, errorZone, setError: setErrorZone, sendRequest: findWorxZone } = useHttp();
    const { isLoadingSuite, errorSuite, setError: setErrorSuite, sendRequest: findWorxSuite } = useHttp();

    const getWorxSpots = (response) => {
        setWorxsSpotsList(response.body)
    };

    const getWorxMeeting = (response) => {
        setWorxsMeetingList(response.body)
    };

    const getWorxOffices = (response) => {
        setWorxsOfficesList(response.body)
    };

    const getWorxZones = (response) => {
        setWorxsZonesList(response.body)
    };

    const getWorxSuites = (response) => {
        setWorxsSuitesList(response.body)
    };

    useEffect(() => {
        console.log(user.userId)
        const userId = utils.getFromSessionStorage('user-id')
        console.log(userId)
        if (userId) {
            user.saveUserId(userId)
            console.log(user.userId)
        }
        findWorxSpots(
            {
                url: `${process.env.REACT_APP_BACKEND_URL}/api/v1/worx/list?createdById=${user.userId}&WorxTypeId=1`,
                method: "GET",
                auth: false,
            },
            getWorxSpots
        );

        findWorxMeeting(
            {
                url: `${process.env.REACT_APP_BACKEND_URL}/api/v1/worx/list?createdById=${user.userId}&WorxTypeId=2`,
                method: "GET",
                auth: false,
            },
            getWorxMeeting
        );

        findWorxOffice(
            {
                url: `${process.env.REACT_APP_BACKEND_URL}/api/v1/worx/list?createdById=${user.userId}&WorxTypeId=3`,
                method: "GET",
                auth: false,
            },
            getWorxOffices
        );

        findWorxZone(
            {
                url: `${process.env.REACT_APP_BACKEND_URL}/api/v1/worx/list?createdById=${user.userId}&WorxTypeId=4`,
                method: "GET",
                auth: false,
            },
            getWorxZones
        );

        findWorxSuite(
            {
                url: `${process.env.REACT_APP_BACKEND_URL}/api/v1/worx/list?createdById=${user.userId}&WorxTypeId=5`,
                method: "GET",
                auth: false,
            },
            getWorxSuites
        );
    }, []);



    return (
        <div>
            <Header />
            <SidebarNav />
            <main style={{ marginLeft: '55px', height: '15%', padding: '5%' }}>
                <Tabs
                    defaultActiveKey="worxspot"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    fill
                >
                    <Tab eventKey="worxspot" title="WorxSpot">
                        <GeneralGrid data={worxSpots} buttonTitle="Create WorxSpot" to="/worx/create?worxtype=1" btnTo="/worx/edit"/>
                    </Tab>
                    <Tab eventKey="worxmeeting" title="WorxMeeting">
                        <GeneralGrid data={worxMeetings} buttonTitle="Create WorxMeeting" to="/worx/create?worxtype=2"/>
                    </Tab>
                    <Tab eventKey="worxoffice" title="WorxOffice">
                        <GeneralGrid data={worxOffices} buttonTitle="Create WorxOffice" to="/worx/create?worxtype=3"/>
                    </Tab>
                    <Tab eventKey="worxzone" title="WorxZone">
                        <GeneralGrid data={worxZones} buttonTitle="Create WorxZone" to="/worx/create?worxtype=4"/>
                    </Tab>
                    <Tab eventKey="worxsuite" title="WorxSuite">
                        <GeneralGrid data={worxSuites} buttonTitle="Create WorxSuite" to="/worx/create?worxtype=5"/>
                    </Tab>
                </Tabs>
            </main>

        </div>
    )
}






