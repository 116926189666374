import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SpaceAPIService } from "../Api/spaceAPIService.ts";
import Header from "../Components/header.tsx";
import { UserContext } from "../Context/User/UserContext.js";
import { useRecoilState } from "recoil";
import { userState } from "../State/userAtom";
import { SERVER_URL } from "../Utils/constants.js";

const SpaceManagementPage = () => {
    const [spaceName, setSpaceName] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [county, setCounty] = useState("");
    const [eircode, setEircode] = useState("");
    const [phone, setPhone] = useState("");
    const userCtx = useContext(UserContext);
    const [user, setUser] = useRecoilState(userState);
    const [email, setEmail] = useState("");
    const navigate = useNavigate();
    const spaceAPIService = new SpaceAPIService();

    useEffect(() => {
        setEmail(user.bbdUser.user.email);
    }, [user]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newSpaceDetails = {
            name: spaceName,
            details: '',
            email,
            address,
            main_photo: '',
            city,
            county,
            website: '',
            eircode,
            phone,
            hotelLogo: '',
            hotelUserID: user.bbdUser.user.id,
        };

        try {
            const response = await spaceAPIService.createSpace(newSpaceDetails);
            if (response.status === 201) {
                setUser((currentUser) => ({
                    ...currentUser,
                    bbdUser: {
                        ...currentUser.bbdUser,
                        user: {
                            ...currentUser.bbdUser.user,
                            Space: [response.body.id], // Adds the new ID to the Space array
                        },
                    },
                }));

                const token = 'your_token_here';

                await fetch(`${SERVER_URL}space-availability/create`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        SpaceId: response.body.id,
                        opening_time_monday: null,
                        closing_time_monday: null,
                        opening_time_tuesday: null,
                        closing_time_tuesday: null,
                        opening_time_wednesday: null,
                        closing_time_wednesday: null,
                        opening_time_thursday: null,
                        closing_time_thursday: null,
                        opening_time_friday: null,
                        closing_time_friday: null,
                        opening_time_saturday: null,
                        closing_time_saturday: null,
                        opening_time_sunday: null,
                        closing_time_sunday: null,
                    }),
                });

                navigate(`/space?id=${response.body.id}`);
            }
        } catch (error) {
            console.error('Error creating new space:', error);
        }
    };

    return (
        <div className="container mx-auto p-4 max-w-3xl">
            <Header userInitials="MG" />
            <form onSubmit={handleSubmit} className="space-y-4 grid grid-cols-1 sm:grid-cols-2 gap-4">
                <InputField label="Space Name" value={spaceName} onChange={setSpaceName} />
                <InputField label="Email" value={email} onChange={setEmail} type="email" />
                <InputField label="Address" value={address} onChange={setAddress} />
                <InputField label="City" value={city} onChange={setCity} />
                <InputField label="County" value={county} onChange={setCounty} />
                <InputField label="Eircode" value={eircode} onChange={setEircode} />
                <InputField label="Phone" value={phone} onChange={setPhone} />
                <div className="col-span-1 sm:col-span-2">
                    <button type="submit" className="w-full px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
                        Create Space
                    </button>
                </div>
            </form>
        </div>
    );
};

const InputField = ({ label, value, onChange, type = "text" }) => (
    <div>
        <label className="block text-sm font-medium text-gray-700">{label}</label>
        {type === "textarea" ? (
            <textarea
                id={label}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                rows="3"
            ></textarea>
        ) : (
            <input
                type={type}
                id={label}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
            />
        )}
    </div>
);

export default SpaceManagementPage;
