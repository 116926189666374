import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SpaceAPIService } from '../Api/spaceAPIService';
import Header from './components/menu';
import BackArrowHeader from './components/backArrow';

// Define the interface for the Venue type based on your data
interface Venue {
    id: number;
    name: string;
    email: string;
    address: string;
    main_photo: string;
    city: string;
    county: string;
    eircode: string;
    phone: string;
    lat: number | null;
    lng: number | null;
    HotelPhotos: { id: number; photo_url: string; isMain: boolean }[];
    Worxes: { name: string; capacity: number }[];
    // ... include other relevant fields
}

const VenueDetailPage: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const venueId = queryParams.get('venueId');
    const [venue, setVenue] = useState<Venue | null>(null);
    const spaceAPIService = new SpaceAPIService();

    useEffect(() => {
        const fetchHotelDetails = async () => {
            try {
                const venueDetails = await spaceAPIService.getSpace(parseInt(venueId!));
                setVenue(venueDetails.body);
            } catch (error) {
                console.error('Error fetching hotel details:', error);
            }
        };

        fetchHotelDetails();
    }, [venueId]);

    const handleBookingButtonClick = () => {
        navigate('/hotel/book', {
            state: {
                hotelId: venue?.id,
                // other booking details
            },
        });
    };

    return (
        <>
            <Header />
            <BackArrowHeader />
            <div className="container mx-auto px-4 py-8">
                {venue && (
                    <div className="flex flex-col items-center">
                        {/* Main image section */}
                        <div className="w-full mb-8">
                            <img
                                src={venue.HotelPhotos[0]?.photo_url || 'path_to_default_image.jpg'}
                                alt={venue.name}
                                className="w-full h-96 object-cover rounded-lg"
                            />
                        </div>
                        {/* Details section */}
                        <div className="w-full lg:w-2/3">
                            <h1 className="text-3xl font-bold mb-4 text-center lg:text-left">{venue.name}</h1>
                            <div className="flex flex-col lg:flex-row lg:space-x-12 mb-8">
                                {/* Contact and Address */}
                                <div className="flex-1 mb-4 lg:mb-0">
                                    <div className="flex flex-col space-y-2">
                                        <span className="text-lg"><strong>Email:</strong> {venue.email}</span>
                                        <span className="text-lg"><strong>Phone:</strong> {venue.phone}</span>
                                        <span className="text-lg"><strong>Address:</strong> {venue.address}, {venue.city}, {venue.county}, {venue.eircode}</span>
                                    </div>
                                </div>
                                {/* Spaces section */}
                                <div className="flex-1">
                                    <h2 className="text-xl font-semibold mb-2">Spaces and Meeting Rooms:</h2>
                                    <p>Number of Spaces: {venue.Worxes.length}</p>
                                    {venue.Worxes.map((worx, index) => (
                                        <div key={index} className="mb-2">
                                            <span className="font-semibold">{worx.name || 'Unnamed Space'}:</span>
                                            <span> Capacity: {worx.capacity || 'N/A'}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {/* Hotel Photo Section (if you have more photos) */}
                            <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mb-8">
                                {venue.HotelPhotos.slice(1).map((photo, index) => (
                                    <img key={index} src={photo.photo_url} alt={`Hotel ${index + 1}`} className="w-full h-48 object-cover rounded-lg" />
                                ))}
                            </div>
                            <button 
                                className="bg-orange-500 text-white px-6 py-3 rounded-lg hover:bg-orange-600 transition duration-200"
                                onClick={handleBookingButtonClick}
                            >
                                Book Now
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default VenueDetailPage;
