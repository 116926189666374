import React, { useState, useEffect } from 'react';
import { uploadVenueImageAndGetURL, uploadWorxImageAndGetURL } from "../Api/firebaseService";
import SpacePhotoAPIService from '../Api/spacePhotoAPIService';
import WorxPhotoAPIService from '../Api/worxPhotoAPIService';
import { Photo } from '../Models/photos';

interface ImageUploadComponentProps {
    isWorxUpload: boolean,
    isVendorUpload: boolean,
    id: number,
    existingImageUrl?: string; // Optional prop for an existing image URL
}

const ImageVendorUploadComponent: React.FC<ImageUploadComponentProps> = ({
    isWorxUpload,
    isVendorUpload,
    id,
    existingImageUrl
}) => {
    const [imageUrl, setImageUrl] = useState(existingImageUrl || '');

    const spacePhotoAPIService = new SpacePhotoAPIService;
    const worxPhotoAPIService = new WorxPhotoAPIService;
    useEffect(() => {
        if (existingImageUrl) {
            setImageUrl(existingImageUrl);
        }
    }, [existingImageUrl]);

    const handleImageChange = async (file: File) => {
        let uploadedImageUrl = '';
        try {
            if (isVendorUpload) {
                const deleteVenuePhotosResponse = await spacePhotoAPIService.deleteVenuePhotosFromId(id);
                console.log(deleteVenuePhotosResponse);
                uploadedImageUrl = await uploadVenueImageAndGetURL(file, id);

                const photo: Photo = {
                    photo_url: uploadedImageUrl,
                    isMain: 1,
                    SpaceId: id
                };
                setImageUrl(uploadedImageUrl)
                const response = await spacePhotoAPIService.createSpacePhoto(photo);
                console.log("Vendor photo uploaded", response);
            } else if (isWorxUpload) {
                const deleteSpacePhotosResponse = await worxPhotoAPIService.deleteSpacePhotosFromId(id);
                uploadedImageUrl = await uploadWorxImageAndGetURL(file, id);

                const photo: Photo = {
                    photo_url: uploadedImageUrl,
                    isMain: 1
                };
                setImageUrl(uploadedImageUrl)
                const response = await worxPhotoAPIService.createWorxPhoto(photo, id);
                console.log("Worx photo uploaded", response);
            }

            if (uploadedImageUrl) {
                setImageUrl(uploadedImageUrl);
            }
        } catch (error) {
            console.error("Error uploading image: ", error);
        }
    };

    const handleFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        console.log(file)
        if (file) {
            await handleImageChange(file);
        }
    };

    return (
        <div className="image-upload-wrapper" style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }}>
            <div className="image-upload-container" style={{
                border: imageUrl ? '2px solid #4CAF50' : '2px dashed gray',
                padding: '15px',
                width: '75vw',
                height: '300px',
                backgroundImage: `url(${imageUrl})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat'
            }}>
            </div>

            <input
                type="file"
                accept="image/*"
                onChange={handleFileInputChange}
                style={{ display: 'none' }}
                id="file-upload"
            />
            <label htmlFor="file-upload" style={{
                marginTop: '15px', // Adds space between the image and the button
                cursor: 'pointer',
                padding: '10px 20px',
                backgroundColor: imageUrl ? '#FFA500' : '#007BFF',
                color: 'white',
                borderRadius: '5px',
                fontSize: '16px',
                textAlign: 'center',
            }}>
                {imageUrl ? 'Update Image' : 'Select From Folder'}
            </label>
        </div>
    );
};

export default ImageVendorUploadComponent;
