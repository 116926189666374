import { useEffect } from "react";

type NearTopCallback = (nearTop: boolean) => void;

function useScrollNearTop(
  onNearTop: NearTopCallback,
  pixelsFromTop: number = 100 // Default value set to 100
): void {
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const isNearTop = scrollPosition <= pixelsFromTop;

      onNearTop(isNearTop);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [onNearTop, pixelsFromTop]);
}

export default useScrollNearTop;