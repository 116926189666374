import React, { useState } from 'react'
//import '../App.css';
import Col from 'react-bootstrap/Col';
import DashboardCard from '../Cards/DashboardCard';
import Button from 'react-bootstrap/Button';
import CardGrid from './CardGrid';
import { Link } from 'react-router-dom';

export default function GeneralGrid(props) {
    console.log(props.toBtn)
    return (
        <>
            {props.buttonTitle ? <Link to={props.to}><Button variant="primary">{props.buttonTitle}</Button></Link> : null}
            <CardGrid >
                {props.data.map(card => (
                    <Col key={card.id} xs={12} sm={6} md={6} lg={4} xl={3}>
                        <DashboardCard img="https://upload.wikimedia.org/wikipedia/commons/c/c4/Puffin_%28Fratercula_arctica%29_with_lesser_sand_eels_%28Ammodytes_tobianus%29.jpg"
                            title={card.name}>
                            <Link to={`${props.btnTo}/${card.id}`} state={{data: card}}><Button variant="primary">Edit</Button></Link>
                        </DashboardCard>
                    </Col>
                ))}
            </CardGrid>
        </>
    )
}