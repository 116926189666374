import { ApiService } from "../Api/apiService";
import { SERVER_URL } from "../Utils/constants";
import User from "../Models/adminUser"

export class UserAPIService {
    private apiService: ApiService;

    constructor() {
        this.apiService = new ApiService(SERVER_URL);
    }

    public async createUser(user: User): Promise<any> {
        return this.apiService.create(`users/create`, user);
    }

    public async editUser(profileData: any, userId: number): Promise<any> {
        try {
            const response = await this.apiService.put(`users/update/${userId}`, profileData); // Ensure the URL is correct
            if (response.status === 200) {
                return { success: true, body: response.body };
            } else {
                return { success: false, message: response.data.message || 'Failed to update profile' };
            }
        } catch (error: any) {
            console.error('Error in editUser:', error);
            return { success: false, message: error.message || 'Failed to update profile' };
        }
    }

    public async getFutureBookings(userId: number) {
        return await this.apiService.get(`booking/future-bookings?userId=${userId}`)
    }

    public async getPastBookings(userId: number) {
        return await this.apiService.get(`booking/past-bookings?userId=${userId}`)
    }

    public async getLikedSpaces(userId: number): Promise<any> {
        return await this.apiService.get(`likespace/user/${userId}/liked-spaces`);
    }

    public async getLikedWorxes(userId: number): Promise<any> {
        return await this.apiService.get(`likespace/user/${userId}/liked-worxes`);
    }
}
