// src/PrivacyPolicyPage.tsx
import React from 'react';
import PrivacyPolicyIframe from './components/PrivacyPolicyIframe';
import BackArrowHeader from './components/backArrow';

const PrivacyPolicyPage: React.FC = () => {
  return (
    <div className='p-4'>
      <div className='mb-4'>
        <BackArrowHeader />
      </div>

      <PrivacyPolicyIframe />
    </div >
  );
};

export default PrivacyPolicyPage;