import React, { useState } from 'react';
import './register2.css';
import { AuthResponse, login, sendPasswordResetEmailFirebase, signUp } from "../Api/firebaseService";
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { userState } from "../State/userAtom";
import { UserAPIService } from "../Api/userAPIService";
import * as utils from "../Utils/utils";
import { Toast } from '@capacitor/toast'; // Import Capacitor Toast
import { SERVER_URL } from '../Utils/constants';

const Register2 = ({ isLogin, userType }) => {
    const userAPIService = new UserAPIService();
    const [user, setUser] = useRecoilState(userState);

    const [step, setStep] = useState(1);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isEmailError, setIsEmailError] = useState(false);
    const [emailErrorText, setEmailErrorText] = useState('');
    const [forgotPassword, setForgotPassword] = useState(false);

    const navigate = useNavigate();

    const navigateToHomepage = () => {
        navigate(`/`);
    }

    const navigateToVendorpage = (result) => {
        navigateToSpace(result.data.body.user);
    }

    const navigateToSpace = (user: SpaceAdmin) => {

        console.log(user)

        if (user.Space !== null && user.Space !== undefined) {
            setTimeout(() => {
                const spaceId = user.Space.id;

                setUser((currentUser) => ({
                    ...currentUser,
                    bbdUser: {
                        ...currentUser.bbdUser,
                        Space: [spaceId] // Adds the new ID to the Space array
                    }
                }));
                navigate(`/space?id=${spaceId}`);
            }, 50);

            return;
        }

        if (user.Space === null || user.Space !== undefined) {
            navigate(`/space/create`);
            return;
        }

        navigate(`/space/create`);
    };

    // Function to handle the next button click
    const handleNext = (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            alert("Passwords do not match");
            return;
        }
        setStep(2);
    };

    // Function to handle the registration form submission
    const handleRegister = async (e) => {
        e.preventDefault();
        console.log('First Name:', firstName);
        console.log('Last Name:', lastName);
        console.log('Email:', email);
        console.log('Password:', password);
        console.log('User Type:', userType);

        try {
            const authResponse = await signUp(email, password, firstName, lastName);
            if (authResponse.success) {
                console.log('User registered successfully');
                const newUser = {
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    profilePicture: 'empty',
                    role: 1,
                    uuid: authResponse.user?.id,
                    UserTypeId: userType,
                };

                const response = await userAPIService.createUser(newUser);
                console.log(response);

                // Update Recoil state
                setUser({ firebaseUser: authResponse.user, bbdUser: response.body });

                if (userType == 1) {
                    navigateToHomepage();
                } else {
                    navigate(`/space/create`);
                    //TODO: NAVIGATE TO SPACES?
                }
            } else {
                if (authResponse.error.includes("email-already-in-use")) {
                    console.error("An account with this email already exists.");
                    setIsEmailError(true);
                    setEmailErrorText("An account with this email already exists.");
                } else {
                    console.error(authResponse.error);
                }
            }
        } catch (error) {
            console.error('Registration failed:', error);
        }
    };

    /**
     * Function to handle the login form submission
     */
    const handleLogin = (e) => {
        e.preventDefault();
        console.log('Email:', email);
        console.log('Password:', password);
        console.log('User Type:', userType);

        login(email, password).then(async (authResponse) => {
            if (authResponse.success && authResponse.user) {
                console.log(authResponse.user?.id);
                console.log('User logged in successfully');

                try {
                    const result = await utils.bodyRequest(
                        `${SERVER_URL}auth/login`,
                        { uuid: authResponse.user.id },
                        "POST"
                    );
                    console.log(result);

                    setUser({ firebaseUser: authResponse.user, bbdUser: result.data.body });
                    if (result.data.body.user.UserTypeId.toString() == 1) {
                        navigateToHomepage();
                    } else if (result.data.body.user.UserTypeId.toString() == 2) {
                        navigateToVendorpage(result)
                    } else {
                        console.error("No User permissions");
                    }
                } catch (error) {
                    console.error('Failed to fetch user data:', error);
                }
            } else {
                console.error('Login failed:', authResponse.error);
            }
        });
    };

    const handleForgetPassword = () => {
        setForgotPassword(true);
    }

    const handlePasswordReset = async () => {
        try {
            const response = await sendPasswordResetEmailFirebase(email);
            if (response === true) {
                await Toast.show({
                    text: 'Password reset email sent! Check your inbox.',
                    duration: 'long',
                });
            } else {
                await Toast.show({
                    text: response,
                    duration: 'long',
                });
            }
            setForgotPassword(false);
        } catch (error) {

            setForgotPassword(false);
        }
    };

    return (
        <div className="register-container w-full h-full">
            <form className="form" onSubmit={step === 1 ? (isLogin ? handleLogin : handleNext) : handleRegister}>
                <h2>{isLogin ? 'Sign In' : 'Sign Up'}</h2>
                {step === 1 && (
                    <>
                        {
                            forgotPassword &&
                            (
                                <div><p>We will send an email with instructions to recover your password</p></div>
                            )
                        }
                            <input
                                type="email"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="input-field"
                            />
                        {!forgotPassword && (
                                <input
                                    type="password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="input-field"
                                />
                        )}
                        {isLogin && !forgotPassword && (
                            <div className="flex justify-end mb-4 underline" onClick={handleForgetPassword}>
                                Forgot Password?
                            </div>
                        )}
                        {!isLogin && !forgotPassword && (
                                <input
                                    type="password"
                                    placeholder="Enter your Password again"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    className="input-field"
                                />
                        )}
                        {
                            !forgotPassword ? (
                                <button type="submit" className={`button-form ${password.length > 8 && email ? 'animate-pulse' : ''}`}>{isLogin ? 'Sign in' : 'Next'}</button>
                            ) : (
                                <button onClick={handlePasswordReset} className="button-form">Send Email</button>
                            )
                        }
                    </>
                )}
                {step === 2 && !isLogin && (
                    <>
                            <input
                                type="text"
                                placeholder="Enter your First Name"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                className="input-field"
                            />
                            <input
                                type="text"
                                placeholder="Enter your Last Name"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                className="input-field"
                            />
                        <button type="submit" className="button-form">Sign up</button>
                    </>
                )}
            </form>
            {isEmailError && <p className="error-message">{emailErrorText}</p>}
        </div>
    );
};

export default Register2;
