import React from 'react';
import Stack from 'react-bootstrap/Stack';

const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(':');
    return `${hours}:${minutes}`;
};

function ListItem(props) {
    return (
        <Stack direction="horizontal" gap={3} className="border-b border-gray-300 py-2">
            <div className="flex-grow">{props.name}</div>
            <div className="flex-grow">{props.bookingDate}</div>
            <div className="flex-grow">{formatTime(props.from_time)}</div>
            <div className="flex-grow">{formatTime(props.to_time)}</div>
        </Stack>
    );
}

function List(props) {
    const items = props.items.map((item) => (
        <ListItem
            key={item.id}
            name={"Test"} // Replace with actual name logic
            bookingDate={item.bookingDate}
            from_time={item.from_time}
            to_time={item.to_time}
        />
    ));

    return <div>
        <Stack direction="horizontal" gap={3} className="border-b border-gray-300 py-2 font-bold">
            <div className="flex-grow">Space Name</div>
            <div className="flex-grow">Date</div>
            <div className="flex-grow">From</div>
            <div className="flex-grow">To</div>
        </Stack>{items}
    </div>;
}

function BookingDashboardList(props) {
    return (
        <div>
            <h2 className="text-lg font-semibold mb-4">{props.title}</h2>
            <List items={props.bookings} />
        </div>
    );
}

export default BookingDashboardList;