import React from "react";
import { useState, useEffect } from "react";
import { SpaceDetail } from "../Models/spaceDetails";
import MainContentImageDisplay from "./mainContentImageDisplay.tsx";
import WorxinnMap from "../Components/map.tsx";
import EditableTextField from "../Components/editableTextField.tsx";
import { CreateDealModal } from "../Components/Worx/createModal.tsx";
import { useDebounce } from "../Hooks/usedDebounce";
import { userState } from "../State/userAtom";
import { useRecoilState, useRecoilValue } from "recoil";
import { useNavigate, useSearchParams } from "react-router-dom";
import Header from "../Components/header.tsx";
import SpaceDisplay from "./spaceDisplay.tsx";
import { FiArrowLeft } from "react-icons/fi";
import { RiParentLine } from "react-icons/ri";
import { SpaceAPIService } from "../Api/spaceAPIService.ts";
import {
  deleteUserById,
  signUp,
  AuthResponse,
  login,
  isLoggedIn,
  logOut,
  createJoinCode,
} from "../Api/firebaseService.ts";
import { AiOutlineDelete } from "react-icons/ai";
import { BiCheck, BiLink, BiSave, BiUser } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import { signOut } from "firebase/auth";
//impiort globe
import { BiGlobe } from "react-icons/bi";
import { AnimatePresence, motion } from "framer-motion";
import { GiTick } from "react-icons/gi";
import useScrollNearBottom from "../Hooks/useScrollNearBottom.ts";
import useScrollNearTop from "../Hooks/useScrollNearTop.ts";
import getLatLngFromEircode from "../Hooks/getLatLngFromEircode.ts";
import { eircodesState } from "../State/eircodesAtom.ts";
import WorxAPIService from "../Api/worxAPIService.ts";
import ConfirmationModal from "./confirmationModal.tsx";
import { WorxDetail } from "../Models/worxDetails.ts";
import { spaceListState } from "../State/spaceAtom.ts";
import SpacePhotoAPIService from "../Api/spacePhotoAPIService.ts";
import { HotelPhoto } from "../Models/hotelPhoto.js";
import { Photo } from "../Models/photos.ts";
import { useAsync } from "../Hooks/useAsync.tsx";
import OpeningClosingTimePicker from "../Components/timePicker.tsx";
import ImageUploadComponent from "../Components/UploadImage.tsx";
import { SERVER_URL } from "../Utils/constants.js";
import ImageVendorUploadComponent from "../Components/imageVendorUploadComponent.tsx";

function useStateSafe<T>(initialValue: T | null) {
  const [state, setState] = useState(initialValue);
  const setSafeState = (newValue: T | null) => {
    if (newValue !== null) {
      setState(newValue);
    }
  };
  return [state, setSafeState] as const;
}

interface SpaceManagementPageProps {
  space: SpaceDetail | null;
  userIntials: string;
  parentIds: number[] | null;
}

interface Field {
  name: string;
  label: string;
  type: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function SpaceManagementPage({
  space,
  userIntials,
  parentIds,
}: SpaceManagementPageProps) {
  const terms = `The standard Lorem Ipsum passage, used since the 1500s
"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."

Section 1.10.32 of "de Finibus Bonorum et Malorum", written by Cicero in 45 BC
"Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"

1914 translation by H. Rackham
"But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?"

Section 1.10.33 of "de Finibus Bonorum et Malorum", written by Cicero in 45 BC
"At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat."

1914 translation by H. Rackham
"On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness of will, which is the same as saying through shrinking from toil and pain. These cases are perfectly simple and easy to distinguish. In a free hour, when our power of choice is untrammelled and when nothing prevents our being able to do what we like best, every pleasure is to be welcomed and every pain avoided. But in certain circumstances and owing to the claims of duty or the obligations of business it will frequently occur that pleasures have to be repudiated and annoyances accepted. The wise man therefore always holds in these matters to this principle of selection: he rejects pleasures to secure other greater pleasures, or else he endures pains to avoid worse pains."`;

  const navigate = useNavigate();

  const convertListOfPhotosToRecord = (
    photos: HotelPhoto[]
  ): Record<number, string> => {
    const recordObj: Record<number, string> = {};
    for (let i = 0; i < photos.length; i++) {
      recordObj[i] = photos[i].photo_url;
    }
    return recordObj;
  };

  const currentUser = useRecoilValue(userState);
  const spaceAPIService = new SpaceAPIService();
  const spacePhotoAPIService = new SpacePhotoAPIService();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [currentSelectedEditableField, setCurrentSelectedEditableField] =
    useState<string>("");

  const [spaceData, setSpaceData] = useStateSafe<SpaceDetail>(space || null);
  const [currentHotelPictures, setCurrentHotelPictures] = useState<
    Record<number, string>
  >(convertListOfPhotosToRecord(spaceData?.HotelPhotos || []));
  const [createNewDeal, setcreateNewDeal] = useState(false);

  let userSpace = currentUser.bbdUser.Space && currentUser.bbdUser.Space.length > 0 ?
    currentUser.bbdUser.Space[0] :
    currentUser.bbdUser.user.Space[0];
  const currentUserPermissionLevel = userSpace == space.id ? 1 : 0;

  const isUserInitialsTwoQuestionMarks = userIntials === "??";
  const [eircodes, setEircodes] = useRecoilState(eircodesState);


  const isEditable: boolean = currentUserPermissionLevel >= 1;

  const hasParent: boolean = parentIds !== null && parentIds?.length > 0;

  const doesUserNameContainQuestionMark =
    currentUser.firebaseUser.firstName.includes("?") || currentUser.firebaseUser.lastName.includes("?");
  const [popupTopMargin, setPopupTopMargin] = useState("90px");
  const [popupBottomMargin, setPopupBottomMargin] = useState("5vh");
  const [popupHeight, setPopupHeight] = useState("90px");
  const ref = React.useRef<HTMLDivElement>(null);
  const [showActivationModal, setShowActivationModal] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);
  const [shake, setShake] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repassword, setRePassword] = useState("");
  const [isNew, setIsNew] = useState(true);
  const [showSaveButton, setShowSaveButton] = useState(
    doesUserNameContainQuestionMark
  );
  const [user, setUser] = useRecoilState(userState);
  const [spaceListCache, setSpaceListCache] = useRecoilState(spaceListState);
  const [lng, setLng] = useState(0);
  const [lat, setLat] = useState(0);

  const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const [availability, setAvailability] = useState({
    Monday: { open: null, close: null, isSelected: false },
    Tuesday: { open: null, close: null, isSelected: false },
    Wednesday: { open: null, close: null, isSelected: false },
    Thursday: { open: null, close: null, isSelected: false },
    Friday: { open: null, close: null, isSelected: false },
    Saturday: { open: null, close: null, isSelected: false },
    Sunday: { open: null, close: null, isSelected: false },
  });

  const [minBookingDays, setMinBookingDays] = useState(0);

  const handleDaySelectionChange = (day, isSelected) => {
    setAvailability((prev) => ({
      ...prev,
      [day]: { ...prev[day], isSelected },
    }));

    setShowSaveButton(true)
  };

  const handleOpeningTimeChange = (day, time) => {
    setAvailability((prev) => ({
      ...prev,
      [day]: { ...prev[day], open: time },
    }));
    setShowSaveButton(true)
  };

  const handleClosingTimeChange = (day, time) => {
    setAvailability((prev) => ({
      ...prev,
      [day]: { ...prev[day], close: time },
    }));
    setShowSaveButton(true)
  };

  const validate = () => {
    if (
      isNew &&
      (firstName === "" ||
        lastName === "" ||
        email === "" ||
        password !== repassword)
    ) {
      setShake(true);
      return false;
    } else if (!isNew && (email === "" || password === "")) {
      setShake(true);
      return false;
    }
    return true;
  };

  const navigateToSpace = (user: any) => {
    const numberOfSpaces = Object.keys(user.spaces).length;

    if (numberOfSpaces === 1) {
      setTimeout(() => {
        const spaceId = Object.keys(user.spaces)[0];
        navigate(`/space?id=${spaceId}`);
        window.location.reload();
      }, 50);

      return;
    }

    if (numberOfSpaces === 0) {
      navigate(`/spaces?total=${0}`);
      return;
    }

    navigate(`/spaces?total=${numberOfSpaces}`);
  };

  const fieldStyle = "border border-gray-400 p-2 mb-4 rounded-lg";

  function convertDateToTimeString(date: Date): string {
    console.log(date)
    const hours = String(date).padStart(2, '0');
    const minutes = String(date).padStart(2, '0');
    const seconds = String(date).padStart(2, '0');

    return `${date}`;
  }

  const onOpeningTimeChange = (openingTime: Date) => {
    //console.log(convertDateToTimeString(openingTime))

    let space: SpaceDetail | null = { ...spaceData! };
    setSpaceData({
      ...space,
      openingHour: convertDateToTimeString(openingTime)
    })
  }

  const onClosingTimeChange = (closingTime: Date) => {

    let space: SpaceDetail | null = { ...spaceData! };
    setSpaceData({
      ...space,
      closingHour: convertDateToTimeString(closingTime)
    })
  }

  const fields: { [key: string]: Field } = {
    "first name": {
      name: "first name",
      label: "First Name",
      type: "text",
      value: firstName,
      onChange: (e) => setFirstName(e.target.value),
    },
    "last name": {
      name: "last name",
      label: "Last Name",
      type: "text",
      value: lastName,
      onChange: (e) => setLastName(e.target.value),
    },
    email: {
      name: "email",
      label: "Email",
      type: "email",
      value: email,
      onChange: (e) => setEmail(e.target.value),
    },
    password: {
      name: "password",
      label: "Password",
      type: "password",
      value: password,
      onChange: (e) => setPassword(e.target.value),
    },
    confirmPassword: {
      name: "confirmPassword",
      label: "Confirm Password",
      type: "password",
      value: repassword,
      onChange: (e) => setRePassword(e.target.value),
    },
  };

  const openDeleteModal = () => setIsDeleteModalOpen(true);
  const closeDeleteModal = () => setIsDeleteModalOpen(false);

  const activeFields = isNew
    ? ["first name", "last name", "email", "password", "confirmPassword"]
    : ["email", "password"];

  const openTermsModal = () => {
    setShowActivationModal(false);
    setShowTermsModal(true);
  };

  const returnToActivationModal = () => {
    setShowTermsModal(false);
    setShowActivationModal(true);
  };

  const handleReturnToTermsModal = () => {
    setShowRegistrationModal(false);
    openTermsModal();
  };

  const openRegistrationModal = () => {
    setShowTermsModal(false);
    setShowActivationModal(false);
    setShowRegistrationModal(true);
  };

  const { data, isLoading, error } = useAsync(async () => {
    if (!spaceData) return;
    if (spaceData.eircode === "") return;
    const eircodeWithNoSpaces = spaceData.eircode.replace(/\s/g, "");
    const location = await getlngLatFromEircode(eircodeWithNoSpaces);

    setLng(location.lng);
    setLat(location.lat);
    setSpaceData(previous => ({
      ...previous,
      lat: location.lat,
      lng: location.lng
    }));
    return location;
  }, [spaceData?.lat, spaceData.lng, spaceData.eircode]);

  useEffect(() => {
    if (data) {
      setLng(data.lng);
      setLat(data.lat);

      setSpaceData(previous => ({
        ...previous,
        lat: data.lat,
        lng: data.lng
      }));
    }
  }, [data]);

  useEffect(() => {
    if (ref.current) {
      setPopupHeight(`${ref.current.clientHeight}px`);
    }
  }, []);

  useScrollNearBottom((nearBottom: boolean) => {
    if (nearBottom) {
      setPopupBottomMargin("22vh");
    } else {
      setPopupBottomMargin("5vh");
    }
  }, 200);

  useScrollNearTop((nearTop: boolean) => {
    if (nearTop) {
      setPopupTopMargin("100px");
    } else {
      setPopupTopMargin("15px");
    }
  }, 70);

  useEffect(() => {
    if (
      space !== null &&
      space !== undefined &&
      Object.keys(space).length > 0
    ) {
      setSpaceData(space);
      setMinBookingDays(space.daysInAdvance)
    }
  }, [space]);

  if (currentUser === null) {
    navigate("/registration?new=0");
  }

  const handleDealCreation = () => {
    setcreateNewDeal(!createNewDeal);
  };

  const placeholderDescription =
    "lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua";

  function updateProperty<T extends Record<string, any>>(
    obj: T,
    propName: string,
    newValue: any
  ): T {
    if (Object.prototype.hasOwnProperty.call(obj, propName)) {
      return { ...obj, [propName]: newValue };
    }
    throw new Error(`Property ${propName} does not exist on object`);
  }

  const handleEditableTestSelection = (field: string) => {
    setCurrentSelectedEditableField(field);
    handleImageEditorVisability(field !== "");
  };

  const setSpace = (prop: string, value: any) => {
    let newSpace: SpaceDetail | null = null;
    const hasProp = Object.prototype.hasOwnProperty.call(spaceData, prop);
    if (hasProp) {
      newSpace = { ...spaceData!, [prop]: value };
      setSpaceData(newSpace);
    } else {
      // Handle the error case
    }

    return newSpace;
  };

  const translateSpecifiedFieldsAsUpdatesToAnotherObject = async (fiel: {
    [key: string]: string;
  }) => {
    let location_: any = null;

    const hasEircode = Object.prototype.hasOwnProperty.call(fiel, "eircode");

    let space: SpaceDetail | null = { ...spaceData! };

    if (hasEircode) {
      const eircode = fiel["eircode"];

      const hasLocation = Object.keys(eircodes).includes(eircode);
      let newEircodes;

      if (hasLocation) {
        location_ = eircodes[eircode];
      } else {
        location_ = await getLatLngFromEircode(eircode);
        newEircodes = { ...eircodes, [eircode]: location_ };
        setEircodes(newEircodes);
      }
    }

    Object.keys(fiel).forEach((field) => {
      space = { ...space!, [field]: fiel[field] };
    });

    if (location_) {
      Object.keys(location_).forEach((location) => {
        space = { ...space!, [location]: location_[location] };
      });

      setLng(location_["lng"]);
      setLat(location_["lat"]);
    }

    let address_ = `${fiel["street"]}, ${spaceData?.city}, ${spaceData?.county}, ${spaceData?.eircode}`;

    space = { ...space!, ["address"]: address_, lat: lat, lng: lng };

    setSpaceData(space);

    return space;
  };

  useEffect(() => {
    daysOfWeek.forEach(day => {
      const openKey = `opening_time_${day.toLowerCase()}`;
      const closeKey = `closing_time_${day.toLowerCase()}`;
      const openTime = spaceData.VenueAvailability[openKey];
      const closeTime = spaceData.VenueAvailability[closeKey];
      const isSelected = openTime !== null && closeTime !== null;

      // Update each day's selected status based on availability data
      handleDaySelectionChange(day, isSelected);

      // Update opening and closing times
      if (openTime !== null) {
        handleOpeningTimeChange(day, openTime);
      }
      if (closeTime !== null) {
        handleClosingTimeChange(day, closeTime);
      }
    });
  }, [spaceData.VenueAvailability]); // Only rerun if availabilityData changes

  const handleFieldSave = async (field: string, value: any) => {
    const isObject = !value.text;
    let newSpace: SpaceDetail | null = null;

    const val = !isObject ? value.text : value;

    if (isObject) {
      newSpace = await translateSpecifiedFieldsAsUpdatesToAnotherObject(value);
    } else {
      newSpace = setSpace(field, val);
    }

    if (newSpace === null || value === undefined) return;

    //checks if account is activated
    if (doesUserNameContainQuestionMark) return;

    let newSpaceList = spaceListCache.filter(
      (space: SpaceDetail) => space.id !== newSpace!.id
    );

    newSpaceList.push(newSpace!);

    setSpaceListCache(newSpaceList);
  };

  const debouncedHandleFieldSave = useDebounce(handleFieldSave, 100);
  const dataURLtoFile = (dataurl: string, filename: string) => {
    var arr = dataurl.split(",") as any,
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const getTimeInMillis = () => {
    return new Date().getTime();
  };

  const handleDeleteSpace = async () => {
    if (doesUserNameContainQuestionMark) return;
    if (!spaceData) return;
    const resp = await spaceAPIService.deleteSpace(spaceData.id);
    setTimeout(() => {
      if (parentIds && parentIds.length > 0) {
        const parentId = parentIds[0];
        navigate(`/space?id=${parentId}`);
        window.location.reload();
      } else {
        navigate("/spaces");
      }
    }, 50);
    closeDeleteModal();
  };

  const createPhotoFromUrl = (url: string): Photo => {
    return {
      photo_url: url,
      isMain: 0,
    };
  };

  const listToRecord = (arr: string[]): Record<number, string> => {
    const recordObj: Record<number, string> = {};
    for (let i = 0; i < arr.length; i++) {
      recordObj[i] = arr[i];
    }
    return recordObj;
  };

  const getlngLatFromEircode = async (eircode: string) => {
    const location = await getLatLngFromEircode(eircode);
    if (!location) return;
    return { lng: location.lng, lat: location.lat };
  };

  const photoRecordToListOfPhotos = (
    photos: Record<number, string>
  ): Photo[] => {
    const photoList: Photo[] = [];
    Object.values(photos).forEach((photo) => {
      photoList.push(createPhotoFromUrl(photo));
    });
    return photoList;
  };

  const savePhotos = async () => {
    const images = photoRecordToListOfPhotos(currentHotelPictures);

    if (images.length == 0) return;

    handleFieldSave("main_photo", images[0].photo_url);

    handleFieldSave("WorxPhotos", images);

    let resp = await spacePhotoAPIService.createBulkSpacePhoto(
      images,
      spaceData!.id
    );
  };

  const createAvailabilityPayload = (availability) => {
    const payload = {
      opening_time_monday: availability.Monday.isSelected ? availability.Monday.open : null,
      closing_time_monday: availability.Monday.isSelected ? availability.Monday.close : null,
      opening_time_tuesday: availability.Tuesday.isSelected ? availability.Tuesday.open : null,
      closing_time_tuesday: availability.Tuesday.isSelected ? availability.Tuesday.close : null,
      opening_time_wednesday: availability.Wednesday.isSelected ? availability.Wednesday.open : null,
      closing_time_wednesday: availability.Wednesday.isSelected ? availability.Wednesday.close : null,
      opening_time_thursday: availability.Thursday.isSelected ? availability.Thursday.open : null,
      closing_time_thursday: availability.Thursday.isSelected ? availability.Thursday.close : null,
      opening_time_friday: availability.Friday.isSelected ? availability.Friday.open : null,
      closing_time_friday: availability.Friday.isSelected ? availability.Friday.close : null,
      opening_time_saturday: availability.Saturday.isSelected ? availability.Saturday.open : null,
      closing_time_saturday: availability.Saturday.isSelected ? availability.Saturday.close : null,
      opening_time_sunday: availability.Sunday.isSelected ? availability.Sunday.open : null,
      closing_time_sunday: availability.Sunday.isSelected ? availability.Sunday.close : null,
    };

    return JSON.stringify(payload);
  };

  const handleSaveAllChanges = async () => {
    setShowActivationModal(true);

    if (!spaceData) return;

    const resp = await spaceAPIService.updateSpace(spaceData.id, spaceData);


    const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MDczODY3NTIsImV4cCI6NDg2MzE0Njc1Mn0.BN4b9hekXmxND9Pgh1ZLb9Et0761TOshWhSnPc1EA30';

    console.log(availability)

    const payload = createAvailabilityPayload(availability)

    console.log(payload)

    const responseAvailability = await fetch(`${SERVER_URL}space-availability/update/${spaceData.VenueAvailability.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}` // Add authorization header
      },
      body: payload
    });

    await savePhotos();
  };

  const updateDaysInAdvance = (days) => {
    setSpaceData(prevState => ({
      ...prevState,
      daysInAdvance: days
    }));

    setMinBookingDays(days)
  };

  const handleGallaryOnSave = async (images: string[]) => {
    if (!space) return;
    const imagesRecord = listToRecord(images);
    setCurrentHotelPictures(images);

    if (doesUserNameContainQuestionMark) {
      return;
    }
  };

  const getReturnLink = () => {
    if (parentIds) {
      const parentId = parentIds[0];
      return `/space?id=${parentId}`;
    }
    if (spaceData) return `/space?id=${spaceData.id}`;
    return "/spaces";
  };

  const saveEverything = () => {
    return;
    if (!spaceData) return;
    spaceAPIService.updateSpace(spaceData!.id, spaceData!);
  };

  const deleteAccountAndClearAllData = async () => {
    const id = currentUser.bbdUser.id;
    deleteUserById(id);
    localStorage.clear();
    logOut();
  };

  const handleAccountActivation = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!spaceData) return;

    if (!validate()) return;

    if (spaceData) {
      spaceAPIService.updateSpace(spaceData.id, spaceData);
      await savePhotos();
    }

    deleteAccountAndClearAllData();

    let spaces = { [`${spaceData.id}`]: 5 };

    const authResponse = await signUp(
      email,
      password,
      firstName,
      lastName,
      spaces
    );

    if (authResponse.user) {
      setUser(authResponse.user);
      navigateToSpace(authResponse.user);
    } else if (authResponse.error) {
      window.location.reload();
    }
  };

  const handleAccountDeactivation = async () => {
    setShowActivationModal(false);
    setShowSaveButton(true);
  };

  const handleTermsAndConditions = async () => {
    openTermsModal();
  };

  if ((spaceData && Object.keys(spaceData).length === 0) || !spaceData) {
    return (
      <div className="w-screen h-screen flex items-center justify-center text-2xl">
        Loaded No Data
      </div>
    );
  }

  const details = spaceData.details
    ? spaceData.details
    : spaceData.Worxes.find((worx: WorxDetail) => worx.description)
      ?.description || placeholderDescription;

  const calculateTopMarginBasedOnPopUpHeight = () => {
    if (doesUserNameContainQuestionMark) {
      return showActivationModal ? "20vh" : "20px";
    } else {
      return "15px";
    }
  };

  const handleImageEditorVisability = (isVisable: boolean): void => {
    setShowActivationModal(false);
    setShowTermsModal(false);
    setShowRegistrationModal(false);
    setShowSaveButton(!isVisable);
  };

  const handleGenerateJoinLink = async () => {
    const code = await createJoinCode();
    const link = `${window.location.origin}/join/${spaceData.id}/${code}`;
    navigator.clipboard.writeText(link);
  };

  return (
    <AnimatePresence>
      <div className="w-full flex flex-col items-center relative overflow-hidden">
        {createNewDeal && (
          <div className="fixed w-screen h-screen z-5">
            <div className="relative h-full w-full flex flex-col justify-center items-center">
              <div
                onClick={handleDealCreation}
                className="cursor-pointer bg-black/60 w-full h-full"
              ></div>
              <CreateDealModal
                onClose={() => { }}
                handleDealCreation={handleDealCreation}
                handleSpaceChange={() => { }}
              />
            </div>
          </div>
        )}
        <Header userInitials={userIntials} />
        {doesUserNameContainQuestionMark &&
          !showTermsModal &&
          showActivationModal && (
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: -120 }}
              exit={{ opacity: 0, y: 50 }}
              transition={{ duration: 0.5 }}
              className="w-full h-full fixed z-[1000] mt-[14.5vh] pointer-events-none"
            >
              <div
                ref={ref}
                key={"activation_popup"}
                className="fixed z-[1000] flex flex-col items-center justify-center w-full space-y-2"
                style={{
                  transition: "margin-top 0.2s ease-out",
                  marginTop: popupTopMargin,
                }}
              >
                <div className="w-full max-w-3xl flex items-center justify-center">
                  <div
                    className={`${createNewDeal ? "pointer-events-none" : ""
                      } h-full w-full bg-white/70 flex flex-col p-6 outline-black/40 backdrop-blur-[15px] outline outline-[0.1px] shadow-2xl max-w-[1280px] items-start justify-center rounded-xl pointer-events-auto`}
                  >
                    <div
                      className={`w-full h-full flex justify-center ${false
                        ? "flex-col items-start text-3xl"
                        : "flex-row text-xl items-center"
                        }`}
                    >
                      <div className="absolute w-full">
                        <div className="xl:h-[35px] h-[32px] w-[100px] mr-6 mb-4 ml-auto">
                          <img
                            className="w-full h-full object-contain"
                            src="//images.squarespace-cdn.com/content/v1/62544e35ff5978408919fe05/ccfd61e4-c1b4-48c3-972a-8a16a8cd4272/Logo_black.png?format=1500w"
                            alt="WORXINN"
                          />
                        </div>
                      </div>
                      <div className="text-black opacity-70 text-3xl mb-[10px] w-full text-start font-semibold">
                        {`
                  Activate account to enable saving.   `}
                      </div>
                    </div>
                    <div className="w-full h-full">
                      <div className="text-black opacity-70 w-full text-start font-semibold mb-6">
                        {`
                 By activiting the account you agree to the terms & conditions.

                `}
                      </div>
                      <div
                        className={`${createNewDeal ? "pointer-events-none" : ""
                          } h-full w-full flex flex-row items-center rounded-xl`}
                      >
                        <div className="flex flex-row items-start space-x-2">
                          <div
                            onClick={handleTermsAndConditions}
                            className=" rounded-lg bg-black/50 backdrop-blur-[15px] shadow-2xl hover:scale-[1.03] transition-all hover:outline-dashed hover:outline-2 h-full hover:shadow-md hover hover:outline-offset-1 cursor-pointer py-1 px-3 font-semibold flex flex-row items-center justify-center outline-black/40 outline outline-[0.1px]"
                          >
                            <BiGlobe className="mr-2 text-white" />
                            <div className="text-white">
                              {"Terms & Condtions"}
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-row space-x-2 ml-auto ">
                          <div
                            onClick={handleTermsAndConditions}
                            className="w-[50%] rounded-md shadow bg-green-200 hover:scale-[1.03] transition-all hover:outline-dashed hover:outline-2 h-full hover:outline-black/40 hover:shadow-md hover hover:outline-offset-1 cursor-pointer py-1 px-3 font-semibold flex flex-row items-center justify-center outline-black/40 outline outline-[0.1px]"
                          >
                            <BiCheck className="mr-2 text-black" />
                            <div className="text-black">{"Activate"}</div>
                          </div>
                          <div
                            onClick={handleAccountDeactivation}
                            className="w-[50%] transition-all  shadow hover:scale-[1.03] hover:outline-dashed hover:outline-2 hover:outline-black/40 h-full hover:shadow-md hover 
                            hover:outline-offset-1 cursor-pointer rounded-md bg-red-200 py-1 px-3 font-semibold flex flex-row items-center justify-center 
                            outline-black/40 outline outline-[0.1px]"
                          >
                            <RxCross2 className="mr-2 text-black" />
                            <div className="text-black">{"Deactivate"}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full max-w-3xl flex items-center justify-center translate-y-[-2px]"></div>
              </div>
            </motion.div>
          )}

        {showTermsModal && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: -120 }}
            exit={{ opacity: 0, y: 50 }}
            transition={{ duration: 0.5 }}
            className="fixed top-0 left-0 w-screen h-[200vh] flex items-start justify-center z-[1100] bg-black/30 "
          >
            <div
              style={{
                transition: "margin-top 0.2s ease-out",
                transform: "translateY(140px)",
                marginTop: popupTopMargin,
              }}
              className="w-full max-w-md mx-auto tline-black/70 z-[110] bg-white/80 backdrop-blur-[105px] p-4 rounded-lg shadow-lg pointer-events-auto"
            >
              <h2 className="text-lg font-bold mb-2">Terms & Conditions</h2>
              <div className="overflow-auto h-64 mb-4 p-2 border border-gray-200 rounded">
                {terms}
              </div>
              <div className="flex justify-between">
                <div
                  onClick={returnToActivationModal}
                  className=" rounded-lg bg-black/50 backdrop-blur-[15px] shadow-2xl hover:scale-[1.03] transition-all hover:outline-dashed hover:outline-2 h-full hover:shadow-md hover hover:outline-offset-1 cursor-pointer py-1 px-3 font-semibold flex flex-row items-center justify-center outline-black/40 outline outline-[0.1px]"
                >
                  <RxCross2 className="mr-2 text-white" />
                  <div className="text-white">{"Return"}</div>
                </div>
                <div
                  onClick={openRegistrationModal}
                  className="w-[50%] transition-all  shadow hover:scale-[1.03] hover:outline-dashed hover:outline-2 hover:outline-black/40 h-full hover:shadow-md hover hover:outline-offset-1 cursor-pointer rounded-md bg-green-200 py-1 px-3 font-semibold flex flex-row items-center justify-center outline-black/40 outline outline-[0.1px]"
                >
                  <BiCheck className="mr-2 text-black" />
                  <div className="text-black">{"Activate & Agree"}</div>
                </div>
              </div>
            </div>
          </motion.div>
        )}

        <ConfirmationModal
          isOpen={isDeleteModalOpen}
          onClose={closeDeleteModal}
          onConfirm={handleDeleteSpace}
          message="Are you sure you want to delete this space?"
        />

        {showRegistrationModal && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: -120 }}
            exit={{ opacity: 0, y: 50 }}
            transition={{ duration: 0.5 }}
            className="fixed top-0 left-0 w-screen h-[200vh] flex items-start justify-center z-[1100] bg-black/30"
          >
            <div
              style={{
                transition: "margin-top 0.2s ease-out",
                transform: "translateY(140px)",
                marginTop: popupTopMargin,
              }}
              className={`w-full max-w-md z-[1200] mx-auto outline-black/70 bg-white/80 backdrop-blur-[105px] p-4 rounded-lg shadow-lg pointer-events-auto`}
            >
              <h2 className="text-xl font-bold mb-4">Complete Profile</h2>
              <form
                className="flex flex-col duration-300 transition-all"
                onSubmit={handleAccountActivation}
              >
                {activeFields.map((key) => (
                  <div className="flex flex-col" key={fields[key].label}>
                    <label className="text-sm text-gray-500 mb-2">
                      {fields[key].label}
                    </label>
                    <input
                      className={fieldStyle}
                      type={fields[key].type}
                      name={fields[key].name}
                      value={fields[key].value}
                      onChange={fields[key].onChange}
                    />
                  </div>
                ))}{" "}
                <div className="flex justify-between">
                  <div
                    onClick={handleReturnToTermsModal}
                    className=" rounded-lg bg-black/50 backdrop-blur-[15px] shadow-2xl hover:scale-[1.03] transition-all hover:outline-dashed hover:outline-2 h-full hover:shadow-md hover hover:outline-offset-1 cursor-pointer py-1 px-3 font-semibold flex flex-row items-center justify-center outline-black/40 outline outline-[0.1px]"
                  >
                    <RxCross2 className="mr-2 text-white" />
                    <div className="text-white">{"Return"}</div>
                  </div>
                  <button
                    type="submit"
                    className="w-[50%] transition-all  shadow hover:scale-[1.03] hover:outline-dashed hover:outline-2 hover:outline-black/40 h-full 
                    hover:shadow-md hover hover:outline-offset-1 cursor-pointer rounded-md bg-green-200 py-1 px-3 font-semibold flex flex-row items-center 
                    justify-center outline-black/40 outline outline-[0.1px]"
                  >
                    <BiUser className="mr-2 text-black" />
                    <div className="text-black">{"Create Account"}</div>
                  </button>
                </div>
              </form>
            </div>
          </motion.div>
        )}

        <div className="fixed w-screen h-screen items-center justify-center z-[999] flex pointer-events-none">
          <div className="w-[89vw] lg:w-[70vw] max-w-[1280px] h-full flex pointer-events-none">
            {showSaveButton && (
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 50 }}
                transition={{ duration: 0.5 }}
                className="w-full h-full flex "
              >
                <div
                  style={{
                    transition: "margin-bottom 1s ease-in-out",
                    marginBottom: popupBottomMargin,
                  }}
                  onClick={handleSaveAllChanges}
                  className="w-[130px] h-[38px] pointer-events-auto text-lg mt-auto ml-auto transition-all duration-300 shadow hover:scale-[1.03] 
                  hover:outline-dashed hover:outline-[3px] hover:outline-orange-400/40 hover:shadow-md hover hover:outline-offset-2 cursor-pointer rounded-lg bg-orange-500 
                  py-1 px-3 font-semibold flex flex-row items-center justify-center outline-orange-400/40 outline outline-[0.1px] "
                >
                  <BiSave className="mr-2 text-white text-xl" />
                  <div className="text-white">{"Save"}</div>
                </div>
              </motion.div>
            )}
          </div>
        </div>

        <div
          style={{
            transition: "margin-top 0.5s ease-in-out",
            marginTop: calculateTopMarginBasedOnPopUpHeight(),
          }}
          className={`min-h-[85vh]`}
        >
          <div
            className={`${createNewDeal ? "pointer-events-none" : ""
              } h-full w-[89vw] lg:w-[70vw] max-w-[1280px] flex flex-col items-center`}
          >
            <div className="w-full flex flex-row h-[35px]">
              {hasParent === true && (
                <div className="rounded-lg bg-gray-200 py-1 px-3 font-semibold flex flex-row items-center justify-center  duration-300 hover:outline-dashed hover:outline-2 hover:outline-black/20 hover hover:outline-offset-4 hover:rounded-lg">
                  <a
                    href={getReturnLink()}
                    className="  flex flex-row no-underline text-black group hover:text-orange-500 hover:cursor-pointer items-center"
                  >
                    <FiArrowLeft className="h-full mr-2 text-xl font-bold group-hover:text-orange-500" />
                    <div className="text-base font-semibold group-hover:text-orange-500">
                      {"Return to Parent"}
                    </div>
                  </a>
                </div>
              )}
              {(hasParent === true || currentUser.role >= 10) && (
                <div
                  onClick={handleGenerateJoinLink}
                  className=" rounded-lg bg-orange-500 mr-2 py-1 px-4 cursor-pointer text-white flex flex-row items-center 
                  justify-center duration-100 hover:outline-dashed hover:outline-3 hover:outline-red-500/50 hover hover:outline-offset-4 
                  hover:rounded-lg"
                >
                  <BiLink className="mr-2 " />
                  <div className="font-bold">{"Generate Link"}</div>
                </div>
              )}
              {(hasParent === true || currentUser.role >= 10) && (
                <div
                  onClick={openDeleteModal}
                  className=" rounded-lg bg-red-500 py-1 px-4 cursor-pointer text-white flex flex-row items-center 
                  justify-center duration-100 hover:outline-dashed hover:outline-3 hover:outline-red-500/50 hover hover:outline-offset-4 
                  hover:rounded-lg"
                >
                  <AiOutlineDelete className="mr-2 " />
                  <div className="font-bold">{"Delete Space"}</div>
                </div>
              )}
              <div className="ml-auto flex flex-row space-x-3 h-[40px] justify-end">
                {hasParent === true && (
                  <div className=" rounded-lg bg-gray-200 py-1 px-3 font-semibold flex flex-row items-center justify-center">
                    <RiParentLine className="mr-2" /> <div>{"Child"}</div>
                  </div>
                )}
              </div>
            </div>

            <div id="title-section" className="flex flex-col w-full mt-4 mb-4">
              <div className="w-full mt-2">
                <EditableTextField
                  label="Space Name"
                  currentSelectedEditableField={currentSelectedEditableField}
                  initialValue={
                    spaceData.name ? spaceData.name : "Space #" + spaceData.id
                  }
                  onSave={(value) => debouncedHandleFieldSave("name", value)}
                  tailwindTextStyle="text-xl font-bold"
                  onSelect={(currentSelectedLabel) => {
                    handleEditableTestSelection(currentSelectedLabel);
                  }}
                  onDeselect={() => {
                    handleEditableTestSelection("");
                  }}
                  isEditable={isEditable}
                />
              </div>
              {hasParent === false && (
                <div className="w-full">
                  <EditableTextField
                    label="Address"
                    currentSelectedEditableField={currentSelectedEditableField}
                    initialValue={
                      spaceData.address +
                      ", " +
                      spaceData.city +
                      ", " +
                      spaceData.county +
                      ", " +
                      spaceData.eircode
                    }
                    onSave={(value) =>
                      debouncedHandleFieldSave("address", value)
                    }
                    tailwindTextStyle={`text-md font-semibold ${currentSelectedEditableField == "Address"
                      ? "text-black/100"
                      : "text-black/40"
                      }`}
                    onSelect={(currentSelectedLabel) => {
                      handleEditableTestSelection(currentSelectedLabel);
                    }}
                    onDeselect={() => {
                      handleEditableTestSelection("");
                    }}
                    isAddress={true}
                    isEditable={isEditable}
                  />
                </div>
              )}
            </div>

            <ImageVendorUploadComponent isVendorUpload={true} isWorxUpload={false} id={spaceData.id} existingImageUrl={spaceData.HotelPhotos.length > 0 ? spaceData.HotelPhotos[0].photo_url : ''} />

            <div
              id="image-section"
              className={`${!createNewDeal ? "relative" : ""} ${isEditable ? "cursor-pointer" : ""
                } flex flex-row w-full max-w-[1280px] z-2`}
            >

              {!createNewDeal && (
                <div className="w-full h-full absolute z-[1]"></div>
              )}

              { /*
                <MainContentImageDisplay
                images={currentHotelPictures}
                handleGallaryOnSave={handleGallaryOnSave}
                isEditable={isEditable}
                isVisableCallback={(isVisable: boolean) =>
                  handleImageEditorVisability(isVisable)
                }
              />
              */}
            </div>
            <div
              id="description-section"
              className={`flex flex-col w-full mt-5 ${currentSelectedEditableField == "Description"
                ? "z-[3]"
                : "z-[1]"
                }`}
            >
              <div className="text-md font-semibold focus:outline-0 w-full mb-2">
                Description
              </div>
              <EditableTextField
                label="Description"
                currentSelectedEditableField={currentSelectedEditableField}
                initialValue={details}
                onSave={(value) => debouncedHandleFieldSave("details", value)}
                tailwindTextStyle={`text-sm ${currentSelectedEditableField == "Description"
                  ? "text-black/100"
                  : "text-black/40"
                  }`}
                onSelect={(currentSelectedLabel) => {
                  handleEditableTestSelection(currentSelectedLabel);
                }}
                onDeselect={() => {
                  handleEditableTestSelection("");
                }}
                isEditable={isEditable}
              />
            </div>
          </div>
          {parentIds !== null && parentIds.length <= 0 && (
            <div
              className={`${createNewDeal ? "pointer-events-none" : ""
                } h-full w-[70vw] max-w-[1280px] flex flex-col items-center`}
            >
              <div className="text-md font-semibold focus:outline-0 w-full mb-2 mt-4">
                Spaces
              </div>
              <div
                id="spaces-section"
                className="flex flex-col w-full z-[1] mb-2"
              >
                <SpaceDisplay
                  isEditable={isEditable && !doesUserNameContainQuestionMark}
                  currentSpaceId={spaceData.id}
                  space={spaceData}
                  typeOfSpaceId={2}
                  typeOfSpaceString="Space"
                />
              </div>
            </div>
          )}

          {parentIds !== null && parentIds.length <= 0 && (
            <div
              className={`${createNewDeal ? "pointer-events-none" : ""
                } h-full w-[70vw] max-w-[1280px] flex flex-col items-center`}
            >
              <div className="text-md font-semibold focus:outline-0 w-full mb-2 mt-4">
                Meeting Rooms
              </div>
              <div
                id="spaces-section"
                className="flex flex-col w-full z-[1] mb-2"
              >
                <SpaceDisplay
                  isEditable={isEditable && !doesUserNameContainQuestionMark}
                  currentSpaceId={spaceData.id}
                  space={spaceData}
                  typeOfSpaceId={1}
                  typeOfSpaceString="Meeting Room"
                />
              </div>
            </div>
          )}
          <div
            className={`${createNewDeal ? "pointer-events-none" : ""} ${hasParent ? "mt-10" : ""
              } h-full w-[89vw] lg:w-[70vw] mt-8 max-w-[1280px] flex flex-col items-center`}
          >
            {hasParent === false && (
              <div id="map-section" className="flex flex-col w-full mb-5">
                <div className="text-md font-semibold focus:outline-0 w-full mb-6">
                  Location
                </div>
                <WorxinnMap
                  latitude={spaceData.lat ? spaceData.lat : lat}
                  longitude={spaceData.lng ? spaceData.lng : lng}
                  height={"30vh"}
                  marker={true}
                />
              </div>
            )}

            {hasParent === false && (
              <div id="opening-times-section" className="flex flex-col w-full mb-5 z-10">
                <div className="text-md font-semibold focus:outline-0 w-full mb-6">
                  Opening Times
                </div>
                <OpeningClosingTimePicker
                  initialOpeningTime={spaceData.openingHour}
                  initialClosingTime={spaceData.closingHour}
                  labelFirstTimePicker="Choose Opening Time:"
                  labelSecondTimePicker="Choose Closing Time:"
                  minTime={""}
                  maxTime={""}
                  availability={spaceData.VenueAvailability}
                  onDaySelectionChange={handleDaySelectionChange}
                  onOpeningTimeChange={(day, time) => handleOpeningTimeChange(day, convertDateToTimeString(time))}
                  onClosingTimeChange={(day, time) => handleClosingTimeChange(day, convertDateToTimeString(time))}
                />
              </div>

            )}

            <div className="mt-4">
              <label htmlFor="minBookingDays" className="block text-md font-semibold mb-2">Minimum Booking Days in Advance:</label>
              <input
                type="number"
                id="minBookingDays"
                className="form-input mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="Enter number of days"
                value={minBookingDays}
                onChange={e => updateDaysInAdvance(e.target.value)}
                min="0"
              />
            </div>

            {hasParent === false && (
              <div
                id="contact-section"
                className="flex flex-col w-full mt-4 space-y-2"
              >
                <div className="text-md font-semibold focus:outline-0 w-full mb-2">
                  Contact
                </div>

                <EditableTextField
                  label="Space Website"
                  currentSelectedEditableField={currentSelectedEditableField}
                  initialValue={spaceData.website === "" ? "www.worxinn.com" : spaceData.website}
                  onSave={(value) => debouncedHandleFieldSave("website", value)}
                  tailwindTextStyle="text-md"
                  onSelect={(currentSelectedLabel) => {
                    handleEditableTestSelection(currentSelectedLabel);
                  }}
                  onDeselect={() => {
                    handleEditableTestSelection("");
                  }}
                  isEditable={isEditable}
                />

                <EditableTextField
                  label="Space Phone"
                  currentSelectedEditableField={currentSelectedEditableField}
                  initialValue={spaceData.phone === "" ? "0812345678" : spaceData.phone}
                  onSave={(value) => debouncedHandleFieldSave("phone", value)}
                  tailwindTextStyle="text-md"
                  onSelect={(currentSelectedLabel) => {
                    handleEditableTestSelection(currentSelectedLabel);
                  }}
                  onDeselect={() => {
                    handleEditableTestSelection("");
                  }}
                  isPhoneNumber={true}
                  isEditable={isEditable}
                />

                <EditableTextField
                  label="Space Email"
                  currentSelectedEditableField={currentSelectedEditableField}
                  initialValue={spaceData.email}
                  onSave={(value) => debouncedHandleFieldSave("email", value)}
                  tailwindTextStyle="text-md"
                  onSelect={(currentSelectedLabel) => {
                    handleEditableTestSelection(currentSelectedLabel);
                  }}
                  onDeselect={() => {
                    handleEditableTestSelection("");
                  }}
                  isEditable={isEditable}
                />
              </div>
            )}
          </div>
        </div>
        <div className="w-full h-[20vh] bg-orange-600 flex flex-col items-center object-cover mt-[120px]">
          <div className=" h-full w-[70vw] max-w-[1280px] flex flex-col items-center justify-center"></div>
        </div>
      </div>
    </AnimatePresence>
  );
}
