import { createContext, useState, useEffect } from 'react';

export const UserContext = createContext({
    userId: 0,
    token: {},
    getToken: () => { },
    compareTokens: () => { },
    setTokenSessionStorage: () => { },
    correctToken: false,
    userName: 'test name',
    //setUserId: () => {}
});

export function UserProvider(props) {
    const [userId, setUserId] = useState(0);
    const [token, setToken] = useState({});
    const [correctToken, setCorrectToken] = useState(false)
    const [userName, setuserName] = useState('Test')


    const getToken = () => {
        const token = sessionStorage.getItem('react-token');
        try {
            if (token) {
                setToken(token);
                setCorrectToken(true)
                return true;
            } else {
                setCorrectToken(false)
            }
        } catch (error) {
            setCorrectToken(false)
        }
        return false;
    }

    const compareTokens = () => {

        try {
            const tokenResult = sessionStorage.getItem('react-token');

            if (token.accessToken === '' || tokenResult.accessToken === undefined || tokenResult.accessToken === '') {
                setCorrectToken(false)
                return false
            }

            if (tokenResult.accessToken === token.accessToken) {

                setCorrectToken(true)
                return true
            } else {

                setCorrectToken(false)
                getToken()
                return false
            }
        } catch (e) {

            setCorrectToken(false)
            return false
        }
    }

    function setTokenSessionStorage(token) {
        if (token) {
            setToken(token)
            const srtToken = token
            sessionStorage.setItem('react-token', srtToken)
            setCorrectToken(true)
        } else {
            console.error('No acces token provided, this token will fail')
            setCorrectToken(false)
        }
    }

    function saveUserId(userId2) {
        console.log('SaveuserID', userId2)
        setUserId(userId2)
        console.log(userId)
    }

    useEffect(() => {
        //compareTokens();
        getToken();
    }, []);


    const context = {
        userId,
        token,
        getToken,
        compareTokens,
        correctToken,
        setTokenSessionStorage,
        userName,
        setUserId,
        saveUserId
    };

    return (
        <UserContext.Provider value={context}>
            {props.children}
        </UserContext.Provider>
    );
}