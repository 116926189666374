import {atom } from 'recoil';
import { WorxDetail } from '../Models/worxDetails';

export const worxListState = atom<WorxDetail[]>({
  key: "worxListState",
  default: [],
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((newWorxList) => {
        localStorage.setItem("worxListState", JSON.stringify(newWorxList));
      });
    },
  ],
});
