import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import WorxAPIService from '../Api/worxAPIService.ts';
import styles from './worxIframe.module.css';
import Header from './components/header';
import HoverEffectDiv from '../effects/HoverEffectDiv.tsx';
import Star from '../assets/icons/star.svg';
import ButtonEffectHover from '../effects/HoverEffectButton';
import { FiUser, FiCalendar } from 'react-icons/fi';

const WorxIframe = () => {
  const { id } = useParams();
  const [hotelDetails, setHotelDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showMeetingRooms, setShowMeetingRooms] = useState(true);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
  const [numPersons, setNumPersons] = useState(2);
  const navigate = useNavigate();

  const goToWorxDetail = (worxId) => {
    navigate(`/space/detail?spaceId=${worxId}`, { state: { search: { day: selectedDate, numPeople: numPersons } } });
  };

  const incrementPersons = () => {
    setNumPersons(numPersons + 1);
  };

  const decrementPersons = () => {
    if (numPersons > 1) {
      setNumPersons(numPersons - 1);
    }
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  useEffect(() => {
    const fetchHotelDetails = async () => {
      try {
        const details = await new WorxAPIService().getWorxBySpaceId(parseInt(id));

        console.log(details.body)
        setHotelDetails(details.body);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchHotelDetails();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!hotelDetails) {
    return <div>No details available</div>;
  }

  const meetingRooms = hotelDetails.worxes.filter(worx => worx.worx_type === 1);
  const workSpaces = hotelDetails.worxes.filter(worx => worx.worx_type === 2);

  return (
    <div className={styles.bgGray100}>
      <Header>
        <HoverEffectDiv
          text={hotelDetails.hotel_name}
          onClick={() => {}}
          isSelected={true}
        />
      </Header>
      <div className={styles.container}>
        <div className={styles.worxContainer}>
          <div className={styles.contentWrapper}>
            <img src={hotelDetails.hotel_main_photo} alt={hotelDetails.hotel_name} className={styles.hotelImage} />
            <h1 className={styles.hotelTitle}>{hotelDetails.hotel_name}</h1>
            <p className={styles.hotelAddress}>{hotelDetails.hotel_address}</p>
            <p className={styles.hotelCity}>{hotelDetails.hotel_city}, {hotelDetails.hotel_county}, {hotelDetails.hotel_eircode}</p>
            <p className={styles.hotelPhone}>Phone: {hotelDetails.hotel_phone}</p>
            <p className={styles.hotelEmail}>Email: {hotelDetails.hotel_email}</p>

            <div className={styles.toggleContainer}>
              <HoverEffectDiv
                text="Meeting Rooms"
                onClick={() => setShowMeetingRooms(true)}
                isSelected={showMeetingRooms}
              />
              <HoverEffectDiv
                text="Worx Spaces"
                onClick={() => setShowMeetingRooms(false)}
                isSelected={!showMeetingRooms}
              />
            </div>

            <div className={`${styles.inputContainer} ${!showMeetingRooms ? styles.wide : ''}`}>
              <div className={`${styles.datePickerContainer} ${!showMeetingRooms ? styles.wide : ''}`}>
                <input
                  className={`${styles.inputField} ${styles.datePicker} ${!showMeetingRooms ? styles.wide : ''}`}
                  type="date"
                  placeholder="Date"
                  value={selectedDate}
                  onChange={handleDateChange}
                  min={new Date().toISOString().split('T')[0]}
                />
              </div>
              {showMeetingRooms && (
                <div className={styles.personsContainer}>
                  <div className={styles.personsSelector}>
                    <button onClick={decrementPersons} className={styles.decrementButton}>-</button>
                    <span className='flex items-center mx-2'> <FiUser className="mr-2" />{numPersons}</span>
                    <button onClick={incrementPersons} className={styles.incrementButton}>+</button>
                  </div>
                </div>
              )}
            </div>

            {showMeetingRooms ? (
              <>
                <h2 className={styles.spacesTitle}>Meeting Rooms:</h2>
                <div className={styles.spacesList}>
                  {meetingRooms.length > 0 ? (
                    meetingRooms.map(worx => (
                      <div key={worx.worx_id} className={styles.spaceItem} onClick={() => goToWorxDetail(worx.worx_id)}>
                        <div
                          className={styles.spaceImage}
                          style={{ backgroundImage: `url(${worx.worx_photo})` }}
                        ></div>
                        <h3 className={styles.spaceTitle}>{worx.worxspace_name}</h3>
                        <div className={styles.infoGrid}>
                          <div className={styles.flexRow}>
                            <div className={styles.flexP}>
                              <p>Capacity:</p>
                              <p>{worx.worxspace_capacity}</p>
                            </div>
                            <div className={styles.flexP}>
                              <img src={Star} alt="star" className="w-4 h-4" />
                              <p>{worx.worxspace_rating} ({worx.worxspace_total_reviews})</p>
                            </div>
                          </div>
                          <div className={styles.flexColumn}>
                            <ButtonEffectHover 
                              text={`Book for €${worx.lowest_price}`}
                              onClick={() => goToWorxDetail(worx.worx_id)}
                              isSelected={false}
                            />
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No meeting rooms available</p>
                  )}
                </div>
              </>
            ) : (
              <>
                <h2 className={styles.spacesTitle}>Worx Spaces:</h2>
                <div className={styles.spacesList}>
                  {workSpaces.length > 0 ? (
                    workSpaces.map(worx => (
                      <div key={worx.worx_id} className={styles.spaceItem} onClick={() => goToWorxDetail(worx.worx_id)}>
                        <div
                          className={styles.spaceImage}
                          style={{ backgroundImage: `url(${worx.worx_photo})` }}
                        ></div>
                        <h3 className={styles.spaceTitle}>{worx.worxspace_name}</h3>
                        <div className={styles.infoGrid}>
                          <div className={styles.flexRow}>
                            <div className={styles.flexP}>
                              <p>Capacity:</p>
                              <p>{worx.worxspace_capacity}</p>
                            </div>
                            <div className={styles.flexP}>
                              <img src={Star} alt="star" className="w-4 h-4" />
                              <p>{worx.worxspace_rating} ({worx.worxspace_total_reviews})</p>
                            </div>
                          </div>
                          <div className={styles.flexColumn}>
                            <ButtonEffectHover 
                              text={`Book for €${worx.lowest_price}`}
                              onClick={() => goToWorxDetail(worx.worx_id)}
                              isSelected={false}
                            />
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No worx spaces available</p>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorxIframe;