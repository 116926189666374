import { initializeApp, FirebaseApp } from "firebase/app";
import {
  getAuth,
  Auth,
  createUserWithEmailAndPassword,
  UserCredential,
  signInWithEmailAndPassword,
  deleteUser,
  sendPasswordResetEmail,
} from "firebase/auth";
import {
  getFirestore,
  doc,
  query,
  where,
  getDoc,
  setDoc,
  getDocs,
  deleteDoc,
  Firestore,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import User from "../Models/adminUser";
import UserRoles from "../Enums/userRoles";
//collection
import {
  collection,
  addDoc,
  enableIndexedDbPersistence,
} from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";

export interface AuthResponse {
  success: boolean;
  user?: User;
  error?: string;
}

const firebaseConfig = {
  apiKey: "AIzaSyCNilZfl5ySD5GmeE2PZn9eZNg5BsaRqkg",
  authDomain: "tonal-nucleus-393911.firebaseapp.com",
  projectId: "tonal-nucleus-393911",
  storageBucket: "tonal-nucleus-393911.appspot.com",
  messagingSenderId: "218292986337",
  appId: "1:218292986337:web:092d5daabd8a834f8c32a0",
  localCache: "IndexedDb",
};

const app: FirebaseApp = initializeApp(firebaseConfig);
const auth: Auth = getAuth();
const db: Firestore = getFirestore();
const storage = getStorage();

const createUserObject = (
  id: string,
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  spaces?: any
): User => {
  return {
    id,
    firstName,
    lastName,
    email,
    role: UserRoles.USER,
    spaces: spaces || {},
  };
};

const signUp = async (
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  spaces?: any
): Promise<AuthResponse> => {
  if (email === "" || password === "" || firstName === "" || lastName === "") {
    return { success: false, error: "Please fill out all fields" };
  }

  let userCredential: UserCredential | undefined;

  try {
    userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
  } catch (error: any) {
    if (!error.message.includes("auth/email-already-in-use")) {
      return { success: false, error: error.message };
    } else {
      return { success: false, error: 'email-already-in-use' };
    }
  }

  if (!userCredential) return { success: false, error: "User not created" };

  const user = createUserObject(
    userCredential.user.uid,
    email,
    password,
    firstName,
    lastName,
    spaces
  );

  await writeUser(user);

  return { success: true, user: user };
};

const writeUser = async (user: User): Promise<void> => {
  try {
    const refDoc = doc(db, "users", user.id);
    let resp = await setDoc(refDoc, user);
  } catch (error: any) {
    throw new Error(`Error writing user: ${error}`);
  }
};

const deleteUserById = async (id: string): Promise<void> => {
  try {
    await deleteUser(auth.currentUser!);
    await deleteDoc(doc(db, "users", id));
  } catch (error: any) {
    console.error("Error deleting user:", error);
  }
};

const createJoinCode = async (): Promise<string> => {
  const joinCode = uuidv4();
  const docRef = doc(db, "invites", joinCode);
  await setDoc(docRef, {});
  return joinCode;
};

const login = async (
  email: string,
  password: string
): Promise<AuthResponse> => {
  try {
    const userCredential: UserCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = await fetchUser(userCredential.user.uid);
    if (user) {
      return { success: true, user };
    } else {
      return { success: false, error: "User data not found" };
    }
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

const fetchUser = async (id: string): Promise<User | null> => {
  try {
    const userDoc = await getDoc(doc(db, "users", id));
    if (userDoc.exists()) {
      return userDoc.data() as User;
    } else {
      return null;
    }
  } catch (error: any) {
    console.error("Error fetching user:", error);
    return null;
  }
};

const logOut = async (): Promise<void> => {
  try {
    await auth.signOut();
  } catch (error: any) {
    console.error("Error logging out:", error);
  }
};

const isLoggedIn = (): boolean => {
  return auth.currentUser !== null;
};
const storeImage = async (
  image: File,
  id: string,
  type: string = "space"
): Promise<string> => {
  // Create a reference to the specific folder and image
  const imageRef = ref(storage, `images/${id}/${image.name}`);

  // Check if the file already exists
  try {
    // Try to get the download URL, if it exists, return the existing URL
    const existingUrl = await getDownloadURL(imageRef);
    return existingUrl;
  } catch (error) {
    // If the file does not exist, upload it
    await uploadBytes(imageRef, image);
    const downloadUrl = await getDownloadURL(imageRef);
    return downloadUrl;
  }
};

const checkInviteKey = async (inviteKey: string): Promise<boolean> => {
  const inviteDoc = await getDoc(doc(db, "invites", inviteKey));
  if (inviteDoc.exists()) {
    //one time use key!
    deleteInviteKey(inviteKey);
    return true;
  } else {
    return false;
  }
};

const deleteInviteKey = async (inviteKey: string): Promise<void> => {
  await deleteDoc(doc(db, "invites", inviteKey));
};

const createSignUpToken = async (email: string): Promise<string> => {
  const inviteKey = Math.random().toString(36).substring(7);
  await addDoc(collection(db, "invites"), {
    email,
    inviteKey,
  });
  return inviteKey;
};

const storeImageMapping = (
  id: string,
  url: string,
  type: string
): Promise<string> => {
  const docRef = doc(db, "images", id);

  // Retrieve the current document
  return getDoc(docRef)
    .then((docSnapshot) => {
      let data = docSnapshot.exists() ? docSnapshot.data() : {};

      // Check if the 'entries' array exists, if not create it
      if (!data.entries) {
        data.entries = [];
      }

      // Append new object to the 'entries' array
      data.entries.push({ url, type });

      // Save the updated document
      return setDoc(docRef, data).then(() => {
        // Return the id after updating the document
        return id;
      });
    })
    .catch((error) => {
      // Handle any errors that occur during the process
      console.error("Error appending to document:", error);
      throw error;
    });
};

const fetchImages = async (id: string, type: string): Promise<string[]> => {
  const images: string[] = [];
  const docRef = doc(db, "images", id);
  const docSnapshot = await getDoc(docRef);

  if (docSnapshot.exists()) {
    const data = docSnapshot.data();

    // Check if 'entries' exists and is an array
    if (data.entries && Array.isArray(data.entries)) {
      data.entries.forEach((entry) => {
        // Check if the entry type matches the requested type
        if (entry.type === type) {
          images.push(entry.url);
        }
      });
    }
  }

  return images;
};

const addSpaceToUser = async (userId: string, spaceId: string) => {
  const user = await fetchUser(userId);

  if (user && user.spaces && user.spaces[spaceId]) {
    return;
  }

  if (!user) {
    return;
  }
  const docRef = doc(db, "users", userId);
  const docSnapshot = await getDoc(docRef);
  if (docSnapshot.exists()) {
    const data = docSnapshot.data();
    if (data.spaces) {
      data.spaces[spaceId] = user?.role;
    } else {
      data.spaces = { [spaceId]: user?.role };
    }
    await setDoc(docRef, data);
  }

  return user;
};

const uploadVenueImageAndGetURL = async (imageFile, venueId) => {
  const imageRef = ref(storage, `images/venue/${venueId}/${imageFile.name}`);

  // Check if the file already exists
  try {
    // Try to get the download URL, if it exists, return the existing URL
    const existingUrl = await getDownloadURL(imageRef);
    return existingUrl;
  } catch (error) {
    // If the file does not exist, upload it
    await uploadBytes(imageRef, imageFile);
    const downloadUrl = await getDownloadURL(imageRef);
    return downloadUrl;
  }
};

const uploadWorxImageAndGetURL = async (imageFile, worxId) => {
  const imageRef = ref(storage, `images/space/${worxId}/${imageFile.name}`);

  // Check if the file already exists
  try {
    // Try to get the download URL, if it exists, return the existing URL
    const existingUrl = await getDownloadURL(imageRef);
    return existingUrl;
  } catch (error) {
    // If the file does not exist, upload it
    await uploadBytes(imageRef, imageFile);
    const downloadUrl = await getDownloadURL(imageRef);
    return downloadUrl;
  };
}

const uploadUserImageAndGetURL = async (imageFile, userUUID) => {
  const imageRef = ref(storage, `images/user/${userUUID}/${imageFile.name}`);

  // Check if the file already exists
  try {
    // Try to get the download URL, if it exists, return the existing URL
    const existingUrl = await getDownloadURL(imageRef);
    return existingUrl;
  } catch (error) {
    // If the file does not exist, upload it
    await uploadBytes(imageRef, imageFile);
    const downloadUrl = await getDownloadURL(imageRef);
    return downloadUrl;
  };
}

const sendPasswordResetEmailFirebase = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email);
    return true
  } catch (error) {
   return error
  }
};

export {
  signUp,
  login,
  fetchUser,
  writeUser,
  logOut,
  isLoggedIn,
  storeImage,
  checkInviteKey,
  deleteUserById,
  createJoinCode,
  createSignUpToken,
  storeImageMapping,
  fetchImages,
  addSpaceToUser,
  uploadVenueImageAndGetURL,
  uploadWorxImageAndGetURL,
  uploadUserImageAndGetURL,
  sendPasswordResetEmailFirebase
};
