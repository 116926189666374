import React, { useState, useEffect } from 'react';

function TimePriceTable({ availability, initialData = [], setRows }) {
    const [maxHours, setMaxHours] = useState(0);
    const [minHours, setMinHours] = useState(1); // Added state to handle minimum hours
    const [rows, setInternalRows] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        calculateMaxHours();
    }, [availability]);

    useEffect(() => {
        if (!isNaN(minHours) && minHours >= 1 && minHours <= 24) {
            generateRows(maxHours);
        }
    }, [minHours, maxHours]);

    // useEffect to initialize rows based on initialData
    useEffect(() => {
        if (initialData.length) {
            const formattedData = initialData.map(({ hour, price }) => ({
                id: hour, // Using hour as a temporary ID; consider using unique IDs in a real app
                hour: hour,
                price: price
            }));
            setInternalRows(formattedData);
            setRows(formattedData); // Assuming setRows is a prop to sync state with the parent
        }
    }, [initialData]);

    const calculateMaxHours = () => {
        let maxDuration = 0;
        let tempError = '';
        Object.values(availability).forEach(day => {
            if (day.isSelected) {
                if (!day.open || !day.close) {
                    tempError = 'Error: Selected day(s) missing open or close time.';
                } else {
                    const [openH, openM] = day.open.split(':').map(Number);
                    const [closeH, closeM] = day.close.split(':').map(Number);
                    const duration = (closeH - openH) + (closeM - openM) / 60;
                    if (duration > maxDuration) {
                        maxDuration = duration;
                    }
                }
            }
        });
        if (tempError) {
            setError(tempError);
        } else {
            setError('');
            setMaxHours(Math.floor(maxDuration));
            generateRows(Math.floor(maxDuration));
        }
    };

    const generateRows = (duration) => {
        const newRows = Array.from({ length: duration }, (_, i) => ({
            id: i,
            hour: i + 1,
            price: ''
        })).filter(row => row.hour >= minHours);
        setInternalRows(newRows);
        setRows(newRows);
    };

    const handlePriceChange = (index, value) => {
        const updatedRows = rows.map((row, i) => {
            if (i === index) return { ...row, price: value };
            return row;
        });
        setInternalRows(updatedRows);
        setRows(updatedRows);
    };

    const handleHourChange = (index, value) => {
        const updatedRows = rows.map((row, i) => {
            if (i === index) return { ...row, hour: value };
            return row;
        });
        setInternalRows(updatedRows);
        setRows(updatedRows);
    };

    const handleMinHoursChange = (e) => {
        const input = parseInt(e.target.value, 10);
        setMinHours(input);
        if (!isNaN(input) && input >= 1 && input <= 24) {
            setError('');
            generateRows(maxHours);
        } else {
            setError('Minimum hours must be between 1 and 24.');
        }
    };

    const deleteRow = (index) => {
        setRows(rows.filter((_, i) => i !== index));
    };

    const getPriceInputClassName = (price) => {
        
        if (!price || isNaN(parseFloat(price)) || parseFloat(price) < 0) {
            return "w-full p-1 rounded border-red-500"; // Red border for invalid input
        }
        return "w-full p-1 rounded border"; // Normal border for valid input
    };

    const addCustomRow = () => {
        setRows(rows.concat({
            id: rows.length ? rows[rows.length - 1].id + 1 : 0,
            hour: '',
            price: ''
        }));
    };

    return (
        <div className="p-4">
            <div className="flex justify-between mb-4 space-x-2">
                <input
                    type="number"
                    value={minHours}
                    onChange={handleMinHoursChange}
                    className="p-1 border rounded"
                    placeholder="Min Hours"
                />
                <button onClick={calculateMaxHours} className="px-4 py-2 bg-blue-500 text-white rounded-md text-sm">Recalculate Hours</button>
            </div>
            <div>
                <button onClick={() => addCustomRow()} className="px-4 py-2 bg-green-500 text-white rounded-md text-sm">Add Custom Row</button>
            </div>
            {error && <p className="text-red-500 text-sm mb-2">{error}</p>}
            {!error && rows.length > 0 && (
                <table className="w-full text-sm">
                    <thead>
                        <tr className="bg-gray-200">
                            <th className="p-2">Hour</th>
                            <th className="p-2">Price €</th>
                            <th className="p-2"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((row, index) => (
                            <tr key={row.id}>
                                <td className="p-1">
                                    <input
                                        type="number"
                                        value={row.hour}
                                        onChange={(e) => handleHourChange(index, parseInt(e.target.value, 10))}
                                        className="w-full p-1 border rounded"
                                        placeholder="Hour"
                                    />
                                </td>
                                <td className="p-1">
                                    <input
                                        type="number"
                                        value={row.price}
                                        onChange={(e) => handlePriceChange(index, e.target.value)}
                                        className= {getPriceInputClassName(row.price)}
                                        placeholder="Price"
                                    />
                                </td>
                                <td className="p-1">
                                    <button
                                        onClick={() => deleteRow(index)}
                                        className="px-2 py-1 bg-red-500 text-white rounded"
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
}

export default TimePriceTable;
