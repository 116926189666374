import React, { useState, useEffect } from "react";
import SpaceManagementPage from "../../Invites/spaceContentManagement";
import { useAsync } from "../../Hooks/useAsync";
import { userState } from "../../State/userAtom";
import { useRecoilValue } from "recoil";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useSearchParams, useNavigate } from "react-router-dom";
import { SpaceDetail } from "../../Models/spaceDetails.ts";
import { SpaceAPIService } from "../../Api/spaceAPIService.ts";
import WorxManagementPage from "../../Invites/worxContentManagement.tsx";
import { WorxDetail } from "../../Models/worxDetails.ts";
import { spaceListState } from "../../State/spaceAtom.ts";
import { worxListState } from "../../State/worxAtom.ts";
import { useRecoilState } from "recoil";
import WorxAPIService from "../../Api/worxAPIService.ts";
import MeetingRoomsContentManager from "../../Invites/meetingRoomsContentManagement.tsx";
import { AmenitieAPIService } from "../../Api/amenityAPIService.ts";

const SpaceHome = () => {
  const currentUser = useRecoilValue(userState);

  const isEmpty = (obj: any) => {
    return Object.keys(obj).length === 0;
  };

  const userIntials =
    currentUser && !isEmpty(currentUser)
      ? `${currentUser.firebaseUser.firstName[0]}${currentUser.firebaseUser.lastName[0]}`
      : "?";
  const spaceAPIService = new SpaceAPIService();
  const worxAPIService = new WorxAPIService();
  const [searchParams] = useSearchParams();
  const hotelIdParam = searchParams.get("id");
  const isWorx = searchParams.get("isworx") === "1";
  const isMeetingRoom = searchParams.get("ismeet") === "1";
  const parentSpaceId = searchParams.get("pid");
  const navigate = useNavigate();
  const [spaceListCache, setSpaceListCache] = useRecoilState(spaceListState);
  const [worxListCache, setWorxListCache] = useRecoilState(worxListState);

  const [spaceId, setSpaceId] = useState(
    isWorx || isMeetingRoom ? parentSpaceId : hotelIdParam || ""
  );

  const [space, setSpace] = useState<SpaceDetail | null>(null);
  const [parents, setParents] = useState<SpaceDetail[] | null>(null);

  const [availableAmenities, setAvailableAmenities] = useState({})

  const amenitieAPIService = new AmenitieAPIService();

  useEffect(() => {
    if (!spaceId) return;

    const spaceInList = [...spaceListCache].find(
      (space: SpaceDetail) => space.id === parseInt(spaceId!)
    );
    console.log(spaceListCache)
    console.log(spaceInList)
    if (spaceInList) {
      console.log(spaceInList)
      setSpace(spaceInList);
    }

    const fetchAvailableAmenities = async () => {
      const response = await amenitieAPIService.getAllAmenities();
      console.log(response)
      if (response.status == 200) {
        setAvailableAmenities(response.body)
      }
    }

    fetchAvailableAmenities();
  }, []);

  const { data, isLoading, error } = useAsync(async () => {
    if (!spaceId) return null;

    const spaceIdNum = parseInt(spaceId);
    const space_ = await spaceAPIService.getSpace(spaceIdNum);

    if (!space_.body.id) {
      return null;
    }

    let newSpaceList = spaceListCache.filter(
      (space: SpaceDetail) => space.id !== space_.body.id
    );

    newSpaceList.push(space_.body);

    setSpaceListCache(newSpaceList);

    return space_;
  }, [spaceId]);

  const { data: worxes } = useAsync(async () => {
    console.log("Entro get worx data")
    console.log(spaceId)
    if (!spaceId) return null;

    const worxIds = space?.Worxes.map((worx) => worx.id) || [];

    console.log(worxIds)
    const resp = await Promise.all(
      worxIds.map(async (worxId) => {
        const worx = await worxAPIService.getWorx(worxId);

        let newWorxList = worxListCache.filter(
          (cachedWorx: WorxDetail) => cachedWorx.id !== worx.body.id
        );

        newWorxList.push(worx.body);

        setWorxListCache(newWorxList);

        return worx.body;
      })
    );

    return resp;
  }, [data]);

  useEffect(() => {
    if (!data) return;
    setSpace(data.body);
  }, [data]);

  useEffect(() => {
    if (!spaceId) return;

    //console.log(!currentUser || (!currentUser.bbdUser.user.Space[spaceId] && (!isWorx || !isMeetingRoom)))
    //console.log(!currentUser)
    //console.log((!currentUser.bbdUser.user.Space[spaceId] && (!isWorx || !isMeetingRoom)))
    //console.log((!isWorx || !isMeetingRoom))

  }, [currentUser, navigate, spaceId]);

  if (!hotelIdParam) return <div>Invalid URL</div>;

  console.log(isLoading)
  console.log(space)

  console.log("Space Home")
  console.log(isWorx)
  console.log(isMeetingRoom)
  console.log(isLoading || !space)

  if (isLoading || !space)
    return (
      <div className="w-screen h-screen flex items-center justify-center text-2xl">
        <AiOutlineLoading3Quarters className="animate-spin" />
      </div>
    );

  if (error) return <div>Error</div>;

  if (!currentUser) {
    navigate("/registration?new=0");
    return null;
  }

  const parentIds = parents?.map((parent) => parent.id as number) || [];

  const getWorxFromId = (worxId: string) => {

    console.log("Entro getWorxFromId")
    const idAsNum = parseInt(worxId);
    let worx: WorxDetail = [...worxListCache].find(
      (worx) => worx.id === idAsNum
    )!;

    console.log(worx)
    console.log(space)
    if (!worx && space) {
      worx = space.Worxes.find((worx) => worx.id === idAsNum);
    }

    if (!worx) {
      navigate("/spaces");
      return null;
    }

    if (worxes && worxes.length > 0) {
      return worxes.find((worx: SpaceDetail) => worx.id === idAsNum)!;
    }

    return worx;
  };




  return (isWorx || isMeetingRoom) && space ? (
    isWorx ? (
      <WorxManagementPage
        parentSpace={space}
        userIntials={userIntials}
        worx={getWorxFromId(hotelIdParam)}
        availableAmenities={availableAmenities}
      />
    ) : (
      <MeetingRoomsContentManager
        parentSpace={space}
        userIntials={userIntials}
        worx={getWorxFromId(hotelIdParam)}
        availableAmenities={availableAmenities}
      />
    )

  ) : (
    <SpaceManagementPage
      space={space}
      userIntials={userIntials}
      parentIds={parentIds}
    />
  );
};

export default SpaceHome;
