import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiMinus, FiPlus, FiUser, FiSettings } from "react-icons/fi";
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings.svg';
import { SpaceAPIService } from '../../Api/spaceAPIService';
import WorxAPIService from '../../Api/worxAPIService.ts';
import PlaceSuggestionsInput from '../components/placesAutocomplete/PlacesSuggestionInput';
import HoverEffectButton from '../../effects/HoverRandomLetters';
import HoverEffectDiv from '../../effects/HoverEffectDiv';
import styles from './home.module.css';
import { Geolocation } from '@capacitor/geolocation';
import { Capacitor } from '@capacitor/core';
import { userState } from '../../State/userAtom';
import { useRecoilState } from 'recoil';
import { UserAPIService } from '../../Api/userAPIService.ts';
import { ReactComponent as Clear } from '../../assets/icons/clear.svg';
import { ReactComponent as Save } from '../../assets/icons/save.svg';
import { Navigator } from 'react-router-dom';
import star from '../../assets/icons/star.svg';
import Header from '../components/header.tsx';
import { ReactComponent as SavedBookingsIconSelected } from '../../assets/icons/savedBlue.svg';

const getFormattedToday = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

const HomePage: React.FC = () => {
    const navigate = useNavigate();
    const [mode, setMode] = useState<'Meet' | 'Space'>('Space');
    const [city, setCity] = useState('');
    const [date, setDate] = useState(getFormattedToday());
    const [numPersons, setNumPersons] = useState(1);
    const [cityValid, setCityValid] = useState(true);
    const [dateValid, setDateValid] = useState(true);
    const [numPersonsValid, setNumPersonsValid] = useState(true);
    const spaceAPIService = new SpaceAPIService();
    const worxApiService = new WorxAPIService();
    const [worxes, setWorxes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [latLng, setLatLng] = useState({ lat: 0.0, lng: 0.0 });
    const [isHiddenViewVisible, setHiddenViewVisible] = useState(false);
    const [allStartingWorxes, setAllStartingWorxes] = useState({});
    const [user, setUser] = useRecoilState(userState);
    const [savedWorxes, setSavedWorxes] = useState([]);
    const userAPIService = new UserAPIService();


    const fetchWorxes = async (latitude, longitude) => {
        try {
            const worxTypeId = mode === 'Meet' ? 1 : 2;
            const response = await worxApiService.getWorxByDistance(latitude, longitude, 99999);
            if (response.status !== 200) {
                throw new Error('Failed to fetch Worxes');
            }
            const filteredWorxes = response.body.filter(worx => worx.name && worx.name.trim() !== '');

            setAllStartingWorxes(filteredWorxes);
            setWorxes(filterWorxesByType(filteredWorxes, mode));
        } catch (error) {
            console.error('Error fetching Worxes:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const filterWorxesByType = (worxes, modeType) => {
        const worxTypeId = modeType === 'Meet' ? 1 : 2;
        return worxes.filter(worx => worx.WorxTypeId === worxTypeId);
    };

    const getCurrentLocation = async () => {
        if (Capacitor.isNativePlatform()) {
            try {
                const coordinates = await Geolocation.getCurrentPosition();
                const { latitude, longitude } = coordinates.coords;
                setLatLng({ lat: latitude, lng: longitude });
                fetchWorxes(latitude, longitude);
            } catch (error) {
                console.error('Error getting geolocation:', error);
                setLatLng({ lat: 51.89780653738887, lng: -8.475723543047707 });
                fetchWorxes(51.89780653738887, -8.475723543047707);
            }
        } else {
            navigator.geolocation.getCurrentPosition(position => {
                const { latitude, longitude } = position.coords;
                setLatLng({ lat: latitude, lng: longitude });
                fetchWorxes(latitude, longitude);
            }, error => {
                console.error('Error getting geolocation:', error);
                setLatLng({ lat: 51.89780653738887, lng: -8.475723543047707 });
                fetchWorxes(51.89780653738887, -8.475723543047707);
            });
        }
    };

    const fetchSavedWorxes = async () => {
        try {
            const response = await userAPIService.getLikedWorxes(user.bbdUser.user.id);
            if (response.body) {
                console.log(response.body);
                const savedWorxIds = response.body.map(worx => worx.worxId);
                setSavedWorxes(savedWorxIds);
            } else {
                setSavedWorxes([]);
            }
        } catch (error) {
            console.error("Error fetching saved worxes:", error);
            setSavedWorxes([]);
        }
    };

    useEffect(() => {
        setIsLoading(true);
        getCurrentLocation();

        if (user) {
            if (Object.keys(user).length !== 0) {
                fetchSavedWorxes();
            }
        }
    }, []);

    const handleModeChange = (newMode: 'Meet' | 'Space') => {
        setMode(newMode);
        setNumPersons(newMode === 'Meet' ? 2 : 1);
        setWorxes(filterWorxesByType(allStartingWorxes, newMode));
    };

    const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDate(e.target.value);
        setDateValid(!!e.target.value);
    };

    const handleIncrement = () => {
        setNumPersons(prevNum => Math.max(2, Number(prevNum) + 1));
    };

    const handleDecrement = () => {
        setNumPersons(prevNum => Math.max(2, Number(prevNum) - 1));
    };

    const fetchAvailablehWorxes = async (date, worxTypeId, capacityRange, priceRange, lat, lng, distance) => {
        const response = await worxApiService.getAllAvailableWorxes(date, '00:00:00', '24:00:00', worxTypeId, priceRange[0], priceRange[1], capacityRange[0], capacityRange[1], lat, lng, distance);
        return response.body;
    };

    const handleSearchClick = async () => {
        if (latLng.lat === 0.0 && latLng.lng === 0.0 || !date || (mode === 'Meet' && !numPersons)) {
            setCityValid(false);
            setDateValid(!!date);
            setNumPersonsValid(!!numPersons);
            return;
        }

        const capacityRange = [numPersons, Math.ceil(numPersons * 1.2)];

        const availableWorxes = await fetchAvailablehWorxes(date, mode === 'Meet' ? 1 : 2, capacityRange, [1, 999], latLng.lat, latLng.lng, 9999);

        const filteredWorxes = availableWorxes.filter(worx => worx.name && worx.name.trim() !== '');

        setAllStartingWorxes(filteredWorxes);
    };

    const handleSetLatLng = (latLngObj, city) => {
        setLatLng(latLngObj);
        setCity(city);
        setCityValid(true);
    };

    const toggleHiddenView = () => {
        setHiddenViewVisible(!isHiddenViewVisible);
    };

    const handleClearWorx = (id) => {
        setWorxes(prevWorxes => prevWorxes.filter(worx => worx.id !== id));
    };

    const handleSaveWorx = async (id) => {
        try {
            const response = await worxApiService.saveWorx(user.bbdUser.user.id, id);
            if (response.status === 201) {
                setSavedWorxes([...savedWorxes, id]);
            }
        } catch (error) {
            console.error("Error saving worx:", error);
        }
    };

    return (
        <div className={`flex flex-col max-h-max ${styles.bgGray100}`}>
            <Header >
                <HoverEffectDiv
                    text="Work space"
                    onClick={() => handleModeChange('Space')}
                    isSelected={mode === 'Space'}
                />
                <HoverEffectDiv
                    text="Meeting room"
                    onClick={() => handleModeChange('Meet')}
                    isSelected={mode === 'Meet'}
                />
            </Header>
            <div className={`flex flex-col flex-grow ${styles.border}`}>
                <div className="mt-4 px-4">
                    <div className="flex justify-center mb-4">
                        <div className="w-4/5">
                            <PlaceSuggestionsInput setLatLng={handleSetLatLng} />
                        </div>
                    </div>
                    {!cityValid && <div className="text-red-500 text-xs">Please enter a city</div>}
                    <div className={`flex ${mode === 'Meet' ? '' : ''}`}>
                        <div className="flex flex-1 justify-center">
                            <div className='w-4/5'>
                                <input
                                    className={`${styles.inputField} ${!dateValid ? 'border-red-500' : 'border-gray-300'}`}
                                    type="date"
                                    placeholder="Date"
                                    value={date}
                                    onChange={handleDateChange}
                                    min={new Date().toISOString().split('T')[0]}
                                />
                                {!dateValid && <div className="text-red-500 text-xs">Please select a date</div>}
                            </div>
                        </div>
                        {mode === 'Meet' && (
                            <div className="flex flex-1 flex-col items-center justify-center">
                                <div className={`flex items-center input-field ${!numPersonsValid ? 'border-red-500' : 'border-none'} justify-center bg-none bg-transparent`}>
                                    <button
                                        type="button"
                                        onClick={handleDecrement}
                                        className="text-black focus:outline-none"
                                    >
                                        <FiMinus />
                                    </button>
                                    <span className="flex items-center mx-2">
                                        <FiUser className="mr-2" />
                                        {numPersons}
                                    </span>
                                    <button
                                        type="button"
                                        onClick={handleIncrement}
                                        className="text-black focus:outline-none"
                                    >
                                        <FiPlus />
                                    </button>
                                </div>
                                {!numPersonsValid && <div className="text-red-500 text-xs">Please enter the number of people</div>}
                            </div>
                        )}
                    </div>
                    <HoverEffectButton text="Search" onClick={handleSearchClick} />
                </div>
                <div className={styles.borderDiv}></div>
                <div className={styles.gridMapAndWorxes}>
                    <div className="w-full overflow-y-auto">
                        <WorxList worxes={worxes} onClearWorx={handleClearWorx} onSaveWorx={handleSaveWorx} savedWorxes={savedWorxes} search={{ day: date, numPeople: numPersons }} />
                    </div>
                    <div className={`w-full ${styles.stickyMap}`}>
                        {isLoading ? (
                            <div className="flex justify-center items-center mt-4">
                                <div className={`animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 ${styles.borderOrange500}`}></div>
                            </div>
                        ) : (
                            <div className={`mx-3 ${styles.stickyMap}`}>
                                <MyGoogleMap center={latLng} zoom={14} worxes={worxes} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomePage;

const WorxList = ({ worxes, onClearWorx, onSaveWorx, savedWorxes, search }) => {
    const navigate = useNavigate();

    const goToWorxDetail = (worxId) => {
        navigate(`/space/detail?spaceId=${worxId}`, { state: { search } });
    }


    return (
        <div>
            {worxes.map(worx => (
                <div key={worx.id} className={styles.gridCard}>
                    <div className={styles.worxInfo}>
                        <div className={styles.titleWorx} onClick={() => goToWorxDetail(worx.id)}>{worx.name}</div>
                        <div className={styles.name}>{worx.Space.name}</div>
                        <p className={styles.address}>{worx.Space.address}</p>
                        <div className={styles.aminities}>{worx.Amenities && worx.Amenities[0] ? `${worx.Amenities[0].name}` : 'Amenetie not available'}</div>
                        <div className={styles.gridPriceAndRating}>
                            <div className={styles.rating}>
                                <img src={star} alt="star" className="w-4 h-4" />
                                {worx.rating}
                            </div>
                            <div className={`${styles.price}`}>
                                {worx.SpacePrices && worx.SpacePrices[0] ? `€${worx.SpacePrices[0].price}` : 'Price not available'}/hour
                            </div>
                        </div>
                    </div>
                    <div className="relative">
                        <div className="absolute left-[16px] top-[16px] w-6 h-6 cursor-pointer" onClick={() => onClearWorx(worx.id)}>
                            <div className="w-6 h-6 absolute bg-neutral-800/30 rounded-full"></div>
                            <div className="w-3 h-3 absolute left-[6px] top-[6px]">
                                <Clear />
                            </div>
                        </div>
                        <div className="absolute right-[16px] top-[16px] w-6 h-6 cursor-pointer" onClick={() => onSaveWorx(worx.id)}>
                            {
                                savedWorxes.includes(worx.id) ? <SavedBookingsIconSelected className='' /> : <Save />
                            }
                        </div>
                        <img onClick={() => goToWorxDetail(worx.id)} src={worx.WorxPhotos && worx.WorxPhotos[0] ? worx.WorxPhotos[0].photo_url : 'https://upload.wikimedia.org/wikipedia/commons/d/d1/Image_not_available.png'} alt="" className={styles.imgWorx} />
                    </div>
                </div>
            ))}
        </div>
    );
};

const MyGoogleMap = ({ center, zoom, worxes }) => {
    const mapRef = useRef(null);
    const [apiLoaded, setApiLoaded] = useState(false);
    const [infoWindow, setInfoWindow] = useState(null);
    const [map, setMap] = useState(null);

    useEffect(() => {
        const loadGoogleMapsScript = (callback) => {
            if (window.google) {
                callback();
                return;
            }

            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCNilZfl5ySD5GmeE2PZn9eZNg5BsaRqkg`;
            script.async = true;
            script.defer = true;
            document.head.append(script);

            script.onload = () => callback();
        };

        loadGoogleMapsScript(() => setApiLoaded(true));
    }, []);

    useEffect(() => {
        if (apiLoaded && mapRef.current && !infoWindow) {
            const mapInstance = new window.google.maps.Map(mapRef.current, {
                center,
                zoom,
                mapTypeId: 'roadmap',
                fullscreenControl: false,
                streetViewControl: false
            });
            setMap(mapInstance);
            const infow = new window.google.maps.InfoWindow();
            setInfoWindow(infow);

            worxes.forEach(worx => {
                const { Space } = worx;
                if (Space.lat && Space.lng) {
                    const marker = new window.google.maps.Marker({
                        position: { lat: Space.lat, lng: Space.lng },
                        map: mapInstance,
                        title: Space.name,
                    });

                    marker.addListener('click', () => {
                        const contentString = `
                            <div>
                                <h2>${Space.name}</h2>
                                <img src="${Space.HotelPhotos.length > 0 ? Space.HotelPhotos[0].photo_url : 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fi.pinimg.com%2Foriginals%2Fbe%2F4f%2Faf%2Fbe4fafe9823889e91e459d8495aec69f.jpg&f=1&nofb=1&ipt=2f2b151e5774dd42aa2d41ebc2366a1d1cfd5d37b24fb206d81af43059a0c7d6&ipo=images'}" alt="${Space.name}" style="width:100px; height:60px;"/>
                                <p>Worxes: ${countWorxesWithWorxTypeId(Space, 2)}</p>
                                <p>Meeting Rooms: ${countWorxesWithWorxTypeId(Space, 1)}</p>
                            </div>
                        `;
                        infow.setContent(contentString);
                        infow.open(mapInstance, marker);
                    });
                }
            });
        }
    }, [apiLoaded, center, zoom, worxes, infoWindow]);

    useEffect(() => {
        if (map) {
            map.setCenter(center);
        }
    }, [center, map]);

    return (
        <div ref={mapRef} style={{ height: '600px', width: '100%', borderRadius: '10px' }} />
    );
};
