// FilterPanel.tsx
import React, { useState } from 'react';
import { Range, getTrackBackground } from 'react-range';

interface FilterPanelProps {
    onClose: () => void;
    handleFilterSubmit: () => void;
    setPriceRange: () => void;
    setCapacityRange: () => void;
    setFilterDate: () => void;
    filters: any;
    worxTypeId: number;
    // Define additional props for handling filter changes, e.g., onPriceChange, onCapacityChange
}

const FilterPanel: React.FC<FilterPanelProps> = ({ onClose, handleFilterSubmit, setPriceRange, setCapacityRange, setFilterDate, filters, worxTypeId }) => {


    const PRICE_MIN = 0;
    const PRICE_MAX = 500;
    const CAPACITY_MIN = 0;
    const CAPACITY_MAX = 150;

    const onPriceRangeChange = (values) => {
        setPriceRange(values)
        console.log(values)
    }

    const onCapacityRangeChange = (capacityRange) => {
        setCapacityRange(capacityRange)
        console.log(capacityRange)
    }

    const setDateChange = (newDate) => {
        setFilterDate(newDate)
        console.log(newDate)
    }

    const today = new Date().toISOString().split('T')[0];

    return (
        <aside className="fixed top-0 right-0 w-3/4 md:w-1/4 h-full bg-white shadow-lg z-50 p-4">
            <button onClick={onClose} className="absolute top-0 right-0 m-4">Close</button>
            <h2 className="text-lg font-semibold mb-4">Filters</h2>
            {/* Implement your filters here */}
            {/* Example: Price Slider */}
            <div>
                <label htmlFor="priceRange">Price Range: €{filters.priceRange[0]} - €{filters.priceRange[1]}</label>
                <Range
                    step={1}
                    min={PRICE_MIN}
                    max={PRICE_MAX}
                    values={filters.priceRange}
                    onChange={(values) => onPriceRangeChange(values as [number, number])}
                    renderTrack={({ props, children }) => (
                        <div
                            onMouseDown={props.onMouseDown}
                            onTouchStart={props.onTouchStart}
                            style={{ ...props.style, height: '36px', display: 'flex', width: '100%' }}
                        >
                            <div
                                ref={props.ref}
                                style={{
                                    height: '5px',
                                    width: '100%',
                                    borderRadius: '4px',
                                    background: getTrackBackground({
                                        values: filters.priceRange,
                                        colors: ['#ccc', 'orange', '#ccc'],
                                        min: PRICE_MIN,
                                        max: PRICE_MAX,
                                    }),
                                    alignSelf: 'center',
                                }}
                            >
                                {children}
                            </div>
                        </div>
                    )}
                    renderThumb={({ props }) => (
                        <div {...props} className="w-4 h-4 bg-orange-500 rounded-full" />
                    )}
                />
            </div>
            {/* Capacity Slider */}
            {
                worxTypeId !== 2 ?
                    <div>
                        <label htmlFor="priceRange">Capacity: {filters.capacityRange[0]} - {filters.capacityRange[1]}</label>
                        <Range
                            step={1}
                            min={CAPACITY_MIN}
                            max={CAPACITY_MAX}
                            values={filters.capacityRange}
                            onChange={(values) => onCapacityRangeChange(values as [number, number])}
                            renderTrack={({ props, children }) => (
                                <div
                                    onMouseDown={props.onMouseDown}
                                    onTouchStart={props.onTouchStart}
                                    style={{ ...props.style, height: '36px', display: 'flex', width: '100%' }}
                                >
                                    <div
                                        ref={props.ref}
                                        style={{
                                            height: '5px',
                                            width: '100%',
                                            borderRadius: '4px',
                                            background: getTrackBackground({
                                                values: filters.capacityRange,
                                                colors: ['#ccc', 'orange', '#ccc'],
                                                min: CAPACITY_MIN,
                                                max: CAPACITY_MAX,
                                            }),
                                            alignSelf: 'center',
                                        }}
                                    >
                                        {children}
                                    </div>
                                </div>
                            )}
                            renderThumb={({ props }) => (
                                <div {...props} className="w-4 h-4 bg-orange-500 rounded-full" />
                            )}
                        />
                    </div> : <div></div>
            }

            {/* Date Picker */}
            <div>
                <label htmlFor="dateFilter">Date</label>
                <input
                    type="date"
                    id="dateFilter"
                    name="dateFilter"
                    min={today} // Ensures the user cannot select dates before today
                    defaultValue={filters.date || today} // Sets the default date from props or to today if not provided
                    onChange={setDateChange}
                />
            </div>
            {/* Filter Button */}
            <button className="mt-4 px-4 py-2 bg-orange-500 text-white rounded-md" onClick={handleFilterSubmit}>Apply Filters</button>
        </aside>
    );
};

export default FilterPanel;