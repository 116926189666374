import React, { useState, useContext, useEffect } from 'react'
//import '../App.css';
import SidebarNav from '../Sidebar/Sidebar';
import Header from '../Header/Header';
import { UserContext } from '../../Context/User/UserContext';
import useHttp from "../../Hooks/useHttp";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import CardGrid from '../../Layouts/Grid/CardGrid';
import Col from 'react-bootstrap/Col';
import DashboardCard from '../../Layouts/Cards/DashboardCard';
import Button from 'react-bootstrap/Button';
import * as utils from '../../Utils/utils';
import GeneralGrid from '../../Layouts/Grid/GeneralGrid';

export default function SpecialDeals() {

    const [worxSpots, setSpecialDeals] = useState([])


    const user = useContext(UserContext);


    const { isLoadingSpot, errorSpot, setError: setErrorSpot, sendRequest: findSpecialDeals } = useHttp();


    const getSpecialDeals = (response) => {
        setSpecialDeals(response.body)
    };

    useEffect(() => {
        console.log(user.userId)
        const userId = utils.getFromSessionStorage('user-id')
        console.log(userId)
        if (userId) {
            user.saveUserId(userId)
            console.log(user.userId)
        }
        findSpecialDeals(
            {
                url: `${process.env.REACT_APP_BACKEND_URL}/api/v1/specialdeal/list?SpaceId=2`,
                method: "GET",
                auth: false,
            },
            getSpecialDeals
        );
    }, []);



    return (
        <div>
            <Header />
            <SidebarNav />
            <main style={{ marginLeft: '55px', height: '15%', padding: '5%' }}>
                <GeneralGrid data={worxSpots} buttonTitle="Create SpecialDeal" to="/specialdeals/create" btnTo="/specialdeals/edit"/>
            </main>

        </div>
    )
}






