import React, { useEffect, useState } from 'react';
import SpaceCard from './components/spaceCard';
import WorxAPIService from '../Api/worxAPIService';
import { useLocation } from 'react-router-dom';
import { FiFilter } from 'react-icons/fi';
import Header from './components/menu';
import FilterPanel from './components/filterPanel';
import WorxinnMapSpaces from './components/mapWithMarkers';
import PlaceSuggestionsInput from './components/placesAutocomplete/PlacesSuggestionInput';
import BackArrowHeader from './components/backArrow';
import { Plugins } from '@capacitor/core';

const { Device } = Plugins;
const worxAPIService = new WorxAPIService();

// Fetch worxes
const fetchWorxes = async (date, worxTypeId, capacityRange, priceRange) => {
    const response = await worxAPIService.getAllAvailableWorxes(date, '00:00:00', '24:00:00', worxTypeId, priceRange[0], priceRange[1], capacityRange[0], capacityRange[1]);
    console.log("Worxes", response.body);
    return response.body;
};

const SpacesPage = () => {
    const [worxes, setWorxesList] = useState([]);
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const date = searchParams.get('date');
    const numPeople = Number(searchParams.get('numPersons'));
    const worxTypeId = searchParams.get('searchType');
    const latSearch = Number(searchParams.get('lat'));
    const lngSearch = Number(searchParams.get('lng'));
    const passedState = location.state;
    const [isEditing, setIsEditing] = useState(false);
    const [searchLocation, setSearchLocation] = useState(passedState.city);
    const [cityValid, setCityValid] = useState(true);
    const [showFilterPanel, setShowFilterPanel] = useState(false);
    const [selectedButton, setSelectedButton] = useState('list'); // 'list', 'map', or 'filter'
    const [priceRange, setPriceRange] = useState([1, 500]);
    const [capacityRange, setCapacityRange] = useState([numPeople, Math.ceil(numPeople * 1.2)]);
    const [filterDate, setFilterDate] = useState(date);
    const [lat, setLatitude] = useState(latSearch);
    const [lng, setLongitude] = useState(lngSearch);
    const [latLng, setLatLng] = useState({ lat: 0.0, lng: 0.0 });
    const [city, setCity] = useState('');
    const [isPhone, setIsPhone] = useState(false);

    const filters = { priceRange, capacityRange, filterDate };

    const handleButtonClick = (buttonName) => {
        setSelectedButton(buttonName);
        setShowFilterPanel(buttonName === 'filter');
    };

    useEffect(() => {
        fetchWorxes(date, worxTypeId, filters.capacityRange, filters.priceRange)
            .then((response) => {
                setWorxesList(response.sort((a, b) => {
                    const distA = calculateDistance(a.Space.lat, a.Space.lng, lat, lng);
                    const distB = calculateDistance(b.Space.lat, b.Space.lng, lat, lng);
                    return distA - distB;
                }));
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching worxes:", error);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        const checkDevice = async () => {
            const info = await Device.getInfo();
            setIsPhone(info.platform === 'ios' || info.platform === 'android');
        };
        checkDevice();

        const handleResize = () => {
            setIsPhone(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleEditClick = () => setIsEditing(true);

    const handleSaveClick = () => {
        setIsEditing(false);
        setLoading(true);
        setWorxesList(worxes.sort((a, b) => {
            const distA = calculateDistance(a.Space.lat, a.Space.lng, lat, lng);
            const distB = calculateDistance(b.Space.lat, b.Space.lng, lat, lng);
            return distA - distB;
        }));

        const randomWaitTime = Math.random() * 1000 + 500;
        setTimeout(() => setLoading(false), randomWaitTime);
    };

    const handleSetLatLng = (latLngObj, city) => {
        setLatLng(latLngObj);
        setCity(city);
        setCityValid(true);
    };

    const handleCloseFilterPanel = () => {
        setSelectedButton('list');
        setShowFilterPanel(false);
    }

    const handleFilterSubmit = async () => {
        setLoading(true);
        setShowFilterPanel(false);
        setSelectedButton('list');

        fetchWorxes(date, worxTypeId, filters.capacityRange, filters.priceRange)
            .then((response) => {
                setWorxesList(response.sort((a, b) => {
                    const distA = calculateDistance(a.Space.lat, a.Space.lng, lat, lng);
                    const distB = calculateDistance(b.Space.lat, b.Space.lng, lat, lng);
                    return distA - distB;
                }));
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching worxes:", error);
                setLoading(false);
            });
    };

    const calculateDistance = (lat1, lng1, lat2, lng2) => Math.sqrt(Math.pow(lat1 - lat2, 2) + Math.pow(lng1 - lng2, 2));

    return (
        <div>
            <Header />
            <BackArrowHeader />
            <div className="container mx-auto p-4">
                {loading ? (
                    <div className="flex items-center justify-center h-64">
                        <div role="status">
                            <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                            </svg>
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="mb-4 mt-2">
                            <div className="mt-4">
                                {isEditing ? (
                                    <div className="flex items-center space-x-2">
                                        <PlaceSuggestionsInput setLatLng={handleSetLatLng} initialValue={passedState.city} />
                                        <button
                                            onClick={handleSaveClick}
                                            className="px-4 py-2 bg-blue-500 text-white rounded-lg font-semibold transition duration-200 ease-in-out transform hover:scale-105 hover:shadow-lg"
                                        >
                                            Search
                                        </button>
                                    </div>
                                ) : (
                                    <div className="flex items-center space-x-2">
                                        <span className="text-lg font-medium">{searchLocation || 'Search location...'}</span>
                                        <button
                                            onClick={handleEditClick}
                                            className="px-4 py-2 bg-gray-200 text-gray-800 rounded-lg font-semibold transition duration-200 ease-in-out transform hover:scale-105 hover:shadow-lg"
                                        >
                                            Edit
                                        </button>
                                    </div>
                                )}
                            </div>
                            <div className="mt-4 flex justify-center space-x-4">
                                <button
                                    onClick={() => handleButtonClick('list')}
                                    className={`px-6 py-3 rounded-lg font-semibold transition duration-200 ease-in-out transform ${selectedButton === 'list' ? 'bg-orange-500 text-white scale-105 shadow-lg' : 'bg-gray-200 hover:bg-gray-300'}`}
                                >
                                    List
                                </button>
                                <button
                                    onClick={() => handleButtonClick('map')}
                                    className={`px-6 py-3 rounded-lg font-semibold transition duration-200 ease-in-out transform ${selectedButton === 'map' ? 'bg-orange-500 text-white scale-105 shadow-lg' : 'bg-gray-200 hover:bg-gray-300'}`}
                                >
                                    Map
                                </button>
                                <button
                                    onClick={() => handleButtonClick('filter')}
                                    className={`px-6 py-3 rounded-lg font-semibold flex items-center transition duration-200 ease-in-out transform ${selectedButton === 'filter' ? 'bg-orange-500 text-white scale-105 shadow-lg' : 'bg-gray-200 hover:bg-gray-300'}`}
                                >
                                    <FiFilter className="mr-2" />
                                    Filter
                                </button>
                            </div>
                        </div>
                        <div className={isPhone ? "flex flex-col gap-4" : "grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4"}>
                            {worxes.length > 0 ? (
                                <>
                                    {(selectedButton === 'list' || showFilterPanel) && (
                                        worxes.map((space) => (
                                            <SpaceCard key={space.id} space={space} search={{ day: date, numPeople: numPeople }} />
                                        ))
                                    )}
                                    {selectedButton === 'map' && (
                                        <WorxinnMapSpaces centerLatitude={lat} centerLongitude={lng} height='300px' spaces={worxes} search={{ day: date, numPeople: numPeople }} />
                                    )}
                                </>
                            ) : (
                                <div className="text-center py-10">
                                    <span>No results found. Please adjust your search criteria.</span>
                                </div>
                            )}
                        </div>
                        {/* Conditionally render the FilterPanel */}
                        {showFilterPanel && (
                            <FilterPanel onClose={handleCloseFilterPanel} handleFilterSubmit={handleFilterSubmit}
                                setPriceRange={setPriceRange}
                                setCapacityRange={setCapacityRange}
                                setFilterDate={setFilterDate}
                                filters={filters}
                                worxTypeId={worxTypeId} />
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default SpacesPage;
