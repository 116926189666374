import React, { useState } from 'react'
//import '../App.css';
import Card from 'react-bootstrap/Card';

export default function DashboardCard(props) {

    return (
        <Card style={{ width: '18rem' }} className="flex-fill">
            {props.img ? <Card.Img variant="top" src={props.img} /> : null}
            <Card.Body className="flex-column">
                <Card.Title>{props.title}</Card.Title>
                {props.subtitle ? <Card.Subtitle className="mb-2 text-muted">{props.subtitle}</Card.Subtitle> : null}
                {props.text ? <Card.Text>{props.text}</Card.Text> : null}
                {props.children}
            </Card.Body>
        </Card>
    )
}






