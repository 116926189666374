import { useState } from "react";

export const useDebounce = (func: (...args: any[]) => void, wait: number) => {
  const [timeout, setTimeoutState] = useState<NodeJS.Timeout | null>(null);

  return (...args: any[]) => {
    const later = () => {
      if (timeout) clearTimeout(timeout);
      func(...args);
    };

    if (timeout) clearTimeout(timeout);

    setTimeoutState(setTimeout(later, wait));
  };
};
