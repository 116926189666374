import { Photo } from "../Models/photos";
import { SERVER_URL } from "../Utils/constants";
import { ApiService } from "./apiService";

export default class WorxPhotoAPIService {
  private apiService: ApiService;

  constructor() {
    this.apiService = new ApiService(SERVER_URL);
  }

  public async createWorxPhoto(worxPhoto: Photo, WorxId: number) {
    const photoData = {
      ...worxPhoto,
      WorxId,
    };
    let t = await this.apiService.create(`worxphoto/create`, photoData);
    return t;
  }

  public async createBulkWorxPhoto(worxPhotos: Photo[], WorxId: number) {
    const photoData = {
      photoList: worxPhotos,
      WorxId: WorxId,
    };
    let resp = await this.apiService.post(`worxphoto/createBulk`, photoData);
    return resp;
  }

  public async updateWorxPhoto(newPhoto: Photo, WorxId: number) {
    const photoData = {
      ...newPhoto,
      WorxId,
    };

    return await this.apiService.update(
      `worxphoto/update`,
      newPhoto.id!.toString(),
      photoData
    );
  }

  public async deleteSpacePhotosFromId(worxId: number) {
    return await this.apiService.delete(`worxphoto/delete-worx?worxId=${worxId}`)
  }
}