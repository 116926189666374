export class ApiService {
  private baseURL: string;

  constructor(baseURL: string) {
    this.baseURL = baseURL;
  }

  public async get(endpoint: string): Promise<any> {
    try {
      const response = await fetch(`${this.baseURL}${endpoint}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const resp = await response.json();
      return resp;
    } catch (error: any) {
      console.error(
        `There was an error with your fetch operation: ${error.message}`
      );
      return null;
    }
  }

  public async post(endpoint: string, body: any): Promise<any> {
    try {
      const bodyToJson = JSON.stringify(body);

      const response = await fetch(`${this.baseURL}${endpoint}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: bodyToJson,
      });

      const data = await response.json();

      return data;
    } catch (error: any) {
      console.error(
        `There was an error with your fetch operation: ${error.message}`
      );
      return null;
    }
  }

  public async create(endpoint: string, body: any): Promise<any> {
    const headers = {
      "Content-type": "application/json",
      authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MDY0ODYyMjMsImV4cCI6NDg2MjI0NjIyM30.bhLUEzqlumPXlkztSaZ1fWkwe7CBrraHTLF6kWvEs94`,
    };

    const response = await fetch(`${this.baseURL}${endpoint}`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  }

  public async update(endpoint: string, id: string, body: any): Promise<any> {
    return this.put(`${endpoint}/${id}`, body);
  }

  public async put(endpoint: string, body: any): Promise<any> {
    try {
      const response = await fetch(`${this.baseURL}${endpoint}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MDY0ODYyMjMsImV4cCI6NDg2MjI0NjIyM30.bhLUEzqlumPXlkztSaZ1fWkwe7CBrraHTLF6kWvEs94`
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error: any) {
      console.error(`There was an error with your fetch operation: ${error.message}`);
      return null;
    }
  }

  public async delete(endpoint: string): Promise<any> {
    try {
      const response = await fetch(`${this.baseURL}${endpoint}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MDY0ODYyMjMsImV4cCI6NDg2MjI0NjIyM30.bhLUEzqlumPXlkztSaZ1fWkwe7CBrraHTLF6kWvEs94`
        },
      });

      const data = await response.json();

      return data;
    } catch (error: any) {
      console.error(
        `There was an error with your fetch operation: ${error.message}`
      );
      return null;
    }
  }

  public async deleteWorx(endpoint: string, payload: { userId: number, worxId: number }): Promise<any> {
    try {
      const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MDY0ODYyMjMsImV4cCI6NDg2MjI0NjIyM30.bhLUEzqlumPXlkztSaZ1fWkwe7CBrraHTLF6kWvEs94`
      };

      const response = await fetch(`${this.baseURL}${endpoint}?userId=${payload.userId}&worxId=${payload.worxId}`, {
        method: "DELETE",
        headers: headers
      });

      const data = await response.json();

      return data;
    } catch (error: any) {
      console.error(`There was an error with your fetch operation: ${error.message}`);
      return null;
    }
  }
}
