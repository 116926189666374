import React, { useState } from 'react'
//import '../App.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

export default function CardGrid(props) {

    return (
        <Container>
            <Row>
                {props.children}
            </Row>
        </Container>
    )
}