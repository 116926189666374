import React, { useState, useEffect } from 'react';
import styles from "./PlacesSuggestionInput.module.css";
import { SERVER_URL } from '../../../Utils/constants';

const PlaceSuggestionsInput = (props) => {
    const [inputValue, setInputValue] = useState(props.initialValue || '');
    const [suggestions, setSuggestions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isTyping, setIsTyping] = useState(false); 

    function generateRandomString(length) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    useEffect(() => {
        setInputValue(props.initialValue || '');
    }, [props.initialValue]);

    useEffect(() => {
        if (inputValue && isTyping) { // Check isTyping to determine if the user is currently typing
            const debounceId = setTimeout(() => {
                setLoading(true);
                fetch(`${SERVER_URL}places?query=${encodeURIComponent(inputValue)}`)
                    .then(response => response.json())
                    .then(data => {
                        setSuggestions(data);
                        setLoading(false);
                    })
                    .catch(error => {
                        console.error('Error fetching place suggestions:', error);
                        setLoading(false);
                    });
            }, 250); // 0.25 seconds debounce period

            return () => clearTimeout(debounceId);
        } else {
            setSuggestions([]);
        }
    }, [inputValue, isTyping]); // Add isTyping to useEffect dependencies

    const handleSelect = async (selectedSuggestion) => {
        console.log(selectedSuggestion)
        const placeId = selectedSuggestion.place_id;
        setInputValue(selectedSuggestion.description ? selectedSuggestion.description : selectedSuggestion.name);
        setSuggestions([]);
        setIsTyping(false); // Reset typing status upon selection

        try {
            const response = await fetch(`${SERVER_URL}place-details?placeId=${placeId}`);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();

            console.log('Place details:', data);

            props.setLatLng(data.location, selectedSuggestion.description ? selectedSuggestion.description : selectedSuggestion.name)
            // Example output: Place details: { name: "Some Place", location: { lat: 51.503364, lng: -0.127625 } }

            // You can now do something with the data, such as updating state or UI elements
            // to show the place's details or position it on a map.
        } catch (error) {
            console.error('Error fetching place details:', error);
        }

    };

    return (
        <div style={{ position: 'relative', marginBottom: '0px' }}>
            <input
                type="text"
                value={inputValue}
                onChange={e => {
                    setInputValue(e.target.value);
                    setIsTyping(true); // Indicate that the user is typing
                }}
                placeholder="Type a place..."
                className = {styles.inputField}
            />
            {loading && <div style={{ position: 'absolute', left: 0, right: 0 }}>Loading...</div>}
            {suggestions.length > 0 && (
                <ul style={{
                    listStyleType: 'none',
                    margin: 0,
                    padding: '10px',
                    backgroundColor: '#fff',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    top: '100%',
                    zIndex: 1000
                }}>
                    {suggestions.map(suggestion => (
                        <li key={`${suggestion.place_id}${generateRandomString(4)}`}
                            style={{ padding: '5px 10px', cursor: 'pointer' }}
                            onClick={() => handleSelect(suggestion)}
                        >
                            {suggestion.description ? suggestion.description : suggestion.name}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default PlaceSuggestionsInput;
