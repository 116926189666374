import React, { useContext } from 'react';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { UserContext } from '../../Context/User/UserContext';

const Header = () => {
    const user = useContext(UserContext);

    return (
        <Navbar bg="light" expand="lg">
            <Container>
                <Link to="/dashboard">
                    <Navbar.Brand href="#home">
                        <img
                            src="img/Logo_black.png"
                            width="90"
                            height="30"
                            className="d-inline-block align-top"
                            alt="Company Logo"
                        />
                    </Navbar.Brand>
                </Link>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link as={Link} to="/dashboard">Dashboard</Nav.Link>
                    </Nav>
                    <Nav>
                        <NavDropdown
                            title={
                                <div>
                                    <i className="bi bi-person-circle me-2"></i>
                                    {user.userName}
                                </div>
                            }
                            id="basic-nav-dropdown"
                        >
                            <NavDropdown.Item href="#action/3.1">Profile</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2">Settings</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="#action/3.3">Log Out</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Header;