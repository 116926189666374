import { useState, useCallback, useContext } from 'react';
import { UserContext } from '../Context/User/UserContext';

const useHttp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const user = useContext(UserContext);


  const sendRequest = useCallback(async (requestConfig, manageData) => {
    setIsLoading(true);
    setError(null);
    try {
      let headers = '';
      if (requestConfig.auth) {
        headers = {
          'Content-type': 'application/json',
          'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MDY0ODYyMjMsImV4cCI6NDg2MjI0NjIyM30.bhLUEzqlumPXlkztSaZ1fWkwe7CBrraHTLF6kWvEs94`
        }
      } else {
        headers = {
          'Content-type': 'application/json',
        }
      }

      const response = await fetch(requestConfig.url, {
        method: requestConfig.method,
        headers,
        body: JSON.stringify(requestConfig.body),
      });

      /*if (!response.ok) {
        throw new Error('Request failed!');
      }*/
      const data = await response.json();

      manageData(data);
    } catch (err) {
      setError(err.message || 'Something went wrong!');
      const data = {
        status: 500,
        error: err.message
      }
      manageData(data)
    }
    setIsLoading(false);
  }, []);

  return {
    isLoading,
    error,
    setError,
    sendRequest,
  };
}
export default useHttp;