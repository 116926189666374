import React, { useEffect, useRef } from "react";

interface MapProps {
  latitude: number;
  longitude: number;
  height: string;
  marker?: boolean;
}

declare global {
  interface Window {
    google: any;
  }
}

const loadGoogleMapsScript = (callback: () => void) => {
  if (window.google && window.google.maps) {
    callback();
    return;
  }

  const script = document.createElement("script");
  script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCNilZfl5ySD5GmeE2PZn9eZNg5BsaRqkg`; // Replace with your actual API key
  document.head.appendChild(script);
  script.onload = callback;
};

const WorxinnMap: React.FC<MapProps> = ({ latitude, longitude, height, marker }) => {
  const mapRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    loadGoogleMapsScript(() => {
      if (mapRef.current) {
        const center = { lat: latitude, lng: longitude };
        const map = new window.google.maps.Map(mapRef.current, {
          center,
          zoom: 15,
          draggable: false,
          zoomControl: false,
          scrollwheel: false,
          disableDoubleClickZoom: true,
          fullscreenControl: false,
          mapTypeControl: false, // Hides the map/satellite toggle
          streetViewControl: false, // Hides the Street View button
          clickableIcons: false, // Disables click events on POIs
        });

        if (marker) {
          new window.google.maps.Marker({
            position: center,
            map,
            title: 'Location',
          });
        }
      }
    });
  }, [latitude, longitude, marker, height]);

  return <div ref={mapRef} style={{ width: "100%", height: height }} className="overflow-hidden"></div>;
};

export default WorxinnMap;
