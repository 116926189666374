import { useEffect } from "react";

// Define a type for the callback function
type NearBottomCallback = (nearBottom: boolean) => void;

// The custom hook with TypeScript annotations
function useScrollNearBottom(
  onNearBottom: NearBottomCallback,
  pixelsFromBottom: number = 100 // Default value set to 100
): void {
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight;
      const bottomPosition = document.documentElement.scrollHeight;
      const isNearBottom = bottomPosition - scrollPosition <= pixelsFromBottom;

      onNearBottom(isNearBottom);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [onNearBottom, pixelsFromBottom]);
}

export default useScrollNearBottom;
