import React from "react";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { RxCross1 } from "react-icons/rx";

interface CreateDealModalProps {
  onClose: () => void;
  handleDealCreation: () => void;
  handleSpaceChange: (key: string, value: string) => void;
}

export const CreateDealModal: React.FC<CreateDealModalProps> = ({
  onClose,
  handleDealCreation,
  handleSpaceChange,
}) => {
  return (
    <div className="absolute w-[60vw] max-w-[800px] h-[65vh] bg-white rounded-lg p-4 z-6 pointer-events-auto flex flex-col items-center">
      <div className="flex flex-row w-full">
        <div className="text-md font-bold focus:outline-0 w-full mr-auto">
          New Deal
        </div>
        <RxCross1
          onClick={handleDealCreation}
          className="text-xl cursor-pointer"
        />
      </div>
      <div className="text-xl font-bold focus:outline-0 w-full mb-2">Title</div>
      <div className="bg-black/10 w-full h-[50%] rounded-lg cursor-pointer flex flex-col items-center justify-center">
        <AiOutlineCloudUpload className="text-[70px] text-orange-500" />
        <div className="text-xl font-bold focus:outline-0 w-full mb-2 text-center text-orange-500">
          Drop file here or click to upload
        </div>
      </div>
      <div className="text-md font-bold focus:outline-0 w-full mr-auto">
        Description
      </div>
      <textarea
        rows={3}
        id="dealDescription"
        defaultValue={"Deal Description; E.g 50% off all drinks"}
        onChange={(e) => handleSpaceChange("dealDescription", e.target.value)}
        className="focus:outline-0 mr-auto w-full text-black/40 resize-none"
      />
      <div className="text-md font-bold focus:outline-0 w-full mr-auto">
        Cost
      </div>
      <input
        id="dealCost"
        type="text"
        autoComplete="off"
        spellCheck="false"
        defaultValue={"£9.99"}
        onChange={(e) => handleSpaceChange("dealCost", e.target.value)}
        className="focus:outline-0 mr-auto w-full"
      />
      <div className="flex flex-row space-x-2 w-full mt-4 mb-4 items-center justify-center">
        <div className="cursor-pointer w-[140px] h-[40px] bg-orange-500 text-white flex flex-row rounded-md justify-center items-center">
          <div className="text-md font-semibold">Add</div>
        </div>
        <div className="cursor-pointer w-[140px] h-[40px] bg-black/30 flex flex-row rounded-md justify-center items-center">
          <div className="text-md font-semibold text-white/50">Cancel</div>
        </div>
      </div>
    </div>
  );
};
