import { ApiService } from "./apiService";
import { SERVER_URL } from "../Utils/constants";
import { Photo } from "../Models/photos";

export default class SpacePhotoAPIService {
  private apiService: ApiService;

  constructor() {
    this.apiService = new ApiService(SERVER_URL);
  }

  public async createSpacePhoto(spacePhoto: Photo) {
    return await this.apiService.create(`spacephoto/create`, spacePhoto);
  }

  public async createBulkSpacePhoto(spacePhotos: Photo[], SpaceId: number) {
    const photoData = {
      photoList: spacePhotos,
      SpaceId: SpaceId,
    };
    let resp = await this.apiService.post(`spacephoto/createBulk`, photoData);
    return resp;
  }

  public async deleteVenuePhotosFromId(spaceId: number) {
    return await this.apiService.delete(`spacephoto/delete-space?spaceId=${spaceId}`)
  }
}
