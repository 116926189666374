import React from "react";

interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  message: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  message,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-[10000] bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded-lg shadow-md">
        <p className="text-lg mb-4">{message}</p>
        <div className="flex justify-end space-x-3">
          <button
            onClick={onClose}
            className="w-[50%] transition-all  shadow hover:scale-[1.03] hover:outline-dashed hover:outline-2 hover:outline-black/40 h-full hover:shadow-md hover hover:outline-offset-1 cursor-pointer rounded-md 
            bg-gray-200 py-1 px-3 font-semibold flex flex-row items-center justify-center outline-black/40 outline outline-[0.1px]"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="w-[50%] transition-all  shadow hover:scale-[1.03] hover:outline-dashed hover:outline-2 
            hover:outline-black/40 h-full hover:shadow-md hover hover:outline-offset-1 cursor-pointer rounded-md 
            bg-red-500 text-white py-1 px-3 font-semibold flex flex-row items-center justify-center outline-black/40 outline outline-[0.1px]"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
