import { ApiService } from "../Api/apiService";
import { SpaceDetail } from "../Models/spaceDetails";
import { WorxDetail } from "../Models/worxDetails";
import { SERVER_URL } from "../Utils/constants";

export class SpaceAPIService {
  private apiService: ApiService;

  constructor() {
    this.apiService = new ApiService(SERVER_URL);
  }

  public async setChild(parentId: number, childId: string) {
    const relationship = {
      parentId,
      childId,
    };
    return await this.apiService.post(`space/set/child`, relationship);
  }

  public async getAllSpaces(skip: number, limit: number): Promise<any> {
    return await this.apiService.get(`space/list?skip=${skip}&limit=${limit}`);
  }

  public async getChildren(spaceId: number): Promise<any> {
    return await this.apiService.get(`space/get/children/${spaceId}`);
  }

  public async getParents(spaceId: number): Promise<any> {
    return await this.apiService.get(`space/get/parents/${spaceId}`);
  }

  public async getSpace(spaceId: number): Promise<any> {
    return await this.apiService.get(`space/details/${spaceId}`);
  }

  public async createSpace(space: SpaceDetail): Promise<any> {
    return this.apiService.create(`space/create`, space);
  }

  public async updateSpace(id: number, space: SpaceDetail): Promise<any> {
    const id_ = "" + space.id;
    return this.apiService.update(`space/update`, id_, space);
  }

  public async deleteSpace(id: number): Promise<any> {
    return this.apiService.delete(`space/delete/${id}`);
  }

  public async getAllBookingsBySpaceId(spaceId: number) {
    return await this.apiService.get(`booking/list?spaceId=${spaceId}`)
  }

}
