import { atom } from "recoil";
import SpaceAdmin from "../Models/adminUser";

export const loginToken = atom({
  key: "LoginToken",
  default: "" as string,
});

export const userIdState = atom({
  key: "userId",
  default: "" as string,
});

export const userState = atom({
  key: "User",
  default: {} as SpaceAdmin,
  effects: [
    ({ onSet, setSelf }) => {
      const savedValue = localStorage.getItem("userState");
      if (savedValue != null && savedValue !== undefined) {
        setSelf(JSON.parse(savedValue) as SpaceAdmin);
      }

      onSet((newValue) => {
        if (newValue === null || newValue === undefined) {
          localStorage.removeItem("userState");
        } else {
          localStorage.setItem("userState", JSON.stringify(newValue));
        }
      });
    },
  ],
});

export const usedInvitesState = atom({
  key: "usedInvites",
  default: {} as { [key: string]: boolean },
  effects_UNSTABLE: [
    ({ onSet, setSelf }) => {
      const savedValue = localStorage.getItem("usedInvitesState");
      if (savedValue != null && savedValue !== undefined) {
        setSelf(JSON.parse(savedValue) as { [key: string]: boolean });
      }

      onSet((newValue) => {
        if (newValue === null || newValue === undefined) {
          localStorage.removeItem("usedInvitesState");
        } else {
          localStorage.setItem("usedInvitesState", JSON.stringify(newValue));
        }
      });
    },
  ],
});