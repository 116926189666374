import React, { useState, useEffect } from 'react';
import { Form, Button, ToastContainer } from 'react-bootstrap'; // assuming that you're using Bootstrap
import useHttp from "../../Hooks/useHttp";
import SidebarNav from '../Sidebar/Sidebar';
import Header from '../Header/Header';
import Toast from 'react-bootstrap/Toast';

export default function EditSpaceForm() {

    const [space, setSpace] = useState([])

    // define state for the form fields
    const [name, setName] = useState('');
    const [details, setDetails] = useState('');
    const [email, setEmail] = useState('');
    const [location, setLocation] = useState('');
    const [photo, setPhoto] = useState('');
    const [city, setCity] = useState('');
    const [tagline, setTagline] = useState('');
    const [website, setWebsite] = useState('');

    const { isLoadingSpot, errorSpot, setError: setErrorSpot, sendRequest: findSpace } = useHttp();
    const { isLoadingEdit, errorEdit, setError: setErrorEdit, sendRequest: editSpace } = useHttp();

    const [showA, setShowA] = useState(false);

    const toggleShowA = () => setShowA(!showA);

    // handle form submit
    const handleSubmit = (event) => {
        event.preventDefault();
        // do something with the form data
        const data = {
            name: name,
            details: details,
            email: email,
            location: location,
            photo: photo,
            city: city,
            tagline: tagline,
            website: website,
        };
        editSpace({
            url: `${process.env.REACT_APP_BACKEND_URL}/api/v1/space/update/2`,
            method: "PUT",
            auth: false,
            body: data
        },
            editSpaceResp)
    };

    const editSpaceResp = (response) => {
        if (response.status == 200) {
            console.log("------------------------")
            toggleShowA()
            setTimeout(() => {
                setShowA(false);
            }, 2000);
        } else {
            console.log("Something went bad")
        }
        console.log(response)
    }



    const getSpace = (response) => {
        //setSpace(response.body)
        console.log(response.body)
        if (response.body.name) {
            setName(response.body.name)
        }
        if (response.body.city) {
            setCity(response.body.city)
        }
        if (response.body.email) {
            setEmail(response.body.email)
        }
        if (response.body.details) {
            setDetails(response.body.details)
        }
        if (response.body.location) {
            setLocation(response.body.location)
        }
        if (response.body.main_photo) {
            setPhoto(response.body.main_photo)
        }
        if (response.body.tagline) {
            setTagline(response.body.tagline)
        }
        if (response.body.website) {
            setWebsite(response.body.website)
        }
    };

    useEffect(() => {
        findSpace(
            {
                url: `${process.env.REACT_APP_BACKEND_URL}/api/v1/space/details/2`,
                method: "GET",
                auth: false,
            },
            getSpace
        );
    }, []);

    return (
        <>
            <div aria-live="polite"
                aria-atomic="true"
                className="position-relative"
                style={{ minHeight: '240px' }}>
                <Header />
                <SidebarNav />
                <Form onSubmit={handleSubmit} style={{ marginLeft: '55px', height: '15%', padding: '5%' }}>
                    <Form.Group controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter name"
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                        />
                    </Form.Group>

                    <Form.Group controlId="details">
                        <Form.Label>Details</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter details"
                            value={details}
                            onChange={(event) => setDetails(event.target.value)}
                        />
                    </Form.Group>

                    <Form.Group controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Enter email"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                        />
                    </Form.Group>

                    <Form.Group controlId="location">
                        <Form.Label>Location</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter location"
                            value={location}
                            onChange={(event) => setLocation(event.target.value)}
                        />
                    </Form.Group>

                    <Form.Group controlId="photo">
                        <Form.Label>Photo</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter photo URL"
                            value={photo}
                            onChange={(event) => setPhoto(event.target.value)}
                        />
                    </Form.Group>

                    <Form.Group controlId="city">
                        <Form.Label>City</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter city"
                            value={city}
                            onChange={(event) => setCity(event.target.value)}
                        />
                    </Form.Group>

                    <Form.Group controlId="tagline">
                        <Form.Label>Tagline</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter tagline"
                            value={tagline}
                            onChange={(event) => setTagline(event.target.value)}
                        />
                    </Form.Group>

                    <Form.Group controlId="website">
                        <Form.Label>Website</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter website URL"
                            value={website}
                            onChange={(event) => setWebsite(event.target.value)}
                        />
                    </Form.Group>

                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                </Form>

                <ToastContainer position="top-end">
                    <Toast show={showA} onClose={toggleShowA}>
                        <Toast.Header>
                            <strong className="me-auto">Correct</strong>
                        </Toast.Header>
                        <Toast.Body>you'r space was edited correctly</Toast.Body>
                    </Toast>
                </ToastContainer>
            </div>
        </>
    );
}