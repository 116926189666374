// src/DeleteAccountPage.tsx
import React, { useState } from 'react';

interface DeleteAccountFormState {
    email: string;
    reason: string;
}

const RequestDeleteAccountPage: React.FC = () => {
    const [formState, setFormState] = useState<DeleteAccountFormState>({ email: '', reason: '' });
    const [errors, setErrors] = useState<{ email?: string }>({});

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormState({ ...formState, [event.target.name]: event.target.value });
        // Clear the error for the current input field
        if (errors[event.target.name as keyof typeof errors]) {
            setErrors({ ...errors, [event.target.name]: undefined });
        }
    };

    const validateForm = () => {
        let isValid = true;
        let errors = {};

        if (!formState.email.trim()) {
            errors = { ...errors, email: 'Email is required.' };
            isValid = false;
        }

        setErrors(errors);
        return isValid;
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (validateForm()) {
            console.log('Requesting account deletion for:', formState);
            // Here you would typically send the formState to your backend server.
        }
    };

    return (
        <div className="m-4">
            <h1 className="text-xl font-semibold mb-4">Request Account Deletion</h1>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email:</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formState.email}
                        onChange={handleInputChange}
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                    />
                    {errors.email && <p className="text-xs text-red-500">{errors.email}</p>}
                </div>
                <div>
                    <label htmlFor="reason" className="block text-sm font-medium text-gray-700">Reason for deletion (optional):</label>
                    <textarea
                        id="reason"
                        name="reason"
                        value={formState.reason}
                        onChange={handleInputChange}
                        rows={4}
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                    ></textarea>
                </div>
                <button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
                    Submit Request
                </button>
            </form>
        </div>
    );
};

export default RequestDeleteAccountPage;
