import React, { useState, useEffect, useRef } from 'react';
import styles from '../home/home.module.css';
import { useRecoilState } from "recoil";
import { userState } from '../../State/userAtom';
import headerStyles from './header.module.css';
import { useNavigate } from 'react-router-dom';
import MenuBar from '../components/menu.tsx';
import { ReactComponent as BurgerMenu } from '../../assets/icons/menu-svgrepo-com.svg';

const Header = ({ children }) => {
  const [user, setUser] = useRecoilState(userState);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const [isMenuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(prevState => !prevState);
  };

  const hideMenu = () => {
    setMenuVisible(false);
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    setUser({});
    setIsDropdownOpen(false);
    navigate('/');
  };

  const handleSignIn = () => {
    navigate('/login');
  };

  return (
    <div className={styles.header}>
      <div className={styles.menuIcon} onClick={toggleMenu}>
        {isMenuVisible ? (
          <MenuBar hideMenu={hideMenu} />
        ) : (
          <BurgerMenu className={styles.smallBurgerMenu} />
        )}
      </div>
      <div className={styles.navOptions}>
        {children}
      </div>
      {
        (!user || Object.keys(user).length === 0) ? (
          <div className={styles.signIn} onClick={handleSignIn}>LOG IN</div>
        ) : (
          <div className={` ${headerStyles.dropdown} ${headerStyles.userName}`}>
            <div className={headerStyles.path} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
              {user.bbdUser.user.firstName} {user.bbdUser.user.lastName}
            </div>
            <div className={`${headerStyles.dropdown_content} ${isDropdownOpen ? `${headerStyles.show}` : ''}`}>
              <a onClick={handleLogout}>Log Out</a>
            </div>
          </div>
        )
      }
    </div>
  );
};

export default Header;