import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthResponse, signUp, checkInviteKey } from "../Api/firebaseService";
import { useRecoilState } from "recoil";
import { userState, usedInvitesState } from "../State/userAtom";
import SpaceAdmin from "../Models/adminUser";
import { v4 as uuidv4 } from "uuid";
import { signOut } from "firebase/auth";

const InviteHandlerPage: React.FC = () => {
  const { spaceId, inviteId } = useParams();
  const [user, setUser] = useRecoilState(userState);
  const [usedInvites, setUsedInvites] = useRecoilState(usedInvitesState);
  const navigate = useNavigate();

  let id = uuidv4().substring(0, 9);
  let lastDigits = new Date().getTime().toString().slice(-9);
  let digitsToAlpha = lastDigits.replace(/[0-9]/g, function (w: any) {
    return "abcdefghijklmnopqrstuvwxyz"[w];
  });
  let shuffledAlpha = digitsToAlpha.split("").sort(function () {
    return 0.5 - Math.random();
  });
  let shuffledAlphaString = shuffledAlpha.join("");

  const email = `admin.${shuffledAlphaString}@worxinn.com`;
  const password = uuidv4();
  const firstName = `?Temp-${shuffledAlphaString}`;
  const lastName = "?Account";

  const navigateToSpace = (user: SpaceAdmin) => {
    const numberOfSpaces = Object.keys(user.spaces).length;
    if (numberOfSpaces === 1) {
      setTimeout(() => {
        const spaceId = Object.keys(user.spaces)[0];
        navigate(`/space?id=${spaceId}`);
      }, 50);
      return;
    }
    if (numberOfSpaces === 0) {
      navigate(`/spaces?total=${0}`);
      return;
    }
    navigate(`/spaces?total=${numberOfSpaces}`);
  };

  const createUserToManageSpaceFromInvite = async () => {
    console.log(
      "createUserToManageSpaceFromInvite: ",
      email,
      password,
      firstName,
      lastName
    );
    let spaces = { [`${spaceId}`]: 5 };

    let authResponse: AuthResponse = await signUp(
      email,
      password,
      firstName,
      lastName,
      spaces
    );

    if (authResponse.user) {
      setUser(authResponse.user);
      navigateToSpace(authResponse.user);
    } else if (authResponse.error) {
      console.log(authResponse.error);
    }
  };

  const checkInviteId = async () => {
    //return true;
    const isIdInUse = usedInvites[`${inviteId}`];
    if (!isIdInUse) {
      setUsedInvites({ ...usedInvites, [`${inviteId}`]: true });
    } else {
      return false;
    }
    if (!inviteId) return false;
    return await checkInviteKey(inviteId!);
  };

  const onSubmit = async () => {

    const isValidInvite = await checkInviteId();
    
    if (!isValidInvite) {
      return;
    }
    await createUserToManageSpaceFromInvite();
  };



  useEffect(() => {
    onSubmit();
  }, []);

  return (
    <div className="w-screen h-screen flex items-center justify-center"></div>
  );
};

export default InviteHandlerPage;
