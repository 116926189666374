import {
  PaymentElement,
  LinkAuthenticationElement
} from '@stripe/react-stripe-js'
import { useState } from 'react'
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { EmailAPIService } from '../../Api/emailAPIService';
import { useRecoilState } from "recoil";
import { userState } from '../../State/userAtom';
import { SERVER_URL } from '../../Utils/constants';

export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const emailAPIService = new EmailAPIService();
  const [user, setUser] = useRecoilState(userState);
  const [userEmail, setUserEmail] = useState("");


  const createBooking = async () => {
    try {

      const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MDczODY3NTIsImV4cCI6NDg2MzE0Njc1Mn0.BN4b9hekXmxND9Pgh1ZLb9Et0761TOshWhSnPc1EA30'; // Replace 'your_auth_token_here' with your actual authentication token


      // Get today's date
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
      const day = String(today.getDate()).padStart(2, '0');
      const formattedToday = `${year}-${month}-${day}`;

      const userId = user && user.bbdUser && user.bbdUser.user ? user.bbdUser.user.id : 96;


      const response = await fetch(`${SERVER_URL}booking/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Add authorization header
        },
        body: JSON.stringify({
          spaceId: props.space.id,
          worxId: props.worx.id,
          from_time: props.booking.from_time, // Set from_time to current time
          to_time: props.booking.to_time, // Set to_time to two hours from now
          createdAt: formattedToday, // Set createdAt to today's date
          updatedAt: formattedToday, // Set updatedAt to today's date
          bookingDate: props.booking.day, // Set bookingDate to today's date
          userId: userId,
          hours: props.booking.numHours,
          totalAmount: props.booking.price,
        })
      });

      if (!response.ok) {
        throw new Error('Failed to create booking');
      }

      const data = await response.json();
      console.log('Booking created:', data);

      const body = {
        "booking": {
          "id": data.body.id,
          "day": props.booking.day,
          "from_time": props.booking.from_time,
          "to_time": props.booking.to_time,
          "totalAmount": props.booking.price,
          "hours": props.booking.numHours
        },
        "space": {
          "name": props.worx.name,
          "city": props.space.city,
          "address": props.space.address,
          "email": props.space.email,
          "phone": props.space.phone,
          "price": props.worx.price
        },
        user: {
          ...user.bbdUser.user,
          email: userEmail
        }
      }

      const venueData = {
        "booking": {
          "id": data.body.id,
          "day": props.booking.day,
          "from_time": props.booking.from_time,
          "to_time": props.booking.to_time,
          "totalAmount": props.booking.price,
          "hours": props.booking.numHours
        },
        "space": {
          "name": props.worx.name,
          "city": props.space.city,
          "address": props.space.address,
          "email": props.space.email,
          "phone": props.space.phone,
          "price": props.worx.price
        },
        user: {
          ...user.bbdUser.user,
          email: userEmail
        }
      };

      const responseEmail = await emailAPIService.sendUserBookingConfirmationEmail(body);

      const venueResponseEmail = await emailAPIService.sendHotelBookingConfirmationEmail(venueData);

      console.log(responseEmail)
      console.log(venueResponseEmail)

    } catch (error) {
      console.error('Error creating booking:', error);
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }


    setIsLoading(true);


    console.log("Creating booking")
    createBooking()

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/completion?worxId=${props.worx.id}&spaceId=${props.space.id}&date=${props.booking.day}&hours=${props.booking.numHours}&price=${props.booking.price}&useremail=${userEmail}`,
      },
    });



    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occured.");
    }

    setIsLoading(false);
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <LinkAuthenticationElement id="link-authentication-element"
        onChange={(event) => {
          setUserEmail(event.value.email);
        }}
      // Access the email value like so:
      // onChange={(event) => {
      //  setEmail(event.value.email);
      // }}
      // Prefill the email field like so:
      // options={{defaultValues: {email: 'foo@bar.com'}}}
      />
      <PaymentElement id="payment-element"
      />
      <div className="flex justify-end">
        <button disabled={isLoading || !stripe || !elements} id="submit" className="bg-orange-500 text-white px-4 py-2 rounded-md shadow-md hover:bg-orange-600 mt-2">
          <span id="button-text">
            {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
          </span>
        </button>
      </div>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  )
}
