import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface DayPickerProps {
    onDayChange: (date: Date | null) => void;
}

const DayPicker: React.FC<DayPickerProps> = ({ onDayChange }) => {

    const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());

    const onDayChangeLocal = (date: Date) => {
        setSelectedDate(date);
        onDayChange(date)
    }

    return (
        <div className="mt-4">
            <DatePicker
                selected={selectedDate} // Pass the selected date here
                onChange={onDayChangeLocal}
                minDate={new Date()}
                placeholderText="Select a day"
                dateFormat="yyyy-MM-dd"
                isClearable
                className="border p-2 rounded focus:outline-none focus:border-blue-500"
            />
        </div>
    );
};

export default DayPicker;
