import React from 'react';
import { WorxDetail } from '../../Models/worxDetails';
import { useNavigate } from "react-router-dom";
import { FiUser } from 'react-icons/fi';

interface SpaceProps {
    space: WorxDetail;
    search: any;
}

const getMinimumPriceText = (prices) => {
    if (!prices || prices.length === 0) {
        return `From €0 - 0 hour`; // Use mock data if no prices are available
    }

    const minPriceDetail = prices.reduce((min, current) => {
        return parseFloat(current.price) < parseFloat(min.price) ? current : min;
    }, prices[0]);

    const hourLabel = minPriceDetail.hour === 1 ? 'hour' : 'hours';
    return `From €${minPriceDetail.price} - ${minPriceDetail.hour} ${hourLabel}`;
};

const SpaceCard: React.FC<SpaceProps> = ({ space, search }) => {
    const navigate = useNavigate();

    const handleBookNowClick = (spaceId: number) => {
        navigate(`/space/detail?spaceId=${spaceId}`, { state: { search } });
    };

    return (
        <div className="flex flex-col p-2 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow mb-3">
            <div className="w-full">
                <img
                    src={space.WorxPhotos.length > 0 ? space.WorxPhotos[0].photo_url : 'https://via.placeholder.com/300'}
                    alt={space.name}
                    className="w-full h-36 object-cover rounded-lg mb-2"
                />
            </div>
            <div className="flex flex-col justify-between w-full">
                <div>
                    <h2 className="text-xl font-bold mb-1">{space.name}</h2>
                    <p className="text-gray-700 mb-2">{getMinimumPriceText(space.SpacePrices)}</p>
                    <div className="flex items-center mb-2">
                        {space.WorxTypeId === 1 && (
                            <div className="flex items-center text-lg text-gray-700">
                                <FiUser className="mr-2" />
                                <span>{space.capacity} People</span>
                            </div>
                        )}
                    </div>
                    <div className="mb-2">
                        <div className="text-gray-600 mb-1">Amenities:</div>
                        <div className="flex flex-wrap">
                            {space.Amenities.map((amenity, index) => (
                                <div key={index} className="flex items-center mr-2 mb-1">
                                    <img src={`/amenities/${amenity.icon}`} alt={`Amenity ${index + 1}`} className="w-6 h-6 mr-1" />
                                    <span className="text-gray-600 text-sm">{amenity.name}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div>
                    <button
                        className="bg-orange-500 text-white px-3 py-1 rounded-md hover:bg-orange-600 transition-colors"
                        onClick={() => handleBookNowClick(space.id)}
                    >
                        Book Now
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SpaceCard;
