import { useState, useEffect, DependencyList } from "react";

type AsyncFunction<T> = () => Promise<T>;

export const useAsync = <T,>(
  asyncFunction: AsyncFunction<T>,
  dependencies: DependencyList
) => {
  const [data, setData] = useState<T | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const result = await asyncFunction();
        if (isMounted) {
          setData(result);
        }
      } catch (err) {
        if (isMounted) {
          if (err instanceof Error) {
            setError(err);
          } else {
            setError(new Error(String(err)));
          }
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, dependencies);

  return { data, isLoading, error };
};
