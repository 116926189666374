import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Header from '../header';
import BookingDashboardList from '../../Layouts/Lists/BookingDashboardList';
import { useRecoilState } from "recoil";
import { userState } from '../../State/userAtom';
import { SpaceAPIService } from '../../Api/spaceAPIService';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

const spaceAPIService = new SpaceAPIService();

const fetchAllBookings = async (spaceId) => {
    const response = await spaceAPIService.getAllBookingsBySpaceId(spaceId);
    return response.body;
};

export default function Dashboard() {
    const [bookings, setBookings] = useState([]);
    const [filteredBookings, setFilteredBookings] = useState([]); // New state to store filtered bookings
    const [user, setUser] = useRecoilState(userState);
    const [filterType, setFilterType] = useState('month'); // Default to 'month'
    const [filterMonth, setFilterMonth] = useState(new Date()); // Default to current month
    const [filterWeek, setFilterWeek] = useState('1'); // Default to first week of the month
    const [weekRange, setWeekRange] = useState({ from: null, to: null }); // To store the selected week's range

    useEffect(() => {
        fetchAllBookings(user.bbdUser.Space[0]).then((response) => {
            setBookings(response);
            applyFilter(response);
        });
    }, []);

    useEffect(() => {
        applyFilter(bookings); // Apply the filter whenever the filter state changes
    }, [filterType, filterMonth, filterWeek]);

    const handleMonthChange = (date) => {
        setFilterMonth(date);
        setFilterWeek('1'); // Reset week to first week when month changes
        updateWeekRange(date, '1'); // Update the week range for the first week
    };

    const handleWeekChange = (event) => {
        setFilterWeek(event.target.value);
        updateWeekRange(filterMonth, event.target.value); // Update the week range for the selected week
    };

    // Calculate the start and end date of the selected week
    const getStartAndEndOfWeek = (month, weekNumber) => {
        const startOfMonth = moment(month).startOf('month');
        const startOfWeek = startOfMonth.clone().add(weekNumber - 1, 'weeks').startOf('week');
        const endOfWeek = startOfWeek.clone().endOf('week');
        return { startOfWeek, endOfWeek };
    };

    // Update the week range based on the selected month and week
    const updateWeekRange = (month, weekNumber) => {
        const { startOfWeek, endOfWeek } = getStartAndEndOfWeek(month, weekNumber);
        setWeekRange({ from: startOfWeek, to: endOfWeek });
    };

    // Function to filter bookings based on selected filterType and filterValue
    const applyFilter = (bookingsList) => {
        const filtered = bookingsList.filter((booking) => {
            const bookingDate = moment(booking.bookingDate, 'YYYY-MM-DD');
            const filterMoment = moment(filterMonth);  // Ensure filterMonth is a moment object

            if (filterType === 'month') {
                return bookingDate.isSame(filterMoment, 'month');
            } else if (filterType === 'week') {
                const { startOfWeek, endOfWeek } = getStartAndEndOfWeek(filterMoment, filterWeek);
                return bookingDate.isBetween(startOfWeek, endOfWeek, undefined, '[]');
            } else if (filterType === 'day') {
                return bookingDate.isSame(filterMoment, 'day');
            }
            return true;
        });

        setFilteredBookings(filtered); // Update the state with filtered bookings
    };

    // Calculate the number of weeks in the selected month
    const getWeeksInMonth = (month) => {
        const firstDay = moment(month).startOf('month').startOf('week');
        const lastDay = moment(month).endOf('month').endOf('week');
        const weeks = [];

        let currentDay = firstDay;

        while (currentDay.isBefore(lastDay)) {
            weeks.push(currentDay.week());
            currentDay = currentDay.add(1, 'week');
        }

        return weeks.length;
    };

    const weeksInMonth = getWeeksInMonth(filterMonth);

    return (
        <div className="bg-gray-50 min-h-screen">
            <Header userInitials={`${user.firebaseUser.firstName[0]}${user.firebaseUser.lastName[0]}`} />
            <main className="ml-[55px] py-8 px-6">
                <Container className="bg-white p-6 rounded-lg shadow-lg">
                    <Row className='d-flex'>
                        <Col>
                            <h2 className="text-xl font-semibold mb-4 text-gray-800">Filter Bookings</h2>
                            <Form.Group as={Row} controlId="formFilter" className="mb-6">
                                <Form.Label column sm="2" className="font-medium text-gray-700">Filter By:</Form.Label>
                                <Col sm="3">
                                    <Form.Control
                                        as="select"
                                        value={filterType}
                                        onChange={(e) => setFilterType(e.target.value)}
                                        className="border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                    >
                                        <option value="month">Month</option>
                                        <option value="week">Week</option>
                                        <option value="day">Day</option>
                                    </Form.Control>
                                </Col>
                                <Col sm="4">
                                    {filterType === 'month' && (
                                        <ReactDatePicker
                                            selected={filterMonth}
                                            onChange={handleMonthChange}
                                            dateFormat="MM/yyyy"
                                            showMonthYearPicker
                                            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                        />
                                    )}
                                    {filterType === 'week' && (
                                        <div>
                                            <ReactDatePicker
                                                selected={filterMonth}
                                                onChange={handleMonthChange}
                                                dateFormat="MM/yyyy"
                                                showMonthYearPicker
                                                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                            />
                                            <Form.Control
                                                as="select"
                                                value={filterWeek}
                                                onChange={handleWeekChange}
                                                className="w-full p-2 mt-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                            >
                                                {Array.from({ length: weeksInMonth }, (_, i) => (
                                                    <option key={i + 1} value={i + 1}>
                                                        Week {i + 1}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                            {weekRange.from && weekRange.to && (
                                                <div className="mt-2 text-gray-600">
                                                    <span>From: {weekRange.from.format('YYYY-MM-DD')}</span>
                                                    <span> To: {weekRange.to.format('YYYY-MM-DD')}</span>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {filterType === 'day' && (
                                        <ReactDatePicker
                                            selected={filterMonth}
                                            onChange={handleMonthChange}
                                            dateFormat="MM/dd/yyyy"
                                            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                        />
                                    )}
                                </Col>
                            </Form.Group>
                            <BookingDashboardList title='Bookings' bookings={filteredBookings} />
                            <div className="mt-4 text-lg font-bold text-gray-800">Total amount: {calculateTotalAmount(filteredBookings).toFixed(2)}€</div>
                        </Col>
                    </Row>
                </Container>
            </main>
        </div>
    );
}

const calculateTotalAmount = (bookings) => {
    // Use reduce to sum up the totalAmount for all bookings
    const totalAmountSum = bookings.reduce((sum, booking) => sum + parseFloat(booking.totalAmount), 0);
    return totalAmountSum;
};
