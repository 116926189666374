import { WorxDetail } from "../Models/worxDetails";
import { SERVER_URL } from "../Utils/constants";
import { ApiService } from "./apiService";
import { Booking } from "../Models/booking";

export default class WorxAPIService {
  private apiService: ApiService;

  constructor() {
    this.apiService = new ApiService(SERVER_URL);
  }

  public async getWorxForId(id: number): Promise<any> {
    if (!id) {
      return null;
    }
    const resp = await this.apiService.get(`worx/for/${id}`);
    return resp;
  }

  public async getWorx(id: number): Promise<any> {
    if (!id) {
      return null;
    }
    const resp = await this.apiService.get(`worx/details/${id}`);
    return resp;
  }

  public async getWorxBySpaceId(spaceId: number): Promise<any> {
    if (!spaceId) {
      return null;
    }
  
    try {
      const resp = await this.apiService.get(`worx/hotel/${spaceId}/worxspaces`);
      return resp;
    } catch (error) {
      console.error(`Error fetching worx spaces for spaceId ${spaceId}:`, error);
      throw error;
    }
  }
  

  public async getWorxByDistance(lat: number, lng: number, distance: number): Promise<any> {
    if (!lat || !lng || !distance) {
      return null;
    }
    const resp = await this.apiService.get(`worx/list?lat=${lat}&lng=${lng}&distance=${distance}`);
    return resp;
  }

  public async getWorxList(idList: number[]): Promise<any> {
    if (!idList) {
      return null;
    }
    const resp = await this.apiService.post(`worx/list/of`, idList);
    return resp;
  }

  public async updateWorx(id: number, newWorx: WorxDetail) {
    const id_ = "" + id;
    return await this.apiService.update(`worx/update`, id_, newWorx);
  }

  public async createWorx(newWorx: WorxDetail) {
    return await this.apiService.create(`worx/create`, newWorx);
  }

  public async deleteWorx(id: number) {
    return await this.apiService.delete(`worx/delete/${id}`);
  }

  public async getWorxBookings(worxId: number) {
    return await this.apiService.get(`booking/list?worxId=${worxId}`)
  }

  public async getWorxAvailability(worxId: number, spaceId: number) {
    return await this.apiService.get(`availability/check-availability?spaceId=${spaceId}&worxId=${worxId}`)
  }

  public async createBooking(newBooking: any) {
    return await this.apiService.create(`booking/create`, newBooking);
  }

  public async getFutureBookings(spaceId: number) {
    return await this.apiService.get(`booking/future-bookings?spaceId=${spaceId}`)
  }

  public async getAllFutureBookings(spaceId: number) {
    return await this.apiService.get(`booking/all-future-bookings?worxId=${spaceId}`)
  }

  public async getPastBookings(spaceId: number) {
    return await this.apiService.get(`booking/past-bookings?spaceId=${spaceId}`)
  }

  public async getAllWorxes() {
    return await this.apiService.get(`worx/list`)
  }

  public async getAllAvailableWorxes(date: string, fromTime: string, toTime: string, worxTypeId: number, minPrice: number, maxPrice: number, minPeople: number, maxPeople: number, lat: number, lng: number, distance: number) {
    return await this.apiService.get(`worx/worxes-available?date=${date}&fromTime=${fromTime}&toTime=${toTime}&worxTypeId=${worxTypeId}&minPrice=${minPrice}&maxPrice=${maxPrice}&minPeople=${minPeople}&maxPeople=${maxPeople}&lat=${lat}&lng=${lng}&distance=${distance}`)
  }

  public async checkWorxAvailability(worxId: number) {
    return await this.apiService.get(`worx/check-availability?worxId=${worxId}`)
  }

  public async saveWorx(userId: number, worxId: number): Promise<any> {
    const data = { userId, worxId };
    console.log(data);
    return await this.apiService.create(`likespace/save-worx`, data);
  }

  public async unlikeWorx(userId: number, worxId: number): Promise<any> {
    return this.apiService.deleteWorx(`likespace/unlike-worx`, { userId, worxId });
  }
}
