import { atom } from "recoil";


export const eircodesState = atom({
  key: "eircodes",
  default: {} as { [key: string]: { lat: number, lng: number } },
  effects_UNSTABLE: [
    ({ onSet, setSelf }) => {
      const savedValue = localStorage.getItem("eircodesState");
      if (savedValue != null && savedValue !== undefined) {
        setSelf(JSON.parse(savedValue) as { [key: string]: { lat: number, lng: number } });
      }

      onSet((newValue) => {
        if (newValue === null || newValue === undefined) {
          localStorage.removeItem("eircodesState");
        } else {
          localStorage.setItem("eircodesState", JSON.stringify(newValue));
        }
      });
    },
  ],
});