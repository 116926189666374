import { atom } from "recoil";
import { SpaceDetail } from "../Models/spaceDetails";

export const spaceListState = atom<SpaceDetail[]>({
  key: 'SpaceList',
  default: [],
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((newSpaceList) => {
        localStorage.setItem("spaceListState", JSON.stringify(newSpaceList));
      });
    },
  ],
});
