import React from 'react';
import { Booking } from '../Models/booking';

interface BookingTableProps {
    bookings: Booking[];
}

const BookingTable: React.FC<BookingTableProps> = ({ bookings }) => {

    function formatDate(inputDate: string) {
        const dateParts = inputDate.split("-");
        const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
        return formattedDate;
    }


    return (
        <div className="max-h-1/3 overflow-y-auto">
            <table className="min-w-full">
                <thead>
                    <tr>
                        <th className="px-4 py-2">ID</th>
                        <th className="px-4 py-2">From Time</th>
                        <th className="px-4 py-2">To Time</th>
                        <th className="px-4 py-2">Booking Date</th>
                        {/* Add other table headers if needed */}
                    </tr>
                </thead>
                <tbody>
                    {bookings.map((booking) => (
                        <tr key={booking.id}>
                            <td className="px-4 py-2">{booking.id}</td>
                            <td className="px-4 py-2">{booking.from_time.substring(0, 5)}</td>
                            <td className="px-4 py-2">{booking.to_time.substring(0, 5)}</td>
                            <td className="px-4 py-2">{formatDate(booking.bookingDate)}</td>
                            {/* Add other table cells if needed */}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default BookingTable;
