import React, { useState, useEffect, useRef } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import { useNavigate } from "react-router-dom";
// Define your WorxDetail type according to your data structure
type WorxDetail = {
  id: string;
  name?: string;
  WorxPhotos?: { photo_url: string }[];
};

type SpaceDisplaySliderProps = {
  worxes: WorxDetail[];
  placeholder: string;
  currentSpaceId: string;
  typeOfSpaceId: number;
};

const SpaceDisplaySlider: React.FC<SpaceDisplaySliderProps> = ({
  worxes,
  placeholder,
  currentSpaceId,
  typeOfSpaceId,
}) => {
  const upperBound = 4;
  const lowerBound = 3;
  const nav = useNavigate();

  const [perPage, setPerPage] = useState(
    worxes.length < upperBound
      ? worxes.length < lowerBound
        ? lowerBound
        : worxes.length
      : upperBound
  );
  const [slideWidth, setSlideWidth] = useState(0);
  const splideRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Adjust the slide width when the window resizes
    const handleResize = () => {
      if (splideRef.current) {
        setSlideWidth(splideRef.current.offsetWidth / perPage);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [perPage]);

   const handleOnClick = ((worxId, spaceId) => {
    console.log(worxId, spaceId)
    if (typeOfSpaceId === 1) {
      nav(`/space?id=${worxId}&ismeet=1&pid=${spaceId}`, { replace: false });
    } else if(typeOfSpaceId === 2) {
      nav(`/space?id=${worxId}&isworx=1&pid=${spaceId}`, { replace: false });
    }
   })

  return (
    <div ref={splideRef} className="w-full h-full translate-x-[8px]">
      <Splide
        options={{ rewind: true, perPage: perPage, pagination: false }}
        aria-label="React Splide Example"
      >
        {worxes &&
          worxes.map((worx: WorxDetail, index) => {
            const image_ =
              worx.WorxPhotos && worx.WorxPhotos.length > 0
                ? worx.WorxPhotos[0].photo_url
                : placeholder;

            return (
              <SplideSlide key={worx.id}>
                <div className="cursor-pointer group mr-3">
                  <a onClick={() => handleOnClick(worx.id, currentSpaceId)}
                  >
                    <img
                      style={{ width: `${slideWidth}px` }}
                      className={`${
                        image_ === placeholder
                          ? "object-contain"
                          : "object-cover"
                      } outline outline-[1px] outline-black/20 shadow-xs ml-[1px] mt-2 rounded-lg h-[15vh] overflow-hidden hover:scale-[1.01] transition-all duration-400 hover:outline-dashed hover:outline-2 hover:outline-black/20 hover hover:outline-offset-4`}
                      src={image_}
                      alt={worx.name ? worx.name : `Space #${worx.id}`}
                    />
                  </a>
                  <div className="text-start mt-2 mb-3 no-underline">
                    <span className="font-semibold opacity-[0.6] no-underline text-black underline-offset-0">
                      {worx.name ? worx.name : `Space #${worx.id}`}
                    </span>
                  </div>
                </div>
              </SplideSlide>
            );
          })}
      </Splide>
    </div>
  );
};

export default SpaceDisplaySlider;
