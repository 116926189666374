import React, { useState } from 'react';
import Stack from 'react-bootstrap/Stack';
import { FaEdit } from 'react-icons/fa';

const SidebarNav = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const sidebarStyle = {
    width: isHovered ? '200px' : '100px', // Adjusted width for smaller version
    transition: 'width 0.3s ease',
    background: '#f0f0f0',
    height: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1,
  };

  const logoStyle = {
    width: isHovered ? '120px' : '40px',
    height: 'auto',
    borderRadius: '5%',
    marginBottom: '10px',
  };

  return (
    <div
      style={sidebarStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Stack className="items-center mt-32">
        <div className="items-center">
          <div>
            {isHovered ? (
              <>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/worxinn-88ebb.appspot.com/o/HotelImages%2FClayton-Corvus_Cork.avif?alt=media&token=4f33407f-e950-4655-8073-059ee716750c"
                  alt="Hotel Logo"
                  style={logoStyle}
                />
                <span>Clayton Hotel</span>
              </>
            ) : (
              <>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/worxinn-88ebb.appspot.com/o/HotelImages%2FClayton-Corvus_Cork.avif?alt=media&token=4f33407f-e950-4655-8073-059ee716750c"
                  alt="Hotel Logo"
                  style={logoStyle}
                />
                <span>Hotel Name</span>
                <FaEdit />
              </>
            )}
          </div>
          {isHovered && (
            <div>
              <div className="flex items-center mt-2">
                <button className="bg-orange-500 text-white p-2 flex items-center">
                  <FaEdit className="mr-1" />
                  <span>Edit</span>
                </button>
              </div>
            </div>
          )}
        </div>

      </Stack >
    </div >
  );
};

export default SidebarNav;
