import ReactGA from 'react-ga';
import { Device } from '@capacitor/device';

// Initialize Google Analytics
export const initGA = async (trackingID) => {
    ReactGA.initialize(trackingID);

    const deviceInfo = await Device.getInfo();
    ReactGA.event({
        category: 'App Start',
        action: 'Opened App',
        label: deviceInfo.platform
    });
};

// Track a page view
export const trackPage = (page) => {
    ReactGA.set({ page });
    ReactGA.pageview(page);
};

// Track an event
export const trackEvent = (category, action) => {
    ReactGA.event({
        category,
        action,
    });
};
