import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { userState } from "../State/userAtom";
import { useAsync } from "../Hooks/useAsync";
import { SpaceAPIService } from "../Api/spaceAPIService";
import UserRoles from "../Enums/userRoles";
import Header from "../Components/header";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { SpaceDetail } from "../Models/spaceDetails";
import { FiEdit2 } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";
import { addSpaceToUser, logOut, writeUser } from "../Api/firebaseService";
import { log } from "console";
import { MdOutlineCreate } from "react-icons/md";
import { HotelPhoto } from "../Models/hotelPhoto";
import User from "../Models/adminUser";
import { spaceListState } from "../State/spaceAtom";
import { useLocation } from "react-router-dom";

const LoadingScreen = () => (
  <div className="w-screen h-screen flex items-center justify-center text-2xl">
    <AiOutlineLoading3Quarters className="animate-spin" />
  </div>
);

const ErrorScreen = () => (
  <div className="w-screen h-screen flex items-center justify-center text-2xl">
    Error
  </div>
);

type SpaceItemProps = {
  space: SpaceDetail;
  role: number;
  handleSpaceClick: (spaceId: number) => void;
  handleSpaceRemove: () => void;
  setShowConfirm: (showConfirm: boolean) => void;
  setSpaceIdToDelete: (spaceId: number | null) => void;
};

const SpaceItem: React.FC<SpaceItemProps> = ({
  space,
  role,
  handleSpaceClick,
  handleSpaceRemove,
  setShowConfirm,
  setSpaceIdToDelete,
}) => {
  const placeholder =
    "https://secureservercdn.net/166.62.110.60/h65.3a1.myftpupload.com/wp-content/uploads/2021/09/variable-placeholder-product-31.jpg?time=1644500349";

  const isEditable = role >= UserRoles.EDITOR;
  const isOwner = role === UserRoles.OWNER;
  const hotelPhotos = space.HotelPhotos;
  const srcFromHotelPhotos =
    hotelPhotos.length > 0 ? hotelPhotos[0].photo_url : placeholder;
  const srcFromSpace = space.main_photo;

  const src_ = srcFromHotelPhotos !== "" ? srcFromHotelPhotos : srcFromSpace;

  return (
    <div
      key={space.id}
      className="relative"
      onClick={() => handleSpaceClick(space.id)}
    >
      <div className="relative">
        <img
          className="rounded-lg h-[20vh] w-[25vw] min-w-[370px] md:min-w-[170px] outline outline-black/20 outline-[0.5px] overflow-hidden hover:scale-[1.01] transition-all duration-400 object-cover cursor-pointer hover:outline-dashed hover:outline-2 hover:outline-black/20 hover hover:outline-offset-4"
          src={src_}
          alt={space.name}
        />
        {isEditable && (
          <div
            className={`absolute top-[10px] ${
              isOwner ? "right-[40px]" : "right-[10px]"
            } p-[4px] cursor-pointer outline outline-1 outline-orange-600 bg-orange-500 rounded-md`}
          >
            <FiEdit2 className="text-gray-200" />
          </div>
        )}
      </div>
      {isOwner && (
        <div
          className="absolute top-[10px] right-[10px] p-[4px] cursor-pointer outline outline-1 outline-gray-300 bg-white rounded-md"
          onClick={() => {
            setShowConfirm(true);
            setSpaceIdToDelete(space.id);
          }}
        >
          <RxCross2 className="text-gray-500" />
        </div>
      )}
      <div className="text-start mt-2">
        <span className="font-semibold text-[12px]">{space.name}</span>
        <div className="text-gray-500 text-[10px]">{space.address}</div>
      </div>
    </div>
  );
};

const SpaceSelector = () => {
  const navigate = useNavigate();
  const [spaceList, setSpaceList] = useRecoilState(spaceListState);
  const [currentUser, setCurrentUser] = useRecoilState(userState);
  const [searchTerm, setSearchTerm] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [spaces, setSpaces] = useState<SpaceDetail[]>([...spaceList]);
  const [spaceIdToDelete, setSpaceIdToDelete] = useState<number | null>(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search)
  const page = queryParams.get("page");

  const [currentPage, setCurrentPage] = useState(page ? parseInt(page) : 1);
  const itemsPerPage = 35;

  const spaceAPIService = new SpaceAPIService();

  const isUserAnAdmin = () => {
    if (!currentUser) return false;

    if (currentUser.role === 10) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    //also check if its an empty object
    const isEmpty = (obj: any) => {
      return Object.keys(obj).length === 0;
    };
    if (!currentUser && isEmpty(currentUser)) {
      logOut();
      navigate("/registration?new=1", { replace: false });
    }
  }, [currentUser]);

  const handleSpaceClick = (spaceId: number) => {
    navigate(`/space?id=${spaceId}`);
  };

  const handleRemove = () => {
    setShowConfirm(false);
    setSpaceIdToDelete(null);
  };

  const fetchAllSpacesAndAddToAdmin = async () => {
    const spaceAPIService = new SpaceAPIService();
    let fetchedSpaces = [];
    const limit = itemsPerPage;
    let skip = currentPage * limit - limit;

    try {
      const spaceResponse = await spaceAPIService.getAllSpaces(skip, limit);
      const spaceData = spaceResponse.body;

      fetchedSpaces.push(...spaceData);

      let newSpaceList = spaceList.filter(
        (space: SpaceDetail) => space.id !== spaceIdToDelete
      );

      newSpaceList.push(...fetchedSpaces);
      setSpaceList(newSpaceList);

      for (const space of fetchedSpaces) {
        if (currentUser.spaces[space.id]) {
          continue;
        }
        await addSpaceToUser(currentUser.id!, space.id.toString());
      }
    } catch (error) {
      console.error("Error fetching space data:", error);
    }

    return fetchedSpaces;
  };

  const fetchSpaces = async () => {
    if (!currentUser) return [];

    if (currentUser.role === UserRoles.ADMIN) {
      return fetchAllSpacesAndAddToAdmin();
    }

    const spaceAPIService = new SpaceAPIService();
    const fetchedSpaces = [];

    const spaceIds = Object.keys(currentUser.spaces);

    for (const spaceId of spaceIds) {
      const spaceIdNum = parseInt(spaceId);

      const spaceFromLocalStorage = spaceList.find(
        (space: SpaceDetail) => space.id === spaceIdNum
      );

      if (spaceFromLocalStorage) {
        fetchedSpaces.push(spaceFromLocalStorage);
        continue;
      }

      try {
        const spaceResponse = await spaceAPIService.getSpace(spaceIdNum);
        let spaceData = spaceResponse.body;

        if (!spaceData.id) {
          continue;
        }

        fetchedSpaces.push(spaceData);
      } catch (error) {
        console.error("Error fetching space data:", error);
      }
    }

    fetchedSpaces.filter(
      (space: SpaceDetail) => space.id && space.name && space.name !== ""
    );

    let newSpaceList = spaceList.filter(
      (space: SpaceDetail) => space.id !== spaceIdToDelete
    );

    newSpaceList.push(...fetchedSpaces);

    setSpaceList(newSpaceList);

    return fetchedSpaces;
  };

  const handleCreateSpace = async () => {
    if (!isUserAnAdmin()) {
      return;
    }

    const space: SpaceDetail = {
      id: 0,
      name: "",
      details: "",
      email: currentUser.email,
      address: "",
      main_photo: "",
      city: "",
      county: "",
      tagline: "",
      website: "",
      isVisible: false,
      isDraft: false,
      eircode: "",
      phone: "",
      lat: 0,
      lng: 0,
      createdAt: new Date(),
      updatedAt: new Date(),
      hotelUserID: null,
      spaceTypeId: 1,
      Worxes: [],
      HotelPhotos: [] as HotelPhoto[],
      specialDeals: [],
      hotelUser: null,
      reviews: [],
      amenities: [],
      spaces: [],
      openingHour: "",
      closingHour: "",
    };

    const respCreateSpace = await spaceAPIService.createSpace(space);
    const id = respCreateSpace.body.id;

    const updatedUser = {
      ...currentUser,
      spaces: {
        ...currentUser.spaces,
        ["" + id]: 10,
      },
    } as User;

    writeUser(updatedUser);
    setCurrentUser(updatedUser);
    setTimeout(() => {
      navigate(`/space?id=${id}`, { replace: false });
      window.location.reload();
    }, 50);
  };

  const handleShowPreviousSpaces = () => {
    if (currentPage === 1) return;

    setCurrentPage(currentPage - 1);
    navigate(`/spaces?page=${currentPage - 1}`, { replace: true });
  };

  const handleShowNextSpaces = () => {
    //if (spaces.length < itemsPerPage) return;

    setCurrentPage(currentPage + 1);
    navigate(`/spaces?page=${currentPage + 1}`, { replace: true });
  };

  const {
    data: fetchedSpaces,
    isLoading,
    error,
  } = useAsync(fetchSpaces, [currentUser, currentPage]);

  useEffect(() => {
    setSpaces(fetchedSpaces || []);
  }, [fetchedSpaces, searchTerm]);

  useEffect(() => {
    if (fetchedSpaces && fetchedSpaces.length === 1 && !isUserAnAdmin()) {
      setTimeout(() => {
        const spaceData = fetchedSpaces[0];
        const space = spaceData.body;
        const spaceId = space.id;
        navigate(`/space?id=${spaceId}`);
      }, 50);
    }
  }, [fetchedSpaces]);

  return (
    <div className="w-screen min-h-screen flex flex-col items-center overflow-y-scroll overflow-x-hidden">
      <Header
        userInitials={
          Object.keys(currentUser).length === 0 ? currentUser.firstName[0] + currentUser.lastName[0] : "?"
        }
      />
      {/* <input
        type="text"
        placeholder="Search Spaces"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="w-[35vw] shadow-sm mt-4 px-4 py-[10px] outline-none border text-[12px] border-gray-400 rounded-full"
      /> */}
      <div className="fixed h-full w-full overflow-y-scroll flex pointer-events-none z-[10001]">
        <div
          onClick={handleCreateSpace}
          className="outline pointer-events-auto bg-orange-500 rounded-lg ml-auto mt-auto mr-[10vw] mb-10 text-[20xp] 
          w-[190px] lg:w-[240px] outline-2 py-2 px-10 text-white text-center 
          max-h-[100px] outline-orange-400/[15%] flex justify-center items-center font-bold overflow-hidden 
          hover:scale-[1.01]
          transition-all duration-400 object-cover cursor-pointer hover:outline-dashed hover:outline-[3px]
          hover:outline-orange-400/50 hover hover:outline-offset-[14px] shadow-md hover:shadow-none"
        >
          <MdOutlineCreate className="mr-2 text-2xl opacity-80" />
          {"Create Space"}
        </div>
      </div>

      {isLoading && <LoadingScreen />}

      {error && <ErrorScreen />}

      {!isLoading && !error && (
        <div className="flex flex-col">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-3 mt-[4vh] w-[70vw] max-w-[1280px]">
            {spaces!.map((space: SpaceDetail) => {
              if (!space.id) return <div></div>;

              return (
                <SpaceItem
                  key={space.id + "-" + space.name}
                  space={space}
                  handleSpaceClick={handleSpaceClick}
                  handleSpaceRemove={handleRemove}
                  setShowConfirm={setShowConfirm}
                  setSpaceIdToDelete={setSpaceIdToDelete}
                  role={currentUser!.spaces[space.id]}
                />
              );
            })}
          </div>
        </div>
      )}

      {!isLoading && !error && (
        <div className="w-full flex flex-row space-x-4 text-white font-semibold items-center justify-center mt-4 mb-[100px]">
          <div
            onClick={currentPage > 1 ? handleShowPreviousSpaces : undefined}
            className={`py-2 px-3 bg-orange-500 rounded-md hover:bg-orange-600 ${
              currentPage > 1
                ? "hover:cursor-pointer"
                : "opacity-0 cursor-default"
            }`}
          >
            <span>Previous</span>
          </div>

          <div className="py-2 px-3 text-orange-500 font-bold text-lg rounded-md cursor-none pointer-events-none">
            <span>{currentPage}</span>
          </div>

          <div
            onClick={
              spaces.length == itemsPerPage ? handleShowNextSpaces : undefined
            }
            className={`py-2 px-3 bg-orange-500 rounded-md hover:bg-orange-600 ${
              spaces.length == itemsPerPage
                ? "hover:cursor-pointer"
                : "opacity-0 cursor-default"
            }`}
          >
            <span>Next</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default SpaceSelector;
