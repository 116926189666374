import React from 'react';
import { Link } from 'react-router-dom';
import UserIconDrawer from "./userIconMenu";
import { useRecoilState } from "recoil";
import { userState } from '../State/userAtom';
import { useNavigate } from 'react-router-dom';

interface HeaderProps {
  userInitials: string;
}

const goHome = () => {
  if (window.location.pathname !== "/spaces") window.location.href = "/spaces";
};



const Header: React.FC<HeaderProps> = ({ userInitials }) => {
  const [user, setUser] = useRecoilState(userState);
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/');
  };

  return (
    <div className="w-full bg-white flex flex-col items-center justify-center mb-4">
      <div className="w-[89vw] lg:w-[70vw] max-w-[1280px] lg:px-0 h-[70px] flex flex-row items-center justify-between">
        <div className="flex items-center">
          <div className="xl:h-[35px] h-[32px] cursor-pointer" onClick={handleGoHome}>
            <img
              className="w-full h-full object-contain"
              src="//images.squarespace-cdn.com/content/v1/62544e35ff5978408919fe05/ccfd61e4-c1b4-48c3-972a-8a16a8cd4272/Logo_black.png?format=1500w"
              alt="WORXINN"
            />
          </div>
          {/* Dashboard Link added here */}
          <Link to="/dashboard" className="ml-4 text-sm text-gray-700 hover:text-gray-900 transition duration-150 ease-in-out">Dashboard</Link>

          {
            "Space" in user.bbdUser && <Link to={`/space?id=${user.bbdUser.Space[0]}`} className="ml-4 text-sm text-gray-700 hover:text-gray-900 transition duration-150 ease-in-out">My Venue</Link>
          }

        </div>
        <UserIconDrawer initials={userInitials} />
      </div>
      <div className="h-[0.3px] bg-black/10 w-full"></div>
    </div>
  );
};

export default Header;