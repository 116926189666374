import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from "recoil";
import { userState } from '../../State/userAtom';
import styles from './menu.module.css';
import ButtonEffectMenu from '../../effects/ButtonsEffectMenu';
import HoverEffectText from '../../effects/SocialEffectMenu';
import { ReactComponent as Cross } from '../../assets/icons/cross.svg';

const MenuBar = ({ hideMenu }) => {
  const [user, setUser] = useRecoilState(userState);
  const location = useLocation();
  const navigate = useNavigate();

  const handleClick = (path) => {
    if ((path === '/my-profile' || path === '/saved-bookings' || path === '/my-bookings') && (!user || Object.keys(user).length === 0)) {
      navigate('/login')
    } else if (location.pathname !== path) {
      navigate(path);
    }
    hideMenu();
  };

  const handleCloseClick = (event) => {
    event.stopPropagation();
    hideMenu();
  };

  return (
    <div className={styles.menuBar}>
      <div className={styles.closeButton} onClick={handleCloseClick}>
        <Cross className={styles.cross} />
      </div>
      <div className={styles.menuContent}>
        <div className={styles.discover}>Discover</div>
        <div className={styles.menuOptions}>
          <ButtonEffectMenu text="Home" onClick={() => handleClick('/')} isSelected={location.pathname === '/'} />
          <ButtonEffectMenu text="My Bookings" onClick={() => handleClick('/my-bookings')} isSelected={location.pathname === '/my-bookings'} />
          <ButtonEffectMenu text="Saved Bookings" onClick={() => handleClick('/saved-bookings')} isSelected={location.pathname === '/saved-bookings'} />
          <ButtonEffectMenu text="My Profile" onClick={() => handleClick('/my-profile')} isSelected={location.pathname === '/my-profile' || location.pathname === '/edit-profile'} />
        </div>
      </div>
      <div className={styles.menuContent}>
        <div className={styles.connect}>Connect</div>
        <div className={styles.socialsGrid}>
          <HoverEffectText text="Instagram" url='https://www.instagram.com/worxinn/' />
          <HoverEffectText text="Linkedin" url='https://www.linkedin.com/company/worxinn/' />
        </div>
      </div>
    </div>
  );
};

export default MenuBar;
